/** GraphQL query to get estimated delivery date data. */

export default `
 query getDeliveryData(
  $cart_id: String
  $dest_country: String!
  $order_date: String!
) {
  getEstimatedDeliveryData(
      cart_id: $cart_id
      dest_country: $dest_country
      order_date: $order_date
  )
}
`;
