import gql from 'graphql-tag';

/**
 * GraphQL Query that fetches the product engraving configurations.
 */
export default gql`
  query getProductEngravingConfig($sku: String!, $store_code: String) {
    getProductEngravingConfig(sku: $sku, store_code: $store_code) {
      sku
      status
      pleat_text
      template {
        entity_id
        key
        product_type
        number_of_lines
        line1_maxlength
        line2_maxlength
        is_text_vertical
        pos_x
        pos_y
        image_url
        position {
          label
          value
        }
        font {
          label
          value
          size
        }
        color {
          label
          value
        }
      }
    }
  }
`;
