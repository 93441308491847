/* eslint-disable  */

export default {
  "About us": "About us",
  "Account": "Account",
  "Account | Diptyque Paris": "Account | Diptyque Paris",
  "Add new address": "Add new address",
  "Add to compare": "Add to compare",
  "Add to Wishlist": "Add to Wishlist",
  "Additional Information": "Additional Information",
  "All Orders": "All Orders",
  "Allow order notifications": "Allow order notifications",
  "Apply": "Apply",
  "Applied Coupon": "Applied Coupon",
  "Attention!": "Attention!",
  "Back to home": "Back to home",
  "Back to homepage": "Back to homepage",
  "Billing": "Billing",
  "Billing address": "Billing address",
  "Brand": "Brand",
  "Cancel": "Cancel",
  "Cart": "Cart",
  "Categories": "Categories",
  "Change": "Change",
  "Change password your account": "If you want to change the password to access your account, enter the following information",
  "Clear all": "Clear all",
  "Color": "Color",
  "Commercial information": "and agree to receive personalized commercial information from Brand name by email",
  "Contact details updated": "Keep your addresses and contact details updated.",
  "Contact us": "Contact us",
  "Continue to billing": "Continue to billing",
  "Continue to payment": "Continue to payment",
  "Continue to shipping": "Continue to shipping",
  "Cookies Policy": "Cookies Policy",
  "Create an account": "Create an Account",
  "Customer Reviews": "Customer Reviews",
  "Customer service": "Customer service",
  "Date": "Date",
  "Default Billing Address": "Default Billing Address",
  "Default Shipping Address": "Default Shipping Address",
  "Delete": "Delete",
  "Departments": "Departments",
  "Description": "Description",
  "Details and status orders": "Check the details and status of your orders in the online store. You can also cancel your order or request a return.",
  "Discount": "Discount",
  "Discover the universe": "Discover the universe",
  "Discover the collection": "Discover the collection",
  "Discover the products": "Discover the collection",
  "Done": "Done",
  "Download": "Download",
  "Download all": "Download all",
  "Edit": "Edit",
  "Email address": "Email address",
  "Empty": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Empty bag": "Empty bag",
  "Enjoy your free account": "Enjoy these perks with your free account!",
  "Enter promo code": "Enter promo code",
  "Feedback": "Your feedback is important to us. Let us know what we could improve.",
  "Feel free to edit": "Feel free to edit any of your details below so your account is always up to date",
  "Filters": "Refine your Search",
  "Find out more": "Find out more",
  "First Name": "First Name",
  "Forgot Password": "If you can’t remember your password, you can reset it.",
  "Forgot Password Modal Email": "Email you are using to sign in:",
  "forgotPasswordConfirmation": "Thanks! If there is an account registered with the {0} email, you will find message with a password reset link in your inbox.",
  "Forgotten password?": "Forgotten your password?",
  "Go back": "Go back",
  "Go back shopping": "Go back shopping",
  "Go back to shop": "Go back to shop",
  "Go to checkout": "Go to checkout",
  "Guarantee": "Guarantee",
  "Help": "Help",
  "Help & FAQs": "Help & FAQs",
  "hide": "hide",
  "Home": "Home",
  "I agree to": "By clicking on “Place order”, I confirm that I have read and accepted the",
  "I confirm subscription": "I confirm subscription",
  "I want to create an account": "I want to create an account",
  "Info after order": "You can log to your account using e-mail and password defined earlier. On your account you can edit your profile data, check history of transactions, edit subscription to newsletter.",
  "Instruction1": "Take care of me",
  "Instruction2": "Just here for the care instructions?",
  "Instruction3": "Yeah, we thought so",
  "It was not possible to request a new password, please check the entered email address.": "It was not possible to request a new password, please check the entered email address.",
  "Item": "Item",
  "Items": "Items",
  "Kidswear": "Kidswear",
  "Last Name": "Last Name",
  "Let’s start now – we’ll help you": "Let’s start now – we’ll help you.",
  "Log into your account": "Log into your account",
  "Login": "Sign in",
  "login in to your account": "login in to your account",
  "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.": "Looks like you haven’t added any items to the bag yet. Start shopping to fill it in.",
  "Looks like you haven’t added any items to the Wishlist.": "Looks like you haven’t added any items to the Wishlist.",
  "Make an order": "Make an order",
  "Manage addresses": "Manage all the addresses you want (work place, home address...) This way you won\"t have to enter the address manually with each order.",
  "Manage billing addresses": "Manage all the billing addresses you want (work place, home address...) This way you won\"t have to enter the billing address manually with each order.",
  "Manage shipping addresses": "Manage all the shipping addresses you want (work place, home address...) This way you won\"t have to enter the shipping address manually with each order.",
  "Match it with": "Match it with",
  "Men fashion": "Men fashion",
  "Menu": "Menu",
  "My shipping and billing addresses are the same.": "My shipping and billing addresses are the same.",
  "My Cart": "My Cart",
  "No account": "Don't have an account yet?",
  "or": "or",
  "or fill the details below": "or fill the details below",
  "Order ID": "Order ID",
  "Order information": "Order information",
  "Order No.": "Order No.",
  "Order summary": "Order summary",
  "Other products you might like": "Other products you might like",
  "Out of stock": "Out of stock",
  "Password": "Password",
  "Password Changed": "Password successfuly changed. You can now go back to homepage and sign in.",
  "Pay for order": "Pay for order",
  "Payment": "Payment",
  "Payment & Delivery": "Payment & Delivery",
  "Payment Method": "Payment Method",
  "Payment Methods": "Payment Methods",
  "Personal details": "Personal details",
  "Please type your current password to change your email address.": "Please type your current password to change your email address.",
  "Price": "Price",
  "Privacy": "Privacy",
  "Privacy Policy": "Privacy Policy",
  "Product": "Product",
  "Product suggestions": "Product suggestions",
  "Products": "Products",
  "Products found": "Products found",
  "Purchase terms": "Purchase terms",
  "Quality in the details": "Quality in the details",
  "Quantity": "Quantity",
  "Read all reviews": "Read all reviews",
  "Read and understand": "I have read and understand the",
  "Read reviews": "Read reviews",
  "Register": "Register",
  "Register today": "Register today",
  "Remove": "Remove",
  "Remove Address": "Remove Address",
  "Remove from Wishlist": "Remove from Wishlist",
  "Repeat Password": "Repeat Password",
  "Reset Password": "Reset Password",
  "Review my order": "Review my order",
  "Same as shipping address": "Same as shipping address",
  "Save changes": "Save changes",
  "Save for later": "Save for later",
  "Save Password": "Save Password",
  "Search": "Search",
  "Search for items": "Search for items",
  "Search results": "Search results",
  "Sections that interest you": "Sections that interest you",
  "See all results": "See all results",
  "See more": "See more",
  "Select payment method": "Select your payment method",
  "Select shipping method": "Select delivery method",
  "Send my feedback": "Send my feedback",
  "Set up newsletter": "Set up your newsletter and we will send you information about new products and trends from the sections you selected every week.",
  "Share your look": "Share your look",
  "Shipping": "Shipping",
  "Shipping address": "Shipping Address",
  "Shipping details": "Shipping details",
  "Shipping method": "Shipping method",
  "Show": "Show",
  "show more": "show more",
  "Show on page": "Show on page",
  "Sign in": "Create an account",
  "Size guide": "Size guide",
  "Sign Up for Newsletter": "Sign Up for Newsletter",
  "Default": "Default",
  "Sort by": "Sort By",
  "Sort By": "Sort By",
  "Name A-Z": "Name A-Z",
  "Name Z-A": "Name Z-A",
  "Price from high to low": "Price from high to low",
  "Price from low to high": "Price from low to high",
  "Start shopping": "Start shopping",
  "Status": "Status",
  "Subscribe to newsletter": "Subscribe to newsletter",
  "subscribeToNewsletterModalContent": "After signing up for the newsletter, you will receive special offers and messages from VSF via email. We will not sell or distribute your email to any third party at any time. Please see our {0}.",
  "Subtotal": "Subtotal",
  "Subtotal price": "Subtotal price",
  "Successful placed order": "You have successfully placed the order. You can check status of your order by using our delivery status feature. You will receive an order confirmation e-mail with details of your order and a link to track its progress.",
  "Terms and conditions": "Terms and conditions",
  "Thank you": "Thank you",
  "Thank You Inbox": "If the message is not arriving in your inbox, try another email address you might’ve used to register.",
  "Total": "Total",
  "Order Total": "Order Total",
  "Total items": "Total items",
  "Total price": "Total price",
  "Update password": "Update password",
  "Update personal data": "Update personal data",
  "Use your personal data": "At Brand name, we attach great importance to privacy issues and are committed to protecting the personal data of our users. Learn more about how we care and use your personal data in the",
  "User Account": "User Account",
  "View": "View",
  "Vue Storefront Next": "Vue Storefront Next",
  "Who we are": "Who we are",
  "Wishlist": "Wishlist",
  "Women fashion": "Women fashion",
  "You added {product} to your shopping cart.": "You added {product} to your shopping cart.",
  "You are not authorized, please log in": "You are not authorized, please log in",
  "You can unsubscribe at any time": "You can unsubscribe at any time",
  "You currently have no orders": "You currently have no orders",
  "You haven\’t searched for items yet": "You haven’t searched for items yet.",
  "Your bag is empty": "Your bag is empty",
  "Your current email address is": "Your current email address is",
  "Your email": "Your email",
  "The email field must be a valid email": "The email field must be a valid email",
  'You have submitted no reviews': 'You have submitted no reviews',
  "The account sign-in was incorrect or your account is disabled temporarily. Please wait and try again later.": "Invalid login or password",
  "A customer with the same email address already exists in an associated website.": "A customer with the same email address already exists in an associated website.",
  "SUMMER COLLECTION {year}": "SUMMER COLLECTION {year}",
  "Colorful summer dresses are already in store": "Colorful summer dresses are already in store",
  "Learn more": "Learn more",
  "Dresses": "Dresses",
  "Cocktail & Party": "Cocktail & Party",
  "Linen Dresses": "Linen Dresses",
  "T-Shirts": "T-Shirts",
  "The office life": "The office life",
  "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.": "Find stunning women's cocktail dresses and party dresses. Stand out in lace and metallic cocktail dresses from all your favorite brands.",
  "Shop now": "Shop now",
  "The Office Life": "The Office Life",
  "Summer Sandals": "Summer Sandals",
  "Eco Sandals": "Eco Sandals",
  "Subscribe to Newsletters": "Subscribe to Newsletters",
  "Be aware of upcoming sales and events. Receive gifts and special offers!": "Be aware of upcoming sales and events. Receive gifts and special offers",
  "Fashion to take away": "Fashion to take away",
  "Download our application to your mobile": "Download our application to your mobile",
  "Share Your Look": "Share Your Look",
  "My account": "My account",
  "My Account": "My Account",
  "My profile": "My profile",
  "Personal Details": "Personal Details",
  "Addresses details": "Addresses details",
  "My newsletter": "My newsletter",
  "Log out": "Log out",
  "My reviews": "My reviews",
  "Order history": "Order history",
  "Order details": "Order details",
  "My wishlist": "My wishlist",
  "My payment methods": "My payment methods",
  "You have no registered payment methods": "You have no registered payment methods",
  "Password change": "Password change",
  "Personal data": "Personal data",
  "Your e-mail": "Your e-mail",
  "Current Password": "Current Password",
  "You are not authorized, please log in.": "You are not authorized, please log in.",
  "Go To Product": "Go To Product",
  "Change to list view": "Change to list view",
  "Change to grid view": "Change to grid view",
  "Returns": "Returns",
  "My orders": "My orders",
  "Add the address": "Add the address",
  "Set as default shipping": "Set as default shipping",
  "Set as default billing": "Set as default billing",
  "House/Apartment number": "House/Apartment number",
  "Street Name": "Street Name",
  "City": "City",
  "State/Province": "State/Province",
  "Zip-code": "Zip/postal code",
  "Country": "Country",
  "Phone number": "Phone number",
  "Please select a country first": "Please select a country first",
  "The field should have at least 2 characters": "The field should have at least 2 characters",
  "The field should have at least 10 characters": "The field should have at least 10 characters",
  "The field should have at least 4 characters": "The field should have at least 4 characters",
  "The field should have at least 8 characters": "Password must be 8 characters, including 1 letter, 1 special symbol, and 1 number.",
  "New Password": "New Password",
  "New Products": "New Products",
  "There are no shipping methods available for this country. We are sorry, please try with different country.": "There are no shipping methods available for this country. We are sorry, please try with different country.",
  "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.": "There was some error while trying to fetch shipping methods. We are sorry, please try with different shipping details.",
  "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.": "There was some error while trying to select this shipping method. We are sorry, please try with different shipping method.",
  "We can't find products matching the selection.": "We can't find products matching the selection.",
  'Page not found': 'The page you are looking for cannot be located',
  'An error occurred': 'An error occurred',
  "AllProductsFromCategory": "All {categoryName}",
  "Show more": "Show more",
  "Show less": "Show less",
  "Yes": "Yes",
  "No": "No",
  "Apply filters": "Apply filters",
  "The coupon code isn't valid. Verify the code and try again.": "The coupon code isn't valid. Verify the code and try again.",
  "From": "From",
  "To": "To",
  "Your customization": "Your customization",
  "Passwords don't match": "Passwords don't match",
  "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)": "The password must be at least 8 characters long and must contain at least: 1 uppercase or lowercase letter, 1 number, or one special character (E.g. , . _ & ? etc)",
  "Show all products": "Show all products",
  "Select previously saved address": "Select previously saved address",
  "Enter different address": "Enter different address",
  "You must confirm your account. Please check your email for the confirmation link.": "You must confirm your account. Please check your email for the confirmation link.",
  "Change Store": "Change Store",
  "Choose Currency": "Choose Currency",
  "Add a review": "Add a review",
  "Add to cart": "Add to cart",
  "Title": "Title",
  "Name": "Name",
  "Review": "Review",
  "Add review": "Add review",
  "Are you sure you would like to remove this item from the shopping cart?": "Are you sure you would like to remove this item from the shopping cart?",
  "Your cart is empty": "Your cart is empty",
  "Are you sure?": "Are you sure?",
  "{0} has been successfully removed from your cart": "{0} has been successfully removed from your cart",
  "Amount": "Amount",
  "Thank you for your order!": "Thank you for your order!",
  "Your Purchase": "Your Purchase",
  "Primary contacts for any questions": "Primary contacts for any questions",
  "Your Account": "Your account",
  "What can we improve": "What can we improve",
  "Payment date": "Payment date",
  "The user password was changed successfully updated!": "The user password was changed successfully updated!",
  "The user account data was successfully updated!": "The user account data was successfully updated!",
  "Read more": "Read more",
  "Newsletter": "Newsletter",
  "Tell you the latest creations of the House in preview, send you private invitations or simply our news... Receive our newsletter.": "Sign up to our newsletter so we can welcome you to the Diptyque community and keep you posted on new launches, events, special offers and more.",
  "Our service": "Our service",
  "Free delivery from 75 €": "Free delivery from 75 €",
  "Signature box": "Signature box",
  "Small gifts": "Small gifts",
  "Free samples": "Free samples",
  "Free returns": "Free Returns",
  "Your email address has been saved": "Thank you for subscribing",
  "Your email address is already registered": "Your email address is already registered",
  "By clicking on OK, I accept the General Conditions of Sale and the Privacy Policy.": "By clicking on OK, I accept the General Conditions of Sale and the Privacy Policy.",
  "Sign Up": "Sign Up",
  "Mandatory input": "This is a required field",
  "This field is required": "This is a required field",
  "Invalid email": "Please enter a valid email address",
  "Our team is available to help you.": "Our team is available to help you.",
  "Get online help": "Get online help",
  "Languages": "Country / Language",
  "Country, Language": "Country, Language",
  "Close": "Close",

  // Shopping Cart
  "Checkout": "Checkout",
  "Basket": "Basket",
  "Complete your selection": "Complete your selection",
  "Favorites": "Favorites",
  "You have no products in your cart": "You have no products in your <i>cart</i>",
  "Continue shopping": "Continue shopping",
  "Validate my message": "Validate my message",
  "Add a message": "Add a message",
  "Your recipient": "Your recipient",
  "Your receiver": "Your receiver",
  "Your message": "Your message",
  "To the attention of {from}": "To the attention of {from}",
  "The gift message was successfully added.": "The gift message was successfully added.",
  "Something went wrong while adding a gift message.": "Something went wrong while adding a gift message.",
  "Characters remaining: {0}": "Characters remaining: {0}",
  "{name} added to your cart!": "{name} added to Shopping Bag",
  "Products successfully added to your cart": "Products successfully added to your bag",
  "Some of the products are out of stock.": "Some of the products are out of stock.",

  // Checkout - Error Page
  "Your order has been canceled": "Your order has been canceled",
  "The payment was refused. Please try a different payment method or card.": "The payment was refused. Please try a different payment method or card.",


  // Custom translations
  "E-mail": "Email address",

  "Unknown error": "Unknown error",
  "View basket": "View basket",
  "Sort and filter": "Sort and filter",
  "Delivery method": "Delivery method",
  "Country / Language": "Country / Language",
  "There are no shipping methods available for this country. We are sorry, please try with a different country.": "There are no delivery methods available for your shipping address. Please check your information and try again.",
  "Thoughtful gestures": "Thoughtful gestures",
  "Standard delivery from 75€": "Standard delivery from 75€",
  "Trial dose offered": "Trial dose offered",
  "Click and collect": "Click and collect",
  "At your service": "At your service",
  "Our team is available to assist you": "Our team is available to assist you",
  "Refer to online help": "Refer to online help",
  "LET’S STAY IN TOUCH": "LET’S STAY IN TOUCH",
  "You have seen {amount} of {total}": "You have seen {amount} of {total}",
  " out of ": " out of ",
  "Models": "Models",
  "Model": "Model",
  "Load more": "Load more",
  "Sign in / Sign up": "Sign in / Sign up",
  "Discover universe": "Discover universe",
  "Add all to cart": "Add all to cart",
  "Read less": "Read less",
  "Diptyque home page": "Diptyque home page",
  "Diptyque, a precursor of an art of living through the senses, where perfume and art permeate everything.": "Diptyque, a precursor of an art of living through the senses, where perfume and art permeate everything.",
  "Learn more about our products": "Learn more about our products",
  "Banner image": "Banner image",
  "Switch password visibility": "Switch password visibility",
  "Delivery address": "Add an address here to auto-fill",
  "Delivery Address": "Shipping Address",
  "Or enter address manually": "Or enter address manually",
  "Save": "Save",
  "My shipping addresses": "My Shipping Addresses",
  "My billing addresses": "My Billing Addresses",
  "See the detail": "View order details",
  "for your order.": "for your order.",
  "Create my account": "Create my account",
  "Simplify the follow-up of your order by creating your account": "Simplify the follow-up of your order by creating your account",
  "Back to the homepage": "Return to home page",
  "Collection": "The Collection",
  "All products": "See all the products",
  "Company": "Company",
  "Address": "Street address",
  "Additional address": "Additional address details: apartment number, floor, etc. (optional)",
  "Discover diptyque & you": "Discover Diptyque & you",
  "Search...": "Search...",
  "country": "country",
  "See all products": "See all the products",
  "You have qualified for free shipping": "You now benefit from free delivery",
  "See all": "See all",
  "Starting from {price}": "Starting from {price}",
  "Gift box": "Gift box",
  "Choose the color of your box": "Choose the color of your box",
  "Forgot your": "Forgotten",
  "password": "password?",
  "Please enter your email address below to receive the password reset link.": "Please enter your email address below to receive a password reset link.",
  "Back": "Back",
  "Email sent": "Email sent",
  "Send": "Send",
  "If there is an account registered with the {email} email, you will find message with a password reset link in your inbox.": "If an account matches the email address <span style='font-weight: 500; font-style: italic;'>{email}</span>, you’ll be sent a password reset email.",
  "By email": "By email",
  "By SMS": "By SMS",
  "By courier": "By courier",
  "Gift wrapping for order": "Gift wrapping for order",
  "Enter your email address": "Enter your email address",
  "By clicking on subscribe, I agree to the General Terms and Conditions and the Confidentiality Policy.": "By clicking on subscribe, I agree to the General Terms and Conditions and the Confidentiality Policy.",
  "Subscribe": "Subscribe",
  "LET’S STAY IN TOUCH": "LET’S STAY IN TOUCH",
  "Please subscribe to our newsletter so that we can let you know about the latest creations from the Maison, send you invitations to Diptyque events and offer you thoughtful, well-chosen gifts.": "​Sign up to our newsletter so we can welcome you to the Diptyque community and keep you posted on new launches, events, special offers and more.",
  "By clicking on subscribe, I agree to the General Terms and Conditions and the Confidentiality Policy.": "By clicking on subscribe, I agree to the General Terms and Conditions and the Confidentiality Policy.",
  "Subscribe": "Subscribe",
  "At your service": "At your service",
  "Free Standard Delivery for orders of £70+": "Free Standard Delivery for orders of £70+",
  "Free Diptyque Gift Set": "Free Diptyque Gift Set",
  "Two Free Samples with all orders": "Two Free Samples with all orders",
  "Welcome Gift": "Welcome Gift",
  "Need help?": "Need help?",
  "We are here to assist you": "We are here to assist you",
  "Contact Us": "Contact Us",
  "Help Center": "Help Center",
  "Refine your Search": "Refine your Search",
  "Reset": "Reset",
  "Deselect all": "Deselect all",
  "Select": "Select",
  "Apply": "Apply",
  "Articles": "Articles",
  "Display more": "Display More",
  "Explore more Product Ideas": "Explore more Product Ideas",
  "Select a size": "Select a size",
  "Select a format": "Select a format",
  "Selecting a candle? ": "Selecting a candle? ",
  "Let us guide you": "Let us guide you",
  "Need help? Let us guide you": "Need help? Let us guide you",
  "Calculate your Requirements": "Calculate your Requirements",
  "Visualise the product in your home": "Visualise the product in your home",
  "Reserve in a Boutique": "Reserve in a Boutique",
  "Estimated delivery date:": "Estimated delivery date:",
  "Pay in 3 XX instalments with Klarna": "Pay in 3 XX instalments with Klarna",
  "You may also like": "Discover more Product Ideas",
  "You may also like nosto": "You may also like",
  "Compose your Diptyque Candle Set": "Compose your Diptyque Candle Set",
  "Buy your Set": "Buy your Set",
  "Explore the Collection": "Explore the Collection",
  "Discover more Product Ideas": "Discover more Product Ideas",
  "Good to see you again, {user}": "Good to see you again, <br/><i>{user}</i>",
  "My Account Information": "My information",
  "My Orders": "My Orders",
  "Address Book": "Address Book",
  "My Contact Preferences": "My Contact Preferences",
  "Sign out": "Sign out",
  "Create an Account to enjoy exclusive benefits": "Create an Account to enjoy exclusive benefits",
  "Sign in with Facebook": "Sign in with Facebook",
  "Sign in with Google": "Sign in with Google",
  "Share you Birthday details with us and we'll make sure to plan a birthday present for you.": "Share you Birthday details with us and we'll make sure to plan a birthday present for you.",
  "Select your prefered contact method for Diptyque news, invitations and access to special privileges.": "Select your prefered contact method for Diptyque news, invitations and access to special privileges:",
  "by Email": "by Email",
  "by SMS and Phone": "by SMS and Phone",
  "by Postal Mail": "by Postal Mail",
  "Create my Account": "Create my Account",
  "By submitting this form, I accept the Terms and Conditions and the Privacy Policy.": "By submitting this form, I accept the Terms and Conditions and the Privacy Policy.",
  "Your Shopping Bag is empty": "Your Shopping Bag is empty",
  "Continue Shopping": "Continue Shopping",
  "Free standard delivery on orders over X": "Free standard delivery on orders over X",
  "View Shopping Bag": "View Shopping Bag",
  "Personal Information": "Personal Information",
  "Login Information": "Login Information",
  "You have not yet placed an order. Would you like a little inspiration?": "You have not yet placed an order. Would you like a little inspiration?",
  "Explore our Recommendations": "Explore our Recommendations",
  "Order Details": "Order Details",
  "My Delivery Addresses": "My Delivery Addresses",
  "By Email": "By Email",
  "by SMS and Phone": "by SMS and Phone",
  "by Postal Mail": "by Postal Mail",
  "Shopping Bag": "Shopping Bag",
  "Order today to receive your items before": "Order today to receive your items before",
  "TOTAL": "TOTAL",
  "Delivery": "Delivery Methods",
  "Review My Order": "Proceed to checkout",
  "Enter Promotion Code": "Enter Promotion Code",
  "Apply": "Apply",
  "Modify": "Modify",
  "You May Also Like": "You May Also Like",
  "Select your {amount} free samples": "Select your {amount} free samples",
  "See other samples": "See other samples",
  "Select your Gift Box": "Select your Gift Box",
  "Diptyque offers you free gift packaging for your order. Your items will be packaged in our Diptyque gift box and accompanied by a card, allowing you to add a personal message.": "Diptyque offers you free gift packaging for your order. Your items will be packaged in our Diptyque gift box and accompanied by a card, allowing you to add a personal message.",
  "Fabric Pouch": "Fabric Pouch",
  "All products will be packaged in one of our pouches, made of eco-friendly cotton (30%).": "All products will be packaged in one of our pouches, made of eco-friendly cotton (30%).",
  "Gift Set": "Gift Set",
  "Choose the colour of your set:": "Choose the colour of your set:",
  "Add a message": "Add a message",
  "Recipient": "Recipient",
  "Your name": "Your name",
  "Your message": "Your message",
  "Confirm message": "Confirm message",
  "Edit": "Edit",
  "Sign In / Create an Account": "Sign In / Create an Account",
  "By Email": "By Email",
  "by SMS and Phone": "by SMS and Phone",
  "by Postal Mail": "by Postal Mail",
  "Delivery / Shipping Address": "Shipping Address",
  "Shipping Method": "Delivery Method",
  "3. Payment": "3. Payment",
  "Order Summary": "Order Summary",
  "Or enter an address manually": "Or enter an address manually",
  "Billing Address": "Billing Address",
  "Delivery and billing addresses are the same": "My billing and shipping address are the same",
  "Thank you for your order, {name}": "Thank you for your order, {name}",
  "What happens next": "What happens next",
  "You will receive an order confirmation by email. Your package will arrive within an estimated 3 working days (standard delivery) or 1 working day (express delivery). Did you choose Click & Collect? If so, we'll notify you by SMS as soon as your order is ready.": "You will receive an order confirmation by email. Your package will arrive within an estimated 3 working days (standard delivery) or 1 working day (express delivery). Did you choose Click & Collect? If so, we'll notify you by SMS as soon as your order is ready.",
  "Create an Account to Track your order easily": "Create an Account to Track your order easily",
  "Your Order": "Your Order",
  "The Collection": "The Collection",
  "The page you are looking for": "The page you are looking for",
  "cannot be located": "cannot be located",
  "Return to Home Page": "Return to Home Page",
  "Invalid email address": "Please enter a valid email address",
  "E-Mail-Adresse erkannt": "E-Mail-Adresse erkannt",
  "Please enter your email address and proceed to checkout": "Please enter your email address and proceed to checkout",
  "We cannot find your email address. Please create a New Account or proceed as guest.": "We cannot find your email address. Please create a New Account or proceed as guest.",
  "Set a strong password with at least 8 characters.": "Set a strong password with at least 8 characters.",
  "Your password must contain at least 8 characters": "Password must be 8 characters, including 1 letter, 1 special symbol, and 1 number.",
  "Code Applied": "Code Applied",
  "Subscribe to our newsletter": "Subscribe to our Newsletter",
  "Enter your email address …": "Enter your email address …",
  "Thank you for subscribing.": "Thank you for subscribing.",
  "Please enter a valid email address": "Please enter a valid email address",
  "Sorry! No products matching the your search term were found.": "Sorry! We did not find any",
  "Search Results for": "Search Results for",
  "Please try searching again with a different keyword.": "Please try searching again with a different keyword.",
  "Buy Now": "Buy Now",
  "Discover": "Discover",
  "Discover this Candle": "Discover this Candle",
  "Explore this Collection": "Explore this Collection",
  "Discover this Perfume": "Discover this Perfume",
  "View this Home Décor Item": "View this Home Décor Item",
  "Discover this New Product": "Discover this New Product",
  "Find your Scent": "Find your Scent",
  "Choose your Scent": "Choose your Scent",
  "Choose your Perfume": "Choose your Perfume",
  "Buy Now": "Buy Now",
  "Add to cart": "Add to Bag",
  "Add to basket": "Add to Bag",
  "Request a sample": "Request a sample",
  "Receive a sample": "Receive a sample",
  "View all Wallpapers Designs": "View all Wallpapers Designs",
  "View all Products": "View all Products",
  "View all Candles": "View all Candles",
  "View all Perfumes": "View all Perfumes",
  "View all Home Diffusers": "View all Home Diffusers",
  "View all Home Décor": "View all Home Décor",
  "View all Tableware": "View all Tableware",
  "View all Candle Holders": "View all Candle Holders",
  "View all Accessories": "View all Accessories",
  "View all Care Products": "View all Care Products",
  "Get Notifications": "Get Notifications",
  "Reserve in a boutique": "Reserve in a boutique",
  "Find out more": "Find out more",
  "Explore": "Explore",
  "Discover the world of Diptyque": "Discover the world of Diptyque",
  "All our commitments to responsible practices": "All our commitments to responsible practices",
  "Explore Career Opportunities": "Explore Career Opportunities",
  "Create an Account": "Create an Account",
  "Discover Benefits": "Discover Benefits",
  "Continue shopping": "Continue shopping",
  "View Shopping Bag": "View Shopping Bag",
  "Apply": "Apply",
  "Create an Account": "Create an Account",
  "Select Shipping Method": "Delivery",
  "Add a new address": "Add a new address",
  "Save for Later": "Save for Later",
  "Cancel": "Cancel",
  "Proceed to Payment": "Select payment method",
  "Order": "Order",
  "View details": "View details",
  "Return to home page": "Return to home page",
  "Sign Out": "Sign Out",
  "Request a Return": "Request a Return",
  "Order again": "Order again",
  "Product added to Shopping Bag": "Product added to Shopping Bag",
  "Reset all": "Reset all",
  "By checking this box, I agree to the terms of use": "By checking this box, I agree to the terms of use",
  "Thank you. We will notify you when the product is back in stock.": "Thank you. We will notify you when the product is back in stock.",
  "This Email Id is already registered for this product.": "This Email Id is already registered for this product.",
  "You have 6 days to recover your order in store.": "You have 6 days to recover your order in store.",
  "Estimated delivery date {date}": "Estimated delivery date {date}",
  "Order today and receive your items by {date}": "Order today and receive your items by {date}",
  "This Email Id is already registered for this product.": "This Email Id is already registered for this product.",
  "Select your special offer products":"Select your free product",
  "Enter your e-mail below": "Enter your <i>e-mail</i> below",
  "By clicking OK, I agree to the": "By clicking OK, I agree to the",
  "Terms and Conditions": "Terms and Conditions",
  "Read the article": "Read the article",
  "10 characters maximum": "10 characters maximum",
  "30 characters maximum": "30 characters maximum",
  "Only {amount} {currency} left to get free shipping.": "Only <span>{amount} {currency}</span> left to get free shipping.",
  "Reorder": "Reorder",
  "Trending now": "Trending now",
  "View all": "View all",
  "filter":"filter",
  "By clicking on subscribe, I agree to the": "By submitting the form, I agree to the",
  "General Terms and Conditions": "general conditions of sale.",
  "and the": "and",
  "Please verify your information": "Please verify your information",
  "The complete {category_name} collection": "The complete {category_name} collection",
  "Sign in to access your account": "Sign in",
  "Receive our communications and personalized attention:": "Select your prefered contact method for Diptyque news, invitations and access to special privileges.",
  "Find a store": "Find your nearest boutique",
  "Japanese": "日本語",
  "English": "English",
  "Chinese": "中文",
  "Notify me": "Notify me",
  "Complete with": "Complete with",
  "Submit": "Submit",
  "Password must be 8 characters, including 1 letter, 1 special symbol, and 1 number.": "Password must be 8 characters, including 1 letter, 1 special symbol, and 1 number.",
  "By submitting the form, I agree to the terms and conditions and privacy policy.": "By submitting this form, I accept the Terms and Conditions and the Privacy Policy.",
  "You haven\'t placed an order yet. What if you let yourself be inspired?": "You have not yet placed an order. Would you like a little inspiration?",
  "Browse the suggestions": "Explore our Recommendations",
  "Post code": "Post code",
  "This address is your default shipping address.": "This address is your default shipping address.",
  "This address is your default billing address.": "This address is your default billing address.",
  "Use only alphabetic letters": "Please exclusively use characters from the latin alphabet",
  "Only these characters": "The character ; is not allowed",
  "We recognize your email. Happy to see you again!": "We recognize this email. Welcome back!",
  "By submitting the form, I agree to the": "By submitting the form, I agree to the",
  "By submitting this form, I agree to the": "By submitting the form, I agree to the",
  "This product is out of stock": "This product is out of stock",
  "No element found. Consider changing the search query.": "No element found. Consider changing the search query.",
  "We are not able to sign you in with {login_type}. Please verify your social settings or switch to another method.": "We are not able to sign you in with {login_type}. Please verify your social settings or switch to another method.",
  "Story": "Story",
  "Formula & texture": "Formula & texture",
  "Directions of use": "Directions of use",
  "Characteristics": "Characteristics",
  "Know-how": "Know-how",
  "Error: The requested qty exceeds the maximum qty allowed in shopping cart": "The maximum you may purchase is 5.",
  "Email": "Email address",
  "Symbols ; and \" are prohibited": "Symbols ; and \" are prohibited",
  "We will send you a link to confirm your registration.": "We will send you a link to confirm your registration.",
  "* Required fields": "* Required fields",
  "Passwords do not match": "Passwords do not match, please try again",
  "Passwords do not match, please try again.": "Passwords do not match, please try again.",
  "By checking this box, I agree to": "By submitting the form, I agree to the",
  "Continue with Facebook": "Continue with Facebook",
  "Continue with Google": "Continue with Google",
  "Video Banner": "Video Banner",
  "Play": "Play",
  "Pause": "Pause",
  "Mute": "Mute",
  "Unmute": "Unmute",
  "Unnecessary": "Unnecessary",
  "Existing customer": "Existing customer",
  "Proceed": "Proceed",
  "The payment is REFUSED": "The payment is REFUSED",
  "Something went wrong. Please try again!": "Something went wrong. Please try again!",
  "Diptyque Logo": "Diptyque Logo",
  "Previous slide": "Previous",
  "Next slide": "Next",
  "Item Variant": "Item Variant",
  "Back to top": "Back to top",
  "Input": "Input",
  "Svg Image": "Svg Image",
  "Promo Banner": "Promo Banner",
  "Notification": "Notification",
  "Left": "Left",
  "Right": "Right",
  "Pin Button": "Button for product in the image",
  "Opens in new tab": "Opens in new tab",
  "You saved the account information": "You saved the account information",
  "Completed": "Completed",
  "Pending Capture": "Processing",
  "Warehouse Ready": "Processing",
  "Capture OK": "Processing",
  "Capture KO": "Processing",
  "Partially Shipped": "Processing",
  "Store accepted": "Processing",
  "Stores sent": "Processing",
  "On hold": "Processing",
  "Pending Payment": "Processing",
  "Payment Review": "Processing",
  "Payment Authorized": "Processing",
  "Warehouse Sent": "Processing",
  "Shipped": "Shipped",
  "Complete": "Completed",
  "Closed": "Completed",
  "Cancelled": "Canceled",
  "Canceled": "Canceled",
  "[SFS] Aborted": "Canceled",
  "[SFS] Accepted": "Processing",
  "[SFS] Transited": "Processing",
  "[SFS] Cancelled": "Completed",
  "[SFS] Delivered": "Completed",
  "[SFS] Refused": "Canceled",
  "[SFS] Returned": "Canceled",
  "results of the search": "search results",
  " klevu products": "",
  "from {price}": "from {price}",
  "From {price}": "From {price}",
  "Your message 1": "Your message 1",
  "Your message 2": "Your message 2",
  "Your message 3": "Your message 3",
  "The payment has been refused. Check your payment details and try again.": "The payment has been refused. Check your payment details and try again.",
  "Enter your email address...": "Enter your <i>email</i> address ...",
  "Read article": "Read article",
  "Select your {quantity} products": "Select your {quantity} products",
  "Add gift box to bag": "Add gift box to bag",
  "Select {quantity} {name}": "Select {quantity} {name}",
  "I already have an account": "I already have an account",
  "Get news and thoughtful gifts selected just for you:": "Get news and thoughtful gifts selected just for you:",
  "to enjoy exclusive benefits": "to enjoy exclusive benefits",
  "2 free samples of your choice with every order": "2 free samples of your choice with every order",
  "Thank you. Welcome to Diptyque.": "Thank you. Welcome to Diptyque.",
  "Start your scent journey": "Start your scent journey",
  "{message}. Please try again! Order ID: {orderId}": "{message}. Please try again! Order ID: {orderId}",
  "Create this set": "Create this set",
  "Products successfully added to your bag": "Products successfully added to your bag",
  "Forgot your password?": "Forgotten <i>password?</i>",
  "There are no delivery methods available for your address. Please check your information and try again.":"There are no delivery methods available for your address. Please check your information and try again.",
  "Please enter only numbers.":"Please enter only numbers.",
  "You are visiting us from another country, would you like to visit our local {store} website?": "You are visiting us from another country, would you like to visit our local {store} website?",
  "go to our {store} website": "go to our {store} website",
  "Stay on the {store} website": "Stay on the {store} website",
  "storeName": {
    "France": "France",
    "United States": "United States",
    "United Kingdom": "United Kingdom",
    "Spain": "Spain",
    "Germany": "Germany",
    "Italy": "Italy",
    "China": "China",
    "Hong Kong": "Hong Kong",
    "Japan": "Japan",
    "International" : "International"
  },
  "Booked until {time}": "Booked until {time}",
  "Please enter a valid phone number (example: +XXXXXX...)":"Please enter a valid phone number (example: +XXXXXX...)",
  "Your booking is outdated, please remove it from your cart": "Your booking is outdated, please remove it from your cart",
  "Increase": "Increase",
  "Decrease": "Decrease",
  "Skip forward": "Skip forward",
  "Skip back": "Skip back",

  // Payment - Terms of use
  "termsOfUse": {
    'legalText': 'By clicking on "Place an order", I confirm that I have read and accepted the',
    'legalLink' :'general conditions of sale.'
  },

  // Engraving Layer
  "engraving": {
    'title': "Personalised engraving",
    "Position": "Position",
    "Front": "Front",
    "Side": "Side",
    "characters" : "Unavailable | 1 character | {amount} characters",
    "Text": "Text",
    "Line 1": "Line 1",
    "Line 2": "Line 2",
    "tip": "Only the following special characters are available: ' “ - ! & , . : ? #",
    "Close tooltip": "Close tooltip",
    "errorValidate": "Contains invalid characters",
    "errorLimit": "Number of possible characters exceeded",
    "Message": "Message",
    "Fonts": "Fonts",
    "Font": "Font",
    "Color": "Color",
    "Gold": "Gold",
    "Personalized engraving offered": "Personalized engraving offered",
    "See details": "See details",
    "Modify engraving": "Modify engraving",
    "Add to basket": "Add to basket",
    "Apply changes": "Apply changes",
    "actionsErrorLimit": "The text of your message is too long",
    "Close": "Close",
    "Previous": "Previous",
    "Next": "Next",
    "legalNotice": "Customized products cannot be exchanged or refunded. Engraving may extend the delivery time of your order.",
    "engravingLiteBtn": "Engraving",
  },

  // Legal
  "legal": {
    'companyInformation':'Diptyque SAS, a company registered in Paris with company number 612 043 331, VAT FR 3061204333. Registered office: 34 Boulevard Saint-Germain, 75005 Paris, France would like to keep you updated by email and/or post about our latest products and offers (including our new arrivals, exclusive previews, special offers and promotions). If you are happy for us to do this, please tick the relevant boxes.',
    'consentWithdrawal': 'You can change your mind at any time and withdraw your consent by contacting us at',
    'privacyPolicyPrompt': 'Please see our',
    'moreInfo': 'for more information about how we handle and use your personal data.',
    'contactInfo': '',
    'additionalData': ''
  },

  "Edit my shipping address": "Change my shipping address",
  "Edit my billing address": "Change my billing address",
  "Delete my shipping address": "Delete my shipping address",
  "Delete my billing address": "Delete my billing address",
};
