import { integrationPlugin } from '~/helpers/integrationPlugin';
import { mapConfigToSetupObject } from '~/modules/core/helpers';

const moduleOptions = JSON.parse('{"availablePaymentMethods":["scheme","paypal","klarna","klarna_account","klarna_paynow","wechatpayQR","applepay"],"clientKey":"live_62BBO5TJ6FC5BEUO5JBWBX6UKIFPTXTE","environment":"live","methods":{"card":{"brands":["mc","visa","amex","maestro","discover","jcb","cartebancaire","bcmc"],"hasHolderName":true,"holderNameRequired":true}}}');
export default integrationPlugin((plugin) => {
  const settings = mapConfigToSetupObject({
    moduleOptions,
    app: plugin.app
  });

  plugin.integration.configure('adyen', settings);
});
