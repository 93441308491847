import { readonly, ref, useContext } from '@nuxtjs/composition-api';

import { Logger } from '~/helpers/logger';
import { getProductDetailsCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductDetailsCommand';
import { getProductListCommand } from '~/modules/catalog/product/composables/useProduct/commands/getProductListCommand';
import type { GetProductSearchParams } from '~/modules/catalog/product/types';
import { UseContextReturn } from '~/types/core';

import type { UseProductErrors, UseProductInterface } from './useProduct';

/**
 * Allows loading product details or list with
 * params for sorting, filtering and pagination.
 *
 * See the {@link UseProductInterface} for a list of methods and values available in this composable.
 */
export function useProduct(id?: string): UseProductInterface {
  const loading = ref(false);
  const error = ref<UseProductErrors>({
    getProductList: null,
    getProductDetails: null,
    getProductDetailsToAddToCart: null
  });

  const context = useContext();

  const executeCommand = async <RETURN_TYPE>(
    command: {
      execute: (context: UseContextReturn, searchParams: GetProductSearchParams, customQuery?: string) => Promise<RETURN_TYPE>;
    },
    searchParams: GetProductSearchParams,
    errorKey: keyof UseProductErrors,
    customQuery?: string,
    withoutLoading?: boolean
  ): Promise<RETURN_TYPE> => {
    Logger.debug(`useProduct/${id}/${errorKey}`, searchParams);
    let result: RETURN_TYPE = null;

    try {
      if (!withoutLoading) {
        loading.value = true;
      }
      result = await command.execute(context, searchParams, customQuery);
      error.value[errorKey] = null;
    } catch (err) {
      error.value[errorKey] = err;
      Logger.error(`useProduct/${id}/${errorKey}`, err);
    } finally {
      loading.value = false;
    }

    return result;
  };

  const getProductList = (searchParams: GetProductSearchParams, enableLoading = true) => {
    return executeCommand(getProductListCommand, searchParams, 'getProductList', undefined, !enableLoading);
  };

  const getProductDetails = async (searchParams: GetProductSearchParams) => {
    return executeCommand(getProductDetailsCommand, searchParams, 'getProductDetails');
  };

  const getProductDetailsToAddToCart = async (searchParams: GetProductSearchParams) => {
    return executeCommand(getProductDetailsCommand, searchParams, 'getProductDetailsToAddToCart', 'productDetailsToAddToCart');
  };

  return {
    getProductList,
    getProductDetails,
    getProductDetailsToAddToCart,
    error: readonly(error),
    loading: readonly(loading)
  };
}

export * from './useProduct';
export default useProduct;
