import { useContext, useRoute } from '@nuxtjs/composition-api';
import getCategoryUrlPath from '~/diptyqueTheme/customMiddleware/extensions/queries/getCategoryUrlPath.gql';
import getProductUrlPath from '~/diptyqueTheme/customMiddleware/extensions/queries/getProductUrlPath.gql';
import { Logger } from '~/helpers/logger';
import type { CategoryTree, ProductInterface } from '~/modules/GraphQL/types';
import type { BreadcrumbType, ExtendedProductInterface } from '~/diptyqueTheme/composable/useRequestPath/types';

export function useRequestPath() {
  const {
    error: nuxtError,
    app: {
      i18n,
      $vsf: { $magento }
    }
  } = useContext();
  const route = useRoute();
  const { path } = route.value;

  const getFullProductBasedOnFullRequestPath = async function (specificRoute = path) {
    try {
      const cleanedPath: string = getCleanedPathForPDPandPlp(specificRoute);
      const { data, errors } = await $magento.api.customQuery({
        query: getProductUrlPath,
        queryVariables: {
          url: cleanedPath
        }
      });

      if (!data) {
        nuxtError({ statusCode: 404 });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const results = data?.route;

      if (!results) {
        if (errors) {
          console.error(errors);
        }
        nuxtError({ statusCode: 404 });
        return;
      }

      return results;
    } catch (error) {
      Logger.error('Get Full Product Data from URL path by route [ERROR]', error);
      console.error(error);
    }
  };

  const getFullCategoryBasedOnRequestPath = async function (specificRoute = path) {
    try {
      const cleanedPath: string = getCleanedPathForPDPandPlp(specificRoute);
      const { data, errors } = await $magento.api.customQuery({
        query: getCategoryUrlPath,
        queryVariables: {
          url: cleanedPath
        }
      });

      if (!data) {
        nuxtError({ statusCode: 404 });
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const results = data?.route;

      if (!results) {
        if (errors) {
          console.error(errors);
        }
        nuxtError({ statusCode: 404 });
        return;
      }

      return results;
    } catch (error) {
      Logger.error('Get Full Category Data from URL path by route [ERROR]', error);
      console.error(error);
    }
  };

  const getPath = (urlPath: string) => urlPath.replace(`/${i18n.locale}/`, '').trim();
  const getCleanedPathForPDPandPlp = function (urlPath: string) {
    if (urlPath.includes('p/') || urlPath.includes('l/')) {
      return getPath(urlPath);
    }
  };

  const getRelatedProductUrl = (product: ProductInterface): string => {
    const relatedPath = path && path.match(/\/l\/(.*?)\.html/);
    const activeCategoryUrlPath = relatedPath ? relatedPath?.[1] : '';

    const rewrites = product?.url_rewrites;
    let productUrl = product?.url_key ? `/p/${product.url_key}.html` : '';

    if (!rewrites || rewrites.length === 0 || !activeCategoryUrlPath) {
      return productUrl;
    }

    const productRelativeUrl = `p/${activeCategoryUrlPath}/${product.url_key}.html`;

    for (const rewrite of rewrites) {
      if (rewrite?.url && rewrite.url.includes(productRelativeUrl)) {
        productUrl = `/${rewrite.url}`;
        break;
      }
    }

    return productUrl;
  };

  const getProductURL = (product: ProductInterface, category): string => {
    const rewrites = product?.url_rewrites;
    let productUrl = product?.url_key ? `/p/${product.url_key}.html` : '';
    const categoryUrlPath = category?.url_path;

    if (!rewrites || rewrites.length === 0 || !categoryUrlPath) {
      return productUrl;
    }

    const productRelativeUrl = `p/${categoryUrlPath}/${product.url_key}.html`;

    for (const rewrite of rewrites) {
      if (rewrite?.url && rewrite.url.includes(productRelativeUrl)) {
        productUrl = `/${rewrite.url}`;
        break;
      }
    }

    return productUrl;
  };

  const getBreadcrumbs = (dataObject: ExtendedProductInterface | CategoryTree, isCategory = false) => {
    const breadcrumbsArrayFromDataObj = dataObject?.breadcrumbs || [];
    const homeBreadcrumb: BreadcrumbType = {
      link: '/',
      text: i18n.t('Home') as string
    };
    const currentBreadcrumb: BreadcrumbType = {
      link: '',
      text: dataObject?.name || ''
    };
    let breadcrumbs: Array<BreadcrumbType> = [];

    if (isCategory) {
      const categoryBreadcrumbs = breadcrumbsArrayFromDataObj.map((b) => ({
        text: b.category_name,
        link: '/l/' + b.category_url_path + '.html'
      }));
      breadcrumbs = [homeBreadcrumb, ...categoryBreadcrumbs, currentBreadcrumb];
    } else {
      // eslint-disable-next-line no-magic-numbers
      breadcrumbs = [homeBreadcrumb, ...breadcrumbsArrayFromDataObj.slice(0, -1), currentBreadcrumb];
    }

    return breadcrumbs;
  };

  return {
    getFullProductBasedOnFullRequestPath,
    getFullCategoryBasedOnRequestPath,
    getProductURL,
    getRelatedProductUrl,
    getBreadcrumbs
  };
}
