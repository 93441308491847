export declare type Maybe<T> = T | null;
export declare type InputMaybe<T> = Maybe<T>;
export declare type Exact<T extends {
  [key: string]: unknown;
}> = {
  [K in keyof T]: T[K];
};
export declare type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export declare type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
}
export interface AddBundleProductsToCartInput {
  cart_id: Scalars['String'];
  cart_items: Array<InputMaybe<BundleProductCartItemInput>>;
}
export interface AddBundleProductsToCartOutput {
  cart: Cart;
}
export interface AddConfigurableProductsToCartInput {
  cart_id: Scalars['String'];
  cart_items: Array<InputMaybe<ConfigurableProductCartItemInput>>;
}
export interface AddConfigurableProductsToCartOutput {
  cart: Cart;
}
export interface AddDownloadableProductsToCartInput {
  cart_id: Scalars['String'];
  cart_items: Array<InputMaybe<DownloadableProductCartItemInput>>;
}
export interface AddDownloadableProductsToCartOutput {
  cart: Cart;
}
export interface AddProductsToCartOutput {
  /** The cart after products have been added */
  cart: Cart;
  /** An error encountered while adding an item to the cart. */
  user_errors: Array<Maybe<CartUserInputError>>;
}
export interface AddProductsToCompareListInput {
  /** An array of product IDs to add to the compare list */
  products: Array<InputMaybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID'];
}
/** Contains the customer's wish list and any errors encountered */
export interface AddProductsToWishlistOutput {
  /** An array of errors encountered while adding products to a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully added */
  wishlist: Wishlist;
}
export interface AddProductsToCartInput {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
}

export interface AddSimpleProductsToCartInputValue {
  cart_id: Scalars['String'];
  cart_items: Array<Maybe<SimpleProductCartItemInput>>;
}

export interface AddSimpleProductsToCartInput {
  input: Array<Maybe<AddSimpleProductsToCartInputValue>>
}
export interface AddSimpleProductsToCartOutput {
  cart: Cart;
}
export interface AddVirtualProductsToCartInput {
  cart_id: Scalars['String'];
  cart_items: Array<InputMaybe<VirtualProductCartItemInput>>;
}
export interface AddVirtualProductsToCartOutput {
  cart: Cart;
}
export interface AddWishlistItemsToCartOutput {
  /** An array of errors encountered while adding products to the customer's cart */
  add_wishlist_items_to_cart_user_errors: Array<Maybe<WishlistCartUserInputError>>;
  /** Indicates whether the attempt to add items to the customer's cart was successful */
  status: Scalars['Boolean'];
  /** Contains the wish list with all items that were successfully added */
  wishlist: Wishlist;
}
/** A bucket that contains information for each filterable option (such as price, category `UID`, and custom attributes). */
export interface Aggregation {
  /** Attribute code of the aggregation group. */
  attribute_code: Scalars['String'];
  /** The number of options in the aggregation group. */
  count?: Maybe<Scalars['Int']>;
  /** The aggregation display name. */
  label?: Maybe<Scalars['String']>;
  /** Array of options for the aggregation. */
  options?: Maybe<Array<Maybe<AggregationOption>>>;
  /** The relative position of the attribute in a layered navigation block */
  position?: Maybe<Scalars['Int']>;
}
export interface AggregationOption extends AggregationOptionInterface {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
}
export interface AggregationOptionInterface {
  /** The number of items that match the aggregation option. */
  count?: Maybe<Scalars['Int']>;
  /** Aggregation option display label. */
  label?: Maybe<Scalars['String']>;
  /** The internal ID that represents the value of the option. */
  value: Scalars['String'];
}
export interface AppliedCoupon {
  code: Scalars['String'];
  free_shipping_coupon?: Scalars['Boolean'];
}
export interface ApplyCouponToCartInput {
  cart_id: Scalars['String'];
  coupon_code: Scalars['String'];
}
export interface ApplyCouponToCartOutput {
  cart: Cart;
}
/** AreaInput defines the parameters which will be used for filter by specified location. */
export interface AreaInput {
  /** The radius for the search in KM. */
  radius: Scalars['Int'];
  /** The country code where search must be performed. Required parameter together with region, city or postcode. */
  search_term: Scalars['String'];
}
export interface Assets {
  /** The payment method logo url (descriptive) */
  descriptive?: Maybe<Scalars['String']>;
  /** The payment method logo url (standard) */
  standard?: Maybe<Scalars['String']>;
}
export interface AssignCompareListToCustomerOutput {
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>;
  result: Scalars['Boolean'];
}
/** Attribute contains the attribute_type of the specified attribute_code and entity_type */
export interface Attribute {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute options list. */
  attribute_options?: Maybe<Array<Maybe<AttributeOption>>>;
  /** The data type of the attribute */
  attribute_type?: Maybe<Scalars['String']>;
  /** The type of entity that defines the attribute */
  entity_type?: Maybe<Scalars['String']>;
  /** The frontend input type of the attribute */
  input_type?: Maybe<Scalars['String']>;
  /** Contains details about the storefront properties configured for the attribute */
  storefront_properties?: Maybe<StorefrontProperties>;
}
/** AttributeInput specifies the attribute_code and entity_type to search */
export interface AttributeInput {
  /** The unique identifier for an attribute code. This value should be in lowercase letters without spaces. */
  attribute_code?: InputMaybe<Scalars['String']>;
  /** The type of entity that defines the attribute */
  entity_type?: InputMaybe<Scalars['String']>;
}
/** Attribute option. */
export interface AttributeOption {
  /** Attribute option label. */
  label?: Maybe<Scalars['String']>;
  /** Attribute option value. */
  value?: Maybe<Scalars['String']>;
}
export interface AvailablePaymentMethod {
  /** The payment method code */
  code: Scalars['String'];
  /** The payment method title. */
  title: Scalars['String'];
}
export interface AvailableShippingMethod {
  amount: Money;
  available: Scalars['Boolean'];
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>;
  carrier_code: Scalars['String'];
  carrier_title: Scalars['String'];
  error_message?: Maybe<Scalars['String']>;
  delivery_date_info?: Maybe<Scalars['String']>;
  /** Could be null if method is not available */
  method_code?: Maybe<Scalars['String']>;
  /** Could be null if method is not available */
  method_title?: Maybe<Scalars['String']>;
  price_excl_tax: Money;
  price_incl_tax: Money;
}
export interface BillingAddressInput {
  address?: InputMaybe<CartAddressInput>;
  customer_address_id?: InputMaybe<Scalars['Int']>;
  /** Set billing address same as shipping */
  same_as_shipping?: InputMaybe<Scalars['Boolean']>;
  /** Deprecated: use `same_as_shipping` field instead */
  use_for_shipping?: InputMaybe<Scalars['Boolean']>;
}
export interface BillingCartAddress extends CartAddressInterface {
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  /** @deprecated The field is used only in shipping address */
  customer_notes?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  firstnamekana?: InputMaybe<Scalars['String']>;
  lastnamekana?: InputMaybe<Scalars['String']>;
}
export interface BraintreeCcVaultInput {
  device_data?: InputMaybe<Scalars['String']>;
  public_hash: Scalars['String'];
}
export interface BraintreeInput {
  /** Contains a fingerprint provided by Braintree JS SDK and should be sent with sale transaction details to the Braintree payment gateway. Should be specified only in a case if Kount (advanced fraud protection) is enabled for Braintree payment integration. */
  device_data?: InputMaybe<Scalars['String']>;
  /** States whether an entered by a customer credit/debit card should be tokenized for later usage. Required only if Vault is enabled for Braintree payment integration. */
  is_active_payment_token_enabler: Scalars['Boolean'];
  /** The one-time payment token generated by Braintree payment gateway based on card details. Required field to make sale transaction. */
  payment_method_nonce: Scalars['String'];
}
export interface GmoPaymentCcVaultInput {
  public_hash: Scalars['String'];
  cc_masked_card_no: Scalars['String'];
  cc_method?: InputMaybe<Scalars['String']>;
  cc_pay_times?: InputMaybe<Scalars['String']>;
  cc_type: Scalars['String'];
}
export interface GmoPaymentCcInput {
  cc_cid: Scalars['String'];
  cc_exp_month: Scalars['String'];
  cc_exp_year: Scalars['String'];
  cc_holder_name?: InputMaybe<Scalars['String']>;
  cc_masked_card_no: Scalars['String'];
  cc_method?: InputMaybe<Scalars['String']>;
  cc_pay_times?: InputMaybe<Scalars['String']>;
  cc_token: Scalars['String'];
  cc_token_for_vault: Scalars['String'];
  cc_type?: InputMaybe<Scalars['String']>;
  is_active_payment_token_enabler: Scalars['Boolean'];
}

export interface ContactFormInput {
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  email: Scalars['String'];
  mobile: Scalars['String'];
  object: Scalars['String'];
  orderId?: InputMaybe<Scalars['String']>;
  comment: Scalars['String'];
}
/** Breadcrumb item. */
export interface Breadcrumb {
  /**
   * Category ID.
   * @deprecated Use the `category_uid` argument instead.
   */
  category_id?: Maybe<Scalars['Int']>;
  /** Category level. */
  category_level?: Maybe<Scalars['Int']>;
  /** Category name. */
  category_name?: Maybe<Scalars['String']>;
  /** The unique ID for a `Breadcrumb` object. */
  category_uid: Scalars['ID'];
  /** Category URL key. */
  category_url_key?: Maybe<Scalars['String']>;
  /** Category URL path. */
  category_url_path?: Maybe<Scalars['String']>;
}
export interface BundleCartItem extends CartItemInterface {
  bundle_options: Array<Maybe<SelectedBundleOption>>;
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
}
export interface BundleCreditMemoItem extends CreditMemoItemInterface {
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
}
export interface BundleInvoiceItem extends InvoiceItemInterface {
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
}
/** BundleItem defines an individual item in a bundle product. */
export interface BundleItem {
  /**
   * An ID assigned to each type of item in a bundle product.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** An array of additional options for this bundle item. */
  options?: Maybe<Array<Maybe<BundleItemOption>>>;
  /** he relative position of this item compared to the other bundle items. */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the item must be included in the bundle. */
  required?: Maybe<Scalars['Boolean']>;
  /** The SKU of the bundle product. */
  sku?: Maybe<Scalars['String']>;
  /** The display name of the item. */
  title?: Maybe<Scalars['String']>;
  /** The input type that the customer uses to select the item. Examples include radio button and checkbox. */
  type?: Maybe<Scalars['String']>;
  /** The unique ID for a `BundleItem` object. */
  uid?: Maybe<Scalars['ID']>;
}
/** BundleItemOption defines characteristics and options for a specific bundle item. */
export interface BundleItemOption {
  /** Indicates whether the customer can change the number of items for this option. */
  can_change_quantity?: Maybe<Scalars['Boolean']>;
  /**
   * The ID assigned to the bundled item option.
   * @deprecated Use `uid` instead
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether this option is the default option. */
  is_default?: Maybe<Scalars['Boolean']>;
  /** The text that identifies the bundled item option. */
  label?: Maybe<Scalars['String']>;
  /** When a bundle item contains multiple options, the relative position of this option compared to the other options. */
  position?: Maybe<Scalars['Int']>;
  /** The price of the selected option. */
  price?: Maybe<Scalars['Float']>;
  /** One of FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** Contains details about this product option. */
  product?: Maybe<ProductInterface>;
  /**
   * Indicates the quantity of this specific bundle item.
   * @deprecated The `qty` is deprecated. Use `quantity` instead.
   */
  qty?: Maybe<Scalars['Float']>;
  /** Indicates the quantity of this specific bundle item. */
  quantity?: Maybe<Scalars['Float']>;
  /** The unique ID for a `BundleItemOption` object. */
  uid: Scalars['ID'];
}
export interface BundleOptionInput {
  id: Scalars['Int'];
  quantity: Scalars['Float'];
  value: Array<InputMaybe<Scalars['String']>>;
}
export interface BundleOrderItem extends OrderItemInterface {
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
}
/** Defines basic features of a bundle product and contains multiple BundleItems */
export interface BundleProduct extends CustomizableProductInterface, PhysicalProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** Indicates whether the bundle product has a dynamic price. */
  dynamic_price?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamic SK. */
  dynamic_sku?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the bundle product has a dynamically calculated weight. */
  dynamic_weight?: Maybe<Scalars['Boolean']>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** An array containing information about individual bundle items. */
  items?: Maybe<Array<Maybe<BundleItem>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** One of PRICE_RANGE or AS_LOW_AS. */
  price_view?: Maybe<PriceViewEnum>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** Indicates whether to ship bundle items together or individually. */
  ship_bundle_items?: Maybe<ShipBundleItemsEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
}
/** Defines basic features of a bundle product and contains multiple BundleItems */
export interface BundleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
export interface BundleProductCartItemInput {
  bundle_options: Array<InputMaybe<BundleOptionInput>>;
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  data: CartItemInput;
}
export interface BundleShipmentItem extends ShipmentItemInterface {
  /** A list of bundle options that are assigned to the bundle product */
  bundle_options?: Maybe<Array<Maybe<ItemSelectedBundleOption>>>;
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
}
export interface BundleWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** An array containing information about the selected bundle items */
  bundle_options?: Maybe<Array<Maybe<SelectedBundleOption>>>;
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
export interface Cart {
  /**
   * An array of coupons that have been applied to the cart
   * @deprecated Use applied_coupons instead
   */
  applied_coupon?: Maybe<AppliedCoupon>;
  /** An array of `AppliedCoupon` objects. Each object contains the `code` text attribute, which specifies the coupon code */
  applied_coupons?: Maybe<Array<Maybe<AppliedCoupon>>>;
  applied_taxes?: Maybe<Array<object>>;
  fees?: Maybe<Money>;
  /** Available payment methods */
  available_payment_methods?: Maybe<Array<Maybe<AvailablePaymentMethod>>>;
  billing_address?: Maybe<BillingCartAddress>;
  email?: Maybe<Scalars['String']>;
  /** The entered gift message for the cart */
  gift_message?: Maybe<GiftMessage>;
  /** The unique ID for a `Cart` object */
  id: Scalars['ID'];
  is_virtual: Scalars['Boolean'];
  items?: Maybe<Array<Maybe<CartItemInterface>>>;
  prices?: Maybe<CartPrices>;
  selected_payment_method?: Maybe<SelectedPaymentMethod>;
  shipping_addresses: Array<Maybe<ShippingCartAddress>>;
  /** The Veriteworks delivery date info (for JP store) */
  vw_delivery_date_info?: Maybe<VwDeliveryDate>;
  total_quantity: Scalars['Float'];
  booxi_booking_info?: {
    cart_has_expired_booking: Scalars['Boolean'],
    error_message: Scalars['String']
  };
  freeshipping_threshold_info?: Maybe<FreeShippingThresholdInfo>
}
export interface FreeShippingThresholdInfo {
    freeshipping_enabled: Scalars['Boolean'],
    freeshipping_left: Scalars['Int'],
    currency_symbol: Scalars['String'],
    freeshipping_amount: Scalars['String']
}

export interface CartStockStatus {
  id: Scalars['ID'];
  items?: Maybe<Array<Maybe<CartItemInterface>>>;
}

export interface CartAddressCountry {
  code: Scalars['String'];
  label: Scalars['String'];
}
export interface CartAddressInput {
  city: Scalars['String'];
  company?: InputMaybe<Scalars['String']>;
  country_code: Scalars['String'];
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: InputMaybe<Scalars['String']>;
  region?: InputMaybe<Scalars['String']>;
  region_id?: InputMaybe<Scalars['Int']>;
  /** Determines whether to save the address in the customer's address book. The default value is true */
  save_in_address_book?: InputMaybe<Scalars['Boolean']>;
  street: Array<InputMaybe<Scalars['String']>>;
  telephone: Scalars['String'];
  firstnamekana?: InputMaybe<Scalars['String']>;
  lastnamekana?: InputMaybe<Scalars['String']>;
}
export interface CartAddressInterface {
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  firstname: Scalars['String'];
  lastname: Scalars['String'];
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  firstnamekana?: InputMaybe<Scalars['String']>;
  lastnamekana?: InputMaybe<Scalars['String']>;
}
export interface CartAddressRegion {
  code?: Maybe<Scalars['String']>;
  label?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
}
export interface CartDiscount {
  amount: Money;
  label: Array<Maybe<Scalars['String']>>;
}
export interface CartItemInput {
  /** An array of entered options for the base product, such as personalization text */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** For child products, the SKU of its parent product */
  parent_sku?: InputMaybe<Scalars['String']>;
  quantity: Scalars['Float'];
  /** The selected options for the base product, such as color or size with  unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `ConfigurableProductOptionsValues`, etc. objects */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  sku: Scalars['String'];
}
export interface CartItemInterface {
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
  booxi_item_info?: {
    is_booxi: Maybe<Scalars['Boolean']>;
    expired_time: Maybe<Scalars['String']>;
  };
}
export interface CartItemPrices {
  /** An array of discounts to be applied to the cart item */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** Applied FPT to the cart item. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  price: Money;
  row_total: Money;
  row_total_including_tax: Money;
  /** The total of all discounts applied to the item */
  total_item_discount?: Maybe<Money>;
}
/** Deprecated: `cart_items` field of `ShippingCartAddress` returns now  `CartItemInterface` instead of `CartItemQuantity` */
export interface CartItemQuantity {
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  cart_item_id: Scalars['Int'];
  /** @deprecated `cart_items` field of `ShippingCartAddress` returns now `CartItemInterface` instead of `CartItemQuantity` */
  quantity: Scalars['Float'];
}
export interface CartItemSelectedOptionValuePrice {
  type: PriceTypeEnum;
  units: Scalars['String'];
  value: Scalars['Float'];
}
export interface CartItemUpdateInput {
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']>;
  /** The unique ID for a `CartItemInterface` object */
  cart_item_uid?: InputMaybe<Scalars['ID']>;
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  /** Gift message details for the cart item */
  gift_message?: InputMaybe<GiftMessageInput>;
  quantity?: InputMaybe<Scalars['Float']>;
}
export interface CartPrices {
  applied_taxes?: Maybe<Array<Maybe<CartTaxItem>>>;
  fees?: Maybe<Money>;
  /** @deprecated Use discounts instead  */
  discount?: Maybe<CartDiscount>;
  /** An array of applied discounts */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  grand_total?: Maybe<Money>;
  subtotal_excluding_tax?: Maybe<Money>;
  subtotal_including_tax?: Maybe<Money>;
  subtotal_with_discount_excluding_tax?: Maybe<Money>;
  tax_amount?: Maybe<Scalars['String']>;
  vw_cod_fee?: Maybe<Money>;
}
export interface CartTaxItem {
  amount: Money;
  label: Scalars['String'];
}
/** An error encountered while adding an item to the the cart. */
export interface CartUserInputError {
  /** Cart-specific error code */
  code: CartUserInputErrorType;
  /** A localized error message */
  message: Scalars['String'];
}
export declare enum CartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}
export interface Categories {
  /** The payment method assets */
  asset_urls?: Maybe<Array<Maybe<Assets>>>;
  /** The payment method identifier */
  identifier: Scalars['String'];
  /** The payment method name */
  name: Scalars['String'];
}
/** CategoryFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface CategoryFilterInput {
  /** Filter by the unique category ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>;
  /** Deprecated: use 'category_uid' to filter uniquely identifiers of categories. */
  ids?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the display name of the category. */
  name?: InputMaybe<FilterMatchTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_category_uid?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the unique parent category ID for a `CategoryInterface` object. */
  parent_id?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the part of the URL that identifies the category. */
  url_key?: InputMaybe<FilterEqualTypeInput>;
  /** Filter by the URL path for the category. */
  url_path?: InputMaybe<FilterEqualTypeInput>;
}
/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterface {
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  children_count?: Maybe<Scalars['String']>;
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * Timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use the `uid` argument instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  image_hover?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** Category Path. */
  path?: Maybe<Scalars['String']>;
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
}
/** CategoryInterface contains the full set of attributes that can be returned in a category search. */
export interface CategoryInterfaceProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}
/** The category products object returned in the Category query. */
export interface CategoryProducts {
  /** An array of products that are assigned to the category. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
}
/** A collection of CategoryTree objects and pagination information. */
export interface CategoryResult {
  /** A list of categories that match the filter criteria. */
  items?: Maybe<Array<Maybe<CategoryTree>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total number of categories that match the criteria. */
  total_count?: Maybe<Scalars['Int']>;
}
/** Category tree implementation */
export interface CategoryTree extends CategoryInterface, RoutableInterface {
  available_sort_by?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Breadcrumbs, parent categories info. */
  breadcrumbs?: Maybe<Array<Maybe<Breadcrumb>>>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Categories' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** Child categories tree. */
  children?: Maybe<Array<Maybe<CategoryTree>>>;
  children_count?: Maybe<Scalars['String']>;
  /** Category CMS Block. */
  cms_block?: Maybe<CmsBlock>;
  /**
   * Timestamp indicating when the category was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  custom_layout_update_file?: Maybe<Scalars['String']>;
  /** The attribute to use for sorting. */
  default_sort_by?: Maybe<Scalars['String']>;
  /** An optional description of the category. */
  description?: Maybe<Scalars['String']>;
  display_mode?: Maybe<Scalars['String']>;
  filter_price_range?: Maybe<Scalars['Float']>;
  /**
   * An ID that uniquely identifies the category.
   * @deprecated Use the `uid` argument instead.
   */
  id?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  include_in_menu?: Maybe<Scalars['Int']>;
  is_anchor?: Maybe<Scalars['Int']>;
  landing_page?: Maybe<Scalars['Int']>;
  /** Indicates the depth of the category within the tree. */
  level?: Maybe<Scalars['Int']>;
  meta_description?: Maybe<Scalars['String']>;
  meta_keywords?: Maybe<Scalars['String']>;
  meta_title?: Maybe<Scalars['String']>;
  /** The display name of the category. */
  name?: Maybe<Scalars['String']>;
  /** Category Path. */
  path?: Maybe<Scalars['String']>;
  /** Category path in store. */
  path_in_store?: Maybe<Scalars['String']>;
  /** The position of the category relative to other categories at the same level in tree. */
  position?: Maybe<Scalars['Int']>;
  /** The number of products in the category that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  product_count?: Maybe<Scalars['Int']>;
  /** The list of products assigned to the category. */
  products?: Maybe<CategoryProducts>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** The unique ID for a `CategoryInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the category was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** The url key assigned to the category. */
  url_key?: Maybe<Scalars['String']>;
  /** The url path assigned to the category. */
  url_path?: Maybe<Scalars['String']>;
  /** The part of the category URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
}
/** Category tree implementation */
export interface CategoryTreeProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}
export interface ChatData {
  /** API space id */
  api_space_id?: Maybe<Scalars['String']>;
  /** Cookie name */
  cookie_name?: Maybe<Scalars['String']>;
  /** Is chat enabled */
  is_enabled?: Maybe<Scalars['Boolean']>;
}
/** Defines all Checkout Agreement information */
export interface CheckoutAgreement {
  /** Checkout Agreement identifier */
  agreement_id: Scalars['Int'];
  /** Checkout Agreement checkbox text */
  checkbox_text: Scalars['String'];
  /** Checkout Agreement content */
  content: Scalars['String'];
  /** Checkout Agreement content height */
  content_height?: Maybe<Scalars['String']>;
  /** Is Checkout Agreement content in HTML format */
  is_html: Scalars['Boolean'];
  mode: CheckoutAgreementMode;
  /** Checkout Agreement name */
  name: Scalars['String'];
}
export declare enum CheckoutAgreementMode {
  Auto = 'AUTO',
  Manual = 'MANUAL',
}
/** An error encountered while adding an item the the cart. */
export interface CheckoutUserInputError {
  /** Checkout-specific error code */
  code: CheckoutUserInputErrorCodes;
  /** Localized error message */
  message: Scalars['String'];
  /** Path to the input field that caused an error. See the GraphQL specification about path errors for details: http://spec.graphql.org/draft/#sec-Errors */
  path: Array<Maybe<Scalars['String']>>;
}
export declare enum CheckoutUserInputErrorCodes {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  ReorderNotAvailable = 'REORDER_NOT_AVAILABLE',
  Undefined = 'UNDEFINED',
}
/** CMS block defines all CMS block information */
export interface CmsBlock {
  /** CMS block content */
  content?: Maybe<Scalars['String']>;
  /** CMS block identifier */
  identifier?: Maybe<Scalars['String']>;
  /** CMS block title */
  title?: Maybe<Scalars['String']>;
}
/** CMS blocks information */
export interface CmsBlocks {
  /** An array of CMS blocks */
  items?: Maybe<Array<Maybe<CmsBlock>>>;
}
/** CMS page defines all CMS page information */
export interface CmsPage extends RoutableInterface {
  /** CMS page content */
  content?: Maybe<Scalars['String']>;
  /** CMS page content heading */
  content_heading?: Maybe<Scalars['String']>;
  /** Identifier of the CMS page */
  identifier?: Maybe<Scalars['String']>;
  /** CMS page meta description */
  meta_description?: Maybe<Scalars['String']>;
  /** CMS page meta keywords */
  meta_keywords?: Maybe<Scalars['String']>;
  /** CMS page meta title */
  meta_title?: Maybe<Scalars['String']>;
  /** CMS page content heading */
  page_layout?: Maybe<Scalars['String']>;
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** CMS page title */
  title?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /** URL key of CMS page */
  url_key?: Maybe<Scalars['String']>;
}
export interface ColorSwatchData extends SwatchDataInterface {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
}
export interface ComparableAttribute {
  /** An attribute code that is enabled for product comparisons */
  code: Scalars['String'];
  /** The label of the attribute code */
  label: Scalars['String'];
}
export interface ComparableItem {
  /** An array of product attributes that can be used to compare products */
  attributes: Array<Maybe<ProductAttribute>>;
  /** Contains details about a product in a compare list */
  product: ProductInterface;
  /** The unique ID of an item in a compare list */
  uid: Scalars['ID'];
}
export interface CompareList {
  /** An array of attributes that can be used for comparing products */
  attributes?: Maybe<Array<Maybe<ComparableAttribute>>>;
  /** The number of items in the compare list */
  item_count: Scalars['Int'];
  /** An array of products to compare */
  items?: Maybe<Array<Maybe<ComparableItem>>>;
  /** The unique ID assigned to the compare list */
  uid: Scalars['ID'];
}
export interface ComplexTextValue {
  label?: Maybe<Scalars['String']>;
  /** HTML format */
  html: Scalars['String'];
}
/** ConfigurableAttributeOption contains the value_index (and other related information) assigned to a configurable product option */
export interface ConfigurableAttributeOption {
  /** The ID assigned to the attribute */
  code?: Maybe<Scalars['String']>;
  /** A string that describes the configurable attribute option */
  label?: Maybe<Scalars['String']>;
  /** The unique ID for a `ConfigurableAttributeOption` object */
  uid: Scalars['ID'];
  /** A unique index number assigned to the configurable product option */
  value_index?: Maybe<Scalars['Int']>;
}
export interface ConfigurableCartItem extends CartItemInterface {
  configurable_options: Array<Maybe<SelectedConfigurableOption>>;
  /** Product details of the cart item */
  configured_variant: ProductInterface;
  customizable_options?: Maybe<Array<Maybe<SelectedCustomizableOption>>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
}
/** Configurable option available for further selection based on current selection. */
export interface ConfigurableOptionAvailableForSelection {
  /** Attribute code that uniquely identifies configurable option. */
  attribute_code: Scalars['String'];
  /** Configurable option values available for further selection. */
  option_value_uids: Array<Maybe<Scalars['ID']>>;
}
/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export interface ConfigurableProduct extends CustomizableProductInterface, PhysicalProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** An array of linked simple product items */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOptions>>>;
  /** Specified configurable product options selection */
  configurable_product_options_selection?: Maybe<ConfigurableProductOptionsSelection>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  /** The base price with excluding tax */
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** An array of variants of products */
  variants?: Maybe<Array<Maybe<ConfigurableVariant>>>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
}

/** ConfigurableProduct defines basic features of a configurable product and its simple product variants */
export interface ConfigurableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}

export interface ConfigurableProductCartItemInput {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  data: CartItemInput;
  /** Configurable product SKU. */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** Deprecated. Use CartItemInput.sku instead. */
  variant_sku?: InputMaybe<Scalars['String']>;
}
export interface ConfigurableProductOption {
  attribute_code: Scalars['String'];
  label: Scalars['String'];
  uid: Scalars['ID'];
  values?: Maybe<Array<Maybe<ConfigurableProductOptionValue>>>;
}
export interface ConfigurableProductOptionValue {
  is_available: Scalars['Boolean'];
  is_use_default: Scalars['Boolean'];
  label: Scalars['String'];
  swatch?: Maybe<SwatchDataInterface>;
  uid: Scalars['ID'];
}
/** ConfigurableProductOptions defines configurable attributes for the specified product */
export interface ConfigurableProductOptions {
  /** A string that identifies the attribute */
  attribute_code?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id?: Maybe<Scalars['String']>;
  /**
   * The ID assigned to the attribute
   * @deprecated Use attribute_uid instead
   */
  attribute_id_v2?: Maybe<Scalars['Int']>;
  /** The unique ID for a `Attribute` object */
  attribute_uid: Scalars['ID'];
  /**
   * The configurable option ID number assigned by the system
   * @deprecated Use uid instead
   */
  id?: Maybe<Scalars['Int']>;
  /** A string that describes the configurable product option, which is displayed on the UI */
  label?: Maybe<Scalars['String']>;
  /** A number that indicates the order in which the attribute is displayed */
  position?: Maybe<Scalars['Int']>;
  /**
   * This is the same as a product's id field
   * @deprecated `product_id` is not needed and can be obtained from it's parent
   */
  product_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `ConfigurableProductOptions` object */
  uid: Scalars['ID'];
  /** Indicates whether the option is the default */
  use_default?: Maybe<Scalars['Boolean']>;
  /** An array that defines the value_index codes assigned to the configurable product */
  values?: Maybe<Array<Maybe<ConfigurableProductOptionsValues>>>;
}
/** Metadata corresponding to the configurable options selection. */
export interface ConfigurableProductOptionsSelection {
  /** Configurable options available for further selection based on current selection. */
  configurable_options?: Maybe<Array<Maybe<ConfigurableProductOption>>>;
  /** Product images and videos corresponding to the specified configurable options selection. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /** Configurable options available for further selection based on current selection. */
  options_available_for_selection?: Maybe<Array<Maybe<ConfigurableOptionAvailableForSelection>>>;
  /** Variant represented by the specified configurable options selection. It is expected to be null, until selections are made for each configurable option. */
  variant?: Maybe<SimpleProduct>;
}
/** ConfigurableProductOptionsValues contains the index number assigned to a configurable product option */
export interface ConfigurableProductOptionsValues {
  /** The label of the product on the default store */
  default_label?: Maybe<Scalars['String']>;
  /** The label of the product */
  label?: Maybe<Scalars['String']>;
  /** The label of the product on the current store */
  store_label?: Maybe<Scalars['String']>;
  /** Swatch data for configurable product option */
  swatch_data?: Maybe<SwatchDataInterface>;
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  uid?: Maybe<Scalars['ID']>;
  /** Indicates whether to use the default_label */
  use_default_value?: Maybe<Scalars['Boolean']>;
  /**
   * A unique index number assigned to the configurable product option
   * @deprecated Use `uid` instead
   */
  value_index?: Maybe<Scalars['Int']>;
}
/** An array containing all the simple product variants of a configurable product */
export interface ConfigurableVariant {
  attributes?: Maybe<Array<Maybe<ConfigurableAttributeOption>>>;
  product?: Maybe<SimpleProduct>;
}
/** A configurable product wish list item */
export interface ConfigurableWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** The SKU of the simple product corresponding to a set of selected configurable options */
  child_sku: Scalars['String'];
  /** An array of selected configurable options */
  configurable_options?: Maybe<Array<Maybe<SelectedConfigurableOption>>>;
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
export interface Country {
  available_regions?: Maybe<Array<Maybe<Region>>>;
  full_name_english?: Maybe<Scalars['String']>;
  full_name_locale?: Maybe<Scalars['String']>;
  /** The unique ID for a `Country` object. */
  id?: Maybe<Scalars['String']>;
  three_letter_abbreviation?: Maybe<Scalars['String']>;
  two_letter_abbreviation?: Maybe<Scalars['String']>;
}
/** The list of countries codes */
export declare enum CountryCodeEnum {
  /** Andorra */
  Ad = 'AD',
  /** United Arab Emirates */
  Ae = 'AE',
  /** Afghanistan */
  Af = 'AF',
  /** Antigua & Barbuda */
  Ag = 'AG',
  /** Anguilla */
  Ai = 'AI',
  /** Albania */
  Al = 'AL',
  /** Armenia */
  Am = 'AM',
  /** Netherlands Antilles */
  An = 'AN',
  /** Angola */
  Ao = 'AO',
  /** Antarctica */
  Aq = 'AQ',
  /** Argentina */
  Ar = 'AR',
  /** American Samoa */
  As = 'AS',
  /** Austria */
  At = 'AT',
  /** Australia */
  Au = 'AU',
  /** Aruba */
  Aw = 'AW',
  /** Åland Islands */
  Ax = 'AX',
  /** Azerbaijan */
  Az = 'AZ',
  /** Bosnia & Herzegovina */
  Ba = 'BA',
  /** Barbados */
  Bb = 'BB',
  /** Bangladesh */
  Bd = 'BD',
  /** Belgium */
  Be = 'BE',
  /** Burkina Faso */
  Bf = 'BF',
  /** Bulgaria */
  Bg = 'BG',
  /** Bahrain */
  Bh = 'BH',
  /** Burundi */
  Bi = 'BI',
  /** Benin */
  Bj = 'BJ',
  /** St. Barthélemy */
  Bl = 'BL',
  /** Bermuda */
  Bm = 'BM',
  /** Brunei */
  Bn = 'BN',
  /** Bolivia */
  Bo = 'BO',
  /** Brazil */
  Br = 'BR',
  /** Bahamas */
  Bs = 'BS',
  /** Bhutan */
  Bt = 'BT',
  /** Bouvet Island */
  Bv = 'BV',
  /** Botswana */
  Bw = 'BW',
  /** Belarus */
  By = 'BY',
  /** Belize */
  Bz = 'BZ',
  /** Canada */
  Ca = 'CA',
  /** Cocos (Keeling) Islands */
  Cc = 'CC',
  /** Congo-Kinshasa */
  Cd = 'CD',
  /** Central African Republic */
  Cf = 'CF',
  /** Congo-Brazzaville */
  Cg = 'CG',
  /** Switzerland */
  Ch = 'CH',
  /** Côte d’Ivoire */
  Ci = 'CI',
  /** Cook Islands */
  Ck = 'CK',
  /** Chile */
  Cl = 'CL',
  /** Cameroon */
  Cm = 'CM',
  /** China */
  Cn = 'CN',
  /** Colombia */
  Co = 'CO',
  /** Costa Rica */
  Cr = 'CR',
  /** Cuba */
  Cu = 'CU',
  /** Cape Verde */
  Cv = 'CV',
  /** Christmas Island */
  Cx = 'CX',
  /** Cyprus */
  Cy = 'CY',
  /** Czech Republic */
  Cz = 'CZ',
  /** Germany */
  De = 'DE',
  /** Djibouti */
  Dj = 'DJ',
  /** Denmark */
  Dk = 'DK',
  /** Dominica */
  Dm = 'DM',
  /** Dominican Republic */
  Do = 'DO',
  /** Algeria */
  Dz = 'DZ',
  /** Ecuador */
  Ec = 'EC',
  /** Estonia */
  Ee = 'EE',
  /** Egypt */
  Eg = 'EG',
  /** Western Sahara */
  Eh = 'EH',
  /** Eritrea */
  Er = 'ER',
  /** Spain */
  Es = 'ES',
  /** Ethiopia */
  Et = 'ET',
  /** Finland */
  Fi = 'FI',
  /** Fiji */
  Fj = 'FJ',
  /** Falkland Islands */
  Fk = 'FK',
  /** Micronesia */
  Fm = 'FM',
  /** Faroe Islands */
  Fo = 'FO',
  /** France */
  Fr = 'FR',
  /** Gabon */
  Ga = 'GA',
  /** United Kingdom */
  Gb = 'GB',
  /** Grenada */
  Gd = 'GD',
  /** Georgia */
  Ge = 'GE',
  /** French Guiana */
  Gf = 'GF',
  /** Guernsey */
  Gg = 'GG',
  /** Ghana */
  Gh = 'GH',
  /** Gibraltar */
  Gi = 'GI',
  /** Greenland */
  Gl = 'GL',
  /** Gambia */
  Gm = 'GM',
  /** Guinea */
  Gn = 'GN',
  /** Guadeloupe */
  Gp = 'GP',
  /** Equatorial Guinea */
  Gq = 'GQ',
  /** Greece */
  Gr = 'GR',
  /** South Georgia & South Sandwich Islands */
  Gs = 'GS',
  /** Guatemala */
  Gt = 'GT',
  /** Guam */
  Gu = 'GU',
  /** Guinea-Bissau */
  Gw = 'GW',
  /** Guyana */
  Gy = 'GY',
  /** Hong Kong SAR China */
  Hk = 'HK',
  /** Heard &amp; McDonald Islands */
  Hm = 'HM',
  /** Honduras */
  Hn = 'HN',
  /** Croatia */
  Hr = 'HR',
  /** Haiti */
  Ht = 'HT',
  /** Hungary */
  Hu = 'HU',
  /** Indonesia */
  Id = 'ID',
  /** Ireland */
  Ie = 'IE',
  /** Israel */
  Il = 'IL',
  /** Isle of Man */
  Im = 'IM',
  /** India */
  In = 'IN',
  /** British Indian Ocean Territory */
  Io = 'IO',
  /** Iraq */
  Iq = 'IQ',
  /** Iran */
  Ir = 'IR',
  /** Iceland */
  Is = 'IS',
  /** Italy */
  It = 'IT',
  /** Jersey */
  Je = 'JE',
  /** Jamaica */
  Jm = 'JM',
  /** Jordan */
  Jo = 'JO',
  /** Japan */
  Jp = 'JP',
  /** Kenya */
  Ke = 'KE',
  /** Kyrgyzstan */
  Kg = 'KG',
  /** Cambodia */
  Kh = 'KH',
  /** Kiribati */
  Ki = 'KI',
  /** Comoros */
  Km = 'KM',
  /** St. Kitts & Nevis */
  Kn = 'KN',
  /** North Korea */
  Kp = 'KP',
  /** South Korea */
  Kr = 'KR',
  /** Kuwait */
  Kw = 'KW',
  /** Cayman Islands */
  Ky = 'KY',
  /** Kazakhstan */
  Kz = 'KZ',
  /** Laos */
  La = 'LA',
  /** Lebanon */
  Lb = 'LB',
  /** St. Lucia */
  Lc = 'LC',
  /** Liechtenstein */
  Li = 'LI',
  /** Sri Lanka */
  Lk = 'LK',
  /** Liberia */
  Lr = 'LR',
  /** Lesotho */
  Ls = 'LS',
  /** Lithuania */
  Lt = 'LT',
  /** Luxembourg */
  Lu = 'LU',
  /** Latvia */
  Lv = 'LV',
  /** Libya */
  Ly = 'LY',
  /** Morocco */
  Ma = 'MA',
  /** Monaco */
  Mc = 'MC',
  /** Moldova */
  Md = 'MD',
  /** Montenegro */
  Me = 'ME',
  /** St. Martin */
  Mf = 'MF',
  /** Madagascar */
  Mg = 'MG',
  /** Marshall Islands */
  Mh = 'MH',
  /** Macedonia */
  Mk = 'MK',
  /** Mali */
  Ml = 'ML',
  /** Myanmar (Burma) */
  Mm = 'MM',
  /** Mongolia */
  Mn = 'MN',
  /** Macau SAR China */
  Mo = 'MO',
  /** Northern Mariana Islands */
  Mp = 'MP',
  /** Martinique */
  Mq = 'MQ',
  /** Mauritania */
  Mr = 'MR',
  /** Montserrat */
  Ms = 'MS',
  /** Malta */
  Mt = 'MT',
  /** Mauritius */
  Mu = 'MU',
  /** Maldives */
  Mv = 'MV',
  /** Malawi */
  Mw = 'MW',
  /** Mexico */
  Mx = 'MX',
  /** Malaysia */
  My = 'MY',
  /** Mozambique */
  Mz = 'MZ',
  /** Namibia */
  Na = 'NA',
  /** New Caledonia */
  Nc = 'NC',
  /** Niger */
  Ne = 'NE',
  /** Norfolk Island */
  Nf = 'NF',
  /** Nigeria */
  Ng = 'NG',
  /** Nicaragua */
  Ni = 'NI',
  /** Netherlands */
  Nl = 'NL',
  /** Norway */
  No = 'NO',
  /** Nepal */
  Np = 'NP',
  /** Nauru */
  Nr = 'NR',
  /** Niue */
  Nu = 'NU',
  /** New Zealand */
  Nz = 'NZ',
  /** Oman */
  Om = 'OM',
  /** Panama */
  Pa = 'PA',
  /** Peru */
  Pe = 'PE',
  /** French Polynesia */
  Pf = 'PF',
  /** Papua New Guinea */
  Pg = 'PG',
  /** Philippines */
  Ph = 'PH',
  /** Pakistan */
  Pk = 'PK',
  /** Poland */
  Pl = 'PL',
  /** St. Pierre & Miquelon */
  Pm = 'PM',
  /** Pitcairn Islands */
  Pn = 'PN',
  /** Palestinian Territories */
  Ps = 'PS',
  /** Portugal */
  Pt = 'PT',
  /** Palau */
  Pw = 'PW',
  /** Paraguay */
  Py = 'PY',
  /** Qatar */
  Qa = 'QA',
  /** Réunion */
  Re = 'RE',
  /** Romania */
  Ro = 'RO',
  /** Serbia */
  Rs = 'RS',
  /** Russia */
  Ru = 'RU',
  /** Rwanda */
  Rw = 'RW',
  /** Saudi Arabia */
  Sa = 'SA',
  /** Solomon Islands */
  Sb = 'SB',
  /** Seychelles */
  Sc = 'SC',
  /** Sudan */
  Sd = 'SD',
  /** Sweden */
  Se = 'SE',
  /** Singapore */
  Sg = 'SG',
  /** St. Helena */
  Sh = 'SH',
  /** Slovenia */
  Si = 'SI',
  /** Svalbard & Jan Mayen */
  Sj = 'SJ',
  /** Slovakia */
  Sk = 'SK',
  /** Sierra Leone */
  Sl = 'SL',
  /** San Marino */
  Sm = 'SM',
  /** Senegal */
  Sn = 'SN',
  /** Somalia */
  So = 'SO',
  /** Suriname */
  Sr = 'SR',
  /** São Tomé & Príncipe */
  St = 'ST',
  /** El Salvador */
  Sv = 'SV',
  /** Syria */
  Sy = 'SY',
  /** Swaziland */
  Sz = 'SZ',
  /** Turks & Caicos Islands */
  Tc = 'TC',
  /** Chad */
  Td = 'TD',
  /** French Southern Territories */
  Tf = 'TF',
  /** Togo */
  Tg = 'TG',
  /** Thailand */
  Th = 'TH',
  /** Tajikistan */
  Tj = 'TJ',
  /** Tokelau */
  Tk = 'TK',
  /** Timor-Leste */
  Tl = 'TL',
  /** Turkmenistan */
  Tm = 'TM',
  /** Tunisia */
  Tn = 'TN',
  /** Tonga */
  To = 'TO',
  /** Turkey */
  Tr = 'TR',
  /** Trinidad & Tobago */
  Tt = 'TT',
  /** Tuvalu */
  Tv = 'TV',
  /** Taiwan */
  Tw = 'TW',
  /** Tanzania */
  Tz = 'TZ',
  /** Ukraine */
  Ua = 'UA',
  /** Uganda */
  Ug = 'UG',
  /** U.S. Outlying Islands */
  Um = 'UM',
  /** United States */
  Us = 'US',
  /** Uruguay */
  Uy = 'UY',
  /** Uzbekistan */
  Uz = 'UZ',
  /** Vatican City */
  Va = 'VA',
  /** St. Vincent & Grenadines */
  Vc = 'VC',
  /** Venezuela */
  Ve = 'VE',
  /** British Virgin Islands */
  Vg = 'VG',
  /** U.S. Virgin Islands */
  Vi = 'VI',
  /** Vietnam */
  Vn = 'VN',
  /** Vanuatu */
  Vu = 'VU',
  /** Wallis & Futuna */
  Wf = 'WF',
  /** Samoa */
  Ws = 'WS',
  /** Yemen */
  Ye = 'YE',
  /** Mayotte */
  Yt = 'YT',
  /** South Africa */
  Za = 'ZA',
  /** Zambia */
  Zm = 'ZM',
  /** Zimbabwe */
  Zw = 'ZW',
}
export interface CreateCompareListInput {
  /** An array of product IDs to add to the compare list */
  products?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
}
/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface CreatePayflowProTokenOutput {
  response_message: Scalars['String'];
  result: Scalars['Int'];
  result_code: Scalars['Int'];
  secure_token: Scalars['String'];
  secure_token_id: Scalars['String'];
}
export interface CreateProductReviewInput {
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String'];
  /** Ratings details by category. e.g price: 5, quality: 4 etc */
  ratings: Array<InputMaybe<ProductReviewRatingInput>>;
  /** The SKU of the reviewed product */
  sku: Scalars['String'];
  /** The summary (title) of the review */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
  /** The reCaptcha Token. */
  recaptchaToken?: Scalars['String'];
}
export interface CreateProductReviewOutput {
  /** Contains the completed product review */
  review: ProductReview;
}
/** Required fields for Payflow Pro and Payments Pro credit card payments */
export interface CreditCardDetailsInput {
  /** Credit card expiration month */
  cc_exp_month: Scalars['Int'];
  /** Credit card expiration year */
  cc_exp_year: Scalars['Int'];
  /** Last 4 digits of the credit card */
  cc_last_4: Scalars['Int'];
  /** Credit card type */
  cc_type: Scalars['String'];
}
/** Credit memo details */
export interface CreditMemo {
  /** Comments on the credit memo */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `CreditMemo` object */
  id: Scalars['ID'];
  /** An array containing details about refunded items */
  items?: Maybe<Array<Maybe<CreditMemoItemInterface>>>;
  /** The sequential credit memo number */
  number: Scalars['String'];
  /** Contains details about the total refunded amount */
  total?: Maybe<CreditMemoTotal>;
}
export interface CreditMemoItem extends CreditMemoItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
}
/** Credit memo item details */
export interface CreditMemoItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
}
/** Credit memo price details */
export interface CreditMemoTotal {
  /** An adjustment manually applied to the order */
  adjustment: Money;
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the credit memo */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the credit memo */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The credit memo tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the credit memo */
  total_shipping: Money;
  /** The amount of tax applied to the credit memo */
  total_tax: Money;
}
export interface Currency {
  available_currency_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  base_currency_code?: Maybe<Scalars['String']>;
  base_currency_symbol?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_code`. */
  default_display_currecy_code?: Maybe<Scalars['String']>;
  /** @deprecated Symbol was missed. Use `default_display_currency_symbol`. */
  default_display_currecy_symbol?: Maybe<Scalars['String']>;
  default_display_currency_code?: Maybe<Scalars['String']>;
  default_display_currency_symbol?: Maybe<Scalars['String']>;
  exchange_rates?: Maybe<Array<Maybe<ExchangeRate>>>;
}
/** The list of available currency codes */
export declare enum CurrencyEnum {
  Aed = 'AED',
  Afn = 'AFN',
  All = 'ALL',
  Amd = 'AMD',
  Ang = 'ANG',
  Aoa = 'AOA',
  Ars = 'ARS',
  Aud = 'AUD',
  Awg = 'AWG',
  Azm = 'AZM',
  Azn = 'AZN',
  Bam = 'BAM',
  Bbd = 'BBD',
  Bdt = 'BDT',
  Bgn = 'BGN',
  Bhd = 'BHD',
  Bif = 'BIF',
  Bmd = 'BMD',
  Bnd = 'BND',
  Bob = 'BOB',
  Brl = 'BRL',
  Bsd = 'BSD',
  Btn = 'BTN',
  Buk = 'BUK',
  Bwp = 'BWP',
  Byn = 'BYN',
  Bzd = 'BZD',
  Cad = 'CAD',
  Cdf = 'CDF',
  Che = 'CHE',
  Chf = 'CHF',
  Chw = 'CHW',
  Clp = 'CLP',
  Cny = 'CNY',
  Cop = 'COP',
  Crc = 'CRC',
  Cup = 'CUP',
  Cve = 'CVE',
  Czk = 'CZK',
  Djf = 'DJF',
  Dkk = 'DKK',
  Dop = 'DOP',
  Dzd = 'DZD',
  Eek = 'EEK',
  Egp = 'EGP',
  Ern = 'ERN',
  Etb = 'ETB',
  Eur = 'EUR',
  Fjd = 'FJD',
  Fkp = 'FKP',
  Gbp = 'GBP',
  Gek = 'GEK',
  Gel = 'GEL',
  Ghs = 'GHS',
  Gip = 'GIP',
  Gmd = 'GMD',
  Gnf = 'GNF',
  Gqe = 'GQE',
  Gtq = 'GTQ',
  Gyd = 'GYD',
  Hkd = 'HKD',
  Hnl = 'HNL',
  Hrk = 'HRK',
  Htg = 'HTG',
  Huf = 'HUF',
  Idr = 'IDR',
  Ils = 'ILS',
  Inr = 'INR',
  Iqd = 'IQD',
  Irr = 'IRR',
  Isk = 'ISK',
  Jmd = 'JMD',
  Jod = 'JOD',
  Jpy = 'JPY',
  Kes = 'KES',
  Kgs = 'KGS',
  Khr = 'KHR',
  Kmf = 'KMF',
  Kpw = 'KPW',
  Krw = 'KRW',
  Kwd = 'KWD',
  Kyd = 'KYD',
  Kzt = 'KZT',
  Lak = 'LAK',
  Lbp = 'LBP',
  Lkr = 'LKR',
  Lrd = 'LRD',
  Lsl = 'LSL',
  Lsm = 'LSM',
  Ltl = 'LTL',
  Lvl = 'LVL',
  Lyd = 'LYD',
  Mad = 'MAD',
  Mdl = 'MDL',
  Mga = 'MGA',
  Mkd = 'MKD',
  Mmk = 'MMK',
  Mnt = 'MNT',
  Mop = 'MOP',
  Mro = 'MRO',
  Mur = 'MUR',
  Mvr = 'MVR',
  Mwk = 'MWK',
  Mxn = 'MXN',
  Myr = 'MYR',
  Mzn = 'MZN',
  Nad = 'NAD',
  Ngn = 'NGN',
  Nic = 'NIC',
  Nok = 'NOK',
  Npr = 'NPR',
  Nzd = 'NZD',
  Omr = 'OMR',
  Pab = 'PAB',
  Pen = 'PEN',
  Pgk = 'PGK',
  Php = 'PHP',
  Pkr = 'PKR',
  Pln = 'PLN',
  Pyg = 'PYG',
  Qar = 'QAR',
  Rhd = 'RHD',
  Rol = 'ROL',
  Ron = 'RON',
  Rsd = 'RSD',
  Rub = 'RUB',
  Rwf = 'RWF',
  Sar = 'SAR',
  Sbd = 'SBD',
  Scr = 'SCR',
  Sdg = 'SDG',
  Sek = 'SEK',
  Sgd = 'SGD',
  Shp = 'SHP',
  Skk = 'SKK',
  Sll = 'SLL',
  Sos = 'SOS',
  Srd = 'SRD',
  Std = 'STD',
  Svc = 'SVC',
  Syp = 'SYP',
  Szl = 'SZL',
  Thb = 'THB',
  Tjs = 'TJS',
  Tmm = 'TMM',
  Tnd = 'TND',
  Top = 'TOP',
  Trl = 'TRL',
  Try = 'TRY',
  Ttd = 'TTD',
  Twd = 'TWD',
  Tzs = 'TZS',
  Uah = 'UAH',
  Ugx = 'UGX',
  Usd = 'USD',
  Uyu = 'UYU',
  Uzs = 'UZS',
  Veb = 'VEB',
  Vef = 'VEF',
  Vnd = 'VND',
  Vuv = 'VUV',
  Wst = 'WST',
  Xcd = 'XCD',
  Xof = 'XOF',
  Xpf = 'XPF',
  Yer = 'YER',
  Ytl = 'YTL',
  Zar = 'ZAR',
  Zmk = 'ZMK',
  Zwd = 'ZWD',
}
/** CustomAttributeMetadata defines an array of attribute_codes and entity_types */
export interface CustomAttributeMetadata {
  /** An array of attributes */
  items?: Maybe<Array<Maybe<Attribute>>>;
}
/** Customer defines the customer name and address and other details */
export interface Customer {
  /** An array containing the customer's shipping and billing addresses */
  addresses?: Maybe<Array<Maybe<CustomerAddress>>>;
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance: Scalars['Boolean'];
  /** The contents of the customer's compare list */
  compare_list?: Maybe<CompareList>;
  /** Timestamp indicating when the account was created */
  created_at?: Maybe<Scalars['String']>;
  /** The customer's date of birth */
  date_of_birth?: Maybe<Scalars['String']>;
  /** The ID assigned to the billing address */
  default_billing?: Maybe<Scalars['String']>;
  /** The ID assigned to the shipping address */
  default_shipping?: Maybe<Scalars['String']>;
  /** The customer's mobile number */
  mobile?: Maybe<Scalars['String']>;
  /**
   * The customer's date of birth
   * @deprecated Use `date_of_birth` instead
   */
  dob?: Maybe<Scalars['String']>;
  /** The customer's email address. Required */
  email?: Maybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: Maybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: Maybe<Scalars['Int']>;
  /** @deprecated Customer group should not be exposed in the storefront scenarios */
  group_id?: Maybe<Scalars['Int']>;
  /**
   * The ID assigned to the customer
   * @deprecated id is not needed as part of Customer because on server side it can be identified based on customer token used for authentication. There is no need to know customer ID on the client side.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: Maybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: Maybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: Maybe<Scalars['String']>;
  orders?: Maybe<CustomerOrders>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** Contains the customer's product reviews */
  reviews: ProductReviews;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  taxvat?: Maybe<Scalars['String']>;
  /**
   * Contains a customer's wish lists
   * @deprecated Use `Customer.wishlists` or `Customer.wishlist_v2`
   */
  wishlist: Wishlist;
  /** Retrieve the specified wish list identified by the unique ID for a `Wishlist` object */
  wishlist_v2?: Maybe<Wishlist>;
  /** An array of wishlists. In Magento Open Source, customers are limited to one wish list. The number of wish lists is configurable for Magento Commerce */
  wishlists: Array<Maybe<Wishlist>>;
  /** The first name kana of the person only for Japan */
  firstnamekana?: Maybe<Scalars['String']>;
  /** The last name kana of the person only for Japan */
  lastnamekana?: Maybe<Scalars['String']>;
}
/** Customer defines the customer name and address and other details */
export interface CustomerOrdersArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerOrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
/** Customer defines the customer name and address and other details */
export interface CustomerReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}

/** Customer defines the customer name and address and other details */
export interface CustomerWishlistsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
/** CustomerAddress contains detailed information about a customer's billing and shipping addresses */
export interface CustomerAddress {
  /** The city or town */
  city?: Maybe<Scalars['String']>;
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>;
  /**
   * The customer's country
   * @deprecated Use `country_code` instead.
   */
  country_id?: Maybe<Scalars['String']>;
  /** @deprecated Custom attributes should not be put into container */
  custom_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /**
   * The customer ID
   * @deprecated customer_id is not needed as part of CustomerAddress, address ID (id) is unique identifier for the addresses.
   */
  customer_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the address is the default billing address */
  default_billing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address */
  default_shipping?: Maybe<Scalars['Boolean']>;
  /** Address extension attributes */
  extension_attributes?: Maybe<Array<Maybe<CustomerAddressAttribute>>>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname?: Maybe<Scalars['String']>;
  /** The ID assigned to the address object */
  id?: Maybe<Scalars['Int']>;
  /** The family name of the person associated with the shipping/billing address */
  lastname?: Maybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID */
  region?: Maybe<CustomerAddressRegion>;
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']>;
  /** An array of strings that define the street number and name */
  street?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone?: Maybe<Scalars['String']>;
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
  /** The first name kana of the person associated with the shipping/billing address only for Japan */
  firstnamekana?: InputMaybe<Scalars['String']>;
  /** The last name kana of the person associated with the shipping/billing address only for Japan */
  lastnamekana?: InputMaybe<Scalars['String']>;
  /** The type of the address (shipping or billing) */
  address_book_type?: Maybe<Scalars['String']>;
}
export interface CustomerAddressAttribute {
  /** Attribute code */
  attribute_code?: Maybe<Scalars['String']>;
  /** Attribute value */
  value?: Maybe<Scalars['String']>;
}
export interface CustomerAddressAttributeInput {
  /** Attribute code */
  attribute_code: Scalars['String'];
  /** Attribute value */
  value: Scalars['String'];
}
export interface CustomerAddressInput {
  /** The city or town */
  city?: InputMaybe<Scalars['String']>;
  /** The customer's company */
  company?: InputMaybe<Scalars['String']>;
  /** The customer's country */
  country_code?: InputMaybe<CountryCodeEnum>;
  /** Deprecated: use `country_code` instead. */
  country_id?: InputMaybe<CountryCodeEnum>;
  /** Deprecated: Custom attributes should not be put into container. */
  custom_attributes?: InputMaybe<Array<InputMaybe<CustomerAddressAttributeInput>>>;
  /** Indicates whether the address is the default billing address */
  default_billing?: InputMaybe<Scalars['Boolean']>;
  /** Indicates whether the address is the default shipping address */
  default_shipping?: InputMaybe<Scalars['Boolean']>;
  /** The fax number */
  fax?: InputMaybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname?: InputMaybe<Scalars['String']>;
  /** The family name of the person associated with the shipping/billing address */
  lastname?: InputMaybe<Scalars['String']>;
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer's ZIP or postal code */
  postcode?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** An object containing the region name, region code, and region ID */
  region?: InputMaybe<CustomerAddressRegionInput>;
  /** An array of strings that define the street number and name */
  street?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']>;
  /** The telephone number */
  telephone?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  vat_id?: InputMaybe<Scalars['String']>;
  /** The first name kana of the person associated with the shipping/billing address only for Japan */
  firstnamekana?: InputMaybe<Scalars['String']>;
  /** The last name kana of the person associated with the shipping/billing address only for Japan */
  lastnamekana?: InputMaybe<Scalars['String']>;
}
/** CustomerAddressRegion defines the customer's state or province */
export interface CustomerAddressRegion {
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The address region code */
  region_code?: Maybe<Scalars['String']>;
  /** The unique ID for a pre-defined region */
  region_id?: Maybe<Scalars['Int']>;
}
/** CustomerAddressRegionInput defines the customer's state or province */
export interface CustomerAddressRegionInput {
  /** The state or province name */
  region?: InputMaybe<Scalars['String']>;
  /** The address region code */
  region_code?: InputMaybe<Scalars['String']>;
  /** The unique ID for a pre-defined region */
  region_id?: InputMaybe<Scalars['Int']>;
}
export interface CustomerCreateInput {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']>;
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's email address. Required for customer creation */
  email: Scalars['String'];
  /** The customer's first name */
  firstname: Scalars['String'];
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname: Scalars['String'];
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer's password */
  password?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']>;
  /** The reCaptcha Token */
  recaptchaToken?: InputMaybe<Scalars['String']>;
  mobile?: InputMaybe<Scalars['String']>;
  /** The first name kana of the person only for Japan */
  firstnamekana?: InputMaybe<Scalars['String']>;
  /** The last name kana of the person only for Japan */
  lastnamekana?: InputMaybe<Scalars['String']>;
}
export interface CustomerDownloadableProduct {
  date?: Maybe<Scalars['String']>;
  download_url?: Maybe<Scalars['String']>;
  order_increment_id?: Maybe<Scalars['String']>;
  remaining_downloads?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
}
export interface CustomerDownloadableProducts {
  /** List of purchased downloadable items */
  items?: Maybe<Array<Maybe<CustomerDownloadableProduct>>>;
}
export interface CustomerInput {
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's email address. Required for customer creation */
  email?: InputMaybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: InputMaybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: InputMaybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']>;
  /** The customer's password */
  password?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']>;
}
/** Contains details about each of the customer's orders */
export interface CustomerOrder {
  /** The billing address for the order */
  billing_address?: Maybe<OrderAddress>;
  /** The shipping carrier for the order delivery */
  carrier?: Maybe<Scalars['String']>;
  /** Comments about the order */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** @deprecated Use the order_date attribute instead */
  created_at?: Maybe<Scalars['String']>;
  /** A list of credit memos */
  credit_memos?: Maybe<Array<Maybe<CreditMemo>>>;
  /** The entered gift message for the order */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use the totals.grand_total attribute instead */
  grand_total?: Maybe<Scalars['Float']>;
  /** The unique ID for a `CustomerOrder` object */
  id: Scalars['ID'];
  /** @deprecated Use the id attribute instead */
  increment_id?: Maybe<Scalars['String']>;
  /** A list of invoices for the order */
  invoices: Array<Maybe<Invoice>>;
  /** An array containing the items purchased in this order */
  items?: Maybe<Array<Maybe<OrderItemInterface>>>;
  /** The order number */
  number: Scalars['String'];
  /** The date the order was placed */
  order_date: Scalars['String'];
  /** @deprecated Use the number attribute instead */
  order_number: Scalars['String'];
  /** Payment details for the order */
  payment_methods?: Maybe<Array<Maybe<OrderPaymentMethod>>>;
  /** A list of shipments for the order */
  shipments?: Maybe<Array<Maybe<OrderShipment>>>;
  /** The shipping address for the order */
  shipping_address?: Maybe<OrderAddress>;
  /** The delivery method for the order */
  shipping_method?: Maybe<Scalars['String']>;
  /** The Veriteworks delivery date info (for JP store) */
  vw_delivery_date_info?: Maybe<VwDeliveryDate>;
  /** The current status of the order */
  status: Scalars['String'];
  /** Contains details about the calculated totals for this order */
  total?: Maybe<OrderTotal>;
}
/** The collection of orders that match the conditions defined in the filter */
export interface CustomerOrders {
  /** An array of customer orders */
  items: Array<Maybe<CustomerOrder>>;
  /** An object that includes the current_page, page_info, and page_size values specified in the query */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The total count of customer orders */
  total_count?: Maybe<Scalars['Int']>;
}
/** Identifies the filter to use for filtering orders. */
export interface CustomerOrdersFilterInput {
  /** Filters by order number. */
  number?: InputMaybe<FilterStringTypeInput>;
}
export interface CustomerOutput {
  customer: Customer;
}
export interface CustomerPaymentTokens {
  /** An array of payment tokens */
  items: Array<Maybe<PaymentToken>>;
}
export interface CustomerToken {
  /** The customer token */
  token?: Maybe<Scalars['String']>;
}
export interface CustomerUpdateInput {
  /** Indicates whether the customer has enabled remote shopping assistance */
  allow_remote_shopping_assistance?: InputMaybe<Scalars['Boolean']>;
  /** The customer's date of birth */
  date_of_birth?: InputMaybe<Scalars['String']>;
  /** Deprecated: Use `date_of_birth` instead */
  dob?: InputMaybe<Scalars['String']>;
  /** The customer's first name */
  firstname?: InputMaybe<Scalars['String']>;
  /** The customer's gender (Male - 1, Female - 2) */
  gender?: InputMaybe<Scalars['Int']>;
  /** Indicates whether the customer is subscribed to the company's newsletter */
  is_subscribed?: InputMaybe<Scalars['Boolean']>;
  /** The customer's family name */
  lastname?: InputMaybe<Scalars['String']>;
  /** The customer's middle name */
  middlename?: InputMaybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: InputMaybe<Scalars['String']>;
  /** A value such as Sr., Jr., or III */
  suffix?: InputMaybe<Scalars['String']>;
  /** The customer's Tax/VAT number (for corporate customers) */
  taxvat?: InputMaybe<Scalars['String']>;
}
/** CustomizableAreaOption contains information about a text area that is defined as part of a customizable option. */
export interface CustomizableAreaOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text area. */
  value?: Maybe<CustomizableAreaValue>;
}
/** CustomizableAreaValue defines the price and sku of a product whose page contains a customized text area. */
export interface CustomizableAreaValue {
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableAreaValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableCheckbbixOption contains information about a set of checkbox values that are defined as part of a customizable option. */
export interface CustomizableCheckboxOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of checkbox values. */
  value?: Maybe<Array<Maybe<CustomizableCheckboxValue>>>;
}
/** CustomizableCheckboxValue defines the price and sku of a product whose page contains a customized set of checkbox values. */
export interface CustomizableCheckboxValue {
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the checkbox value is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableCheckboxValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableDateOption contains information about a date picker that is defined as part of a customizable option. */
export interface CustomizableDateOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a date field in a customizable option. */
  value?: Maybe<CustomizableDateValue>;
}
/** This enumeration customizable date type. */
export declare enum CustomizableDateTypeEnum {
  Date = 'DATE',
  DateTime = 'DATE_TIME',
  Time = 'TIME',
}
/** CustomizableDateValue defines the price and sku of a product whose page contains a customized date picker. */
export interface CustomizableDateValue {
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** DATE, DATE_TIME or TIME */
  type?: Maybe<CustomizableDateTypeEnum>;
  /** The unique ID for a `CustomizableDateValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableDropDownOption contains information about a drop down menu that is defined as part of a customizable option. */
export interface CustomizableDropDownOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a drop down menu. */
  value?: Maybe<Array<Maybe<CustomizableDropDownValue>>>;
}
/** CustomizableDropDownValue defines the price and sku of a product whose page contains a customized drop down menu. */
export interface CustomizableDropDownValue {
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableDropDownValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableFieldOption contains information about a text field that is defined as part of a customizable option. */
export interface CustomizableFieldOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a text field. */
  value?: Maybe<CustomizableFieldValue>;
}
/** CustomizableFieldValue defines the price and sku of a product whose page contains a customized text field. */
export interface CustomizableFieldValue {
  /** The maximum number of characters that can be entered for this customizable option. */
  max_characters?: Maybe<Scalars['Int']>;
  /** The price of the custom value. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFieldValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableFileOption contains information about a file picker that is defined as part of a customizable option. */
export interface CustomizableFileOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** The Stock Keeping Unit of the base product. */
  product_sku?: Maybe<Scalars['String']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An object that defines a file value. */
  value?: Maybe<CustomizableFileValue>;
}
/** CustomizableFileValue defines the price and sku of a product whose page contains a customized file picker. */
export interface CustomizableFileValue {
  /** The file extension to accept. */
  file_extension?: Maybe<Scalars['String']>;
  /** The maximum width of an image. */
  image_size_x?: Maybe<Scalars['Int']>;
  /** The maximum height of an image. */
  image_size_y?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableFileValue` object. */
  uid: Scalars['ID'];
}
/** CustomizableMultipleOption contains information about a multiselect that is defined as part of a customizable option. */
export interface CustomizableMultipleOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines the set of options for a multiselect. */
  value?: Maybe<Array<Maybe<CustomizableMultipleValue>>>;
}
/** CustomizableMultipleValue defines the price and sku of a product whose page contains a customized multiselect. */
export interface CustomizableMultipleValue {
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableMultipleValue` object. */
  uid: Scalars['ID'];
}
export interface CustomizableOptionInput {
  /** The customizable option id of the product */
  id?: InputMaybe<Scalars['Int']>;
  /** The string value of the option */
  value_string: Scalars['String'];
}
/** The CustomizableOptionInterface contains basic information about a customizable option. It can be implemented by several types of configurable options. */
export interface CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
}
/** CustomizableProductInterface contains information about customizable product options. */
export interface CustomizableProductInterface {
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
}
/** CustomizableRadioOption contains information about a set of radio buttons that are defined as part of a customizable option. */
export interface CustomizableRadioOption extends CustomizableOptionInterface {
  /**
   * Option ID.
   * @deprecated Use `uid` instead
   */
  option_id?: Maybe<Scalars['Int']>;
  /** Indicates whether the option is required. */
  required?: Maybe<Scalars['Boolean']>;
  /** The order in which the option is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableOptionInterface` object. */
  uid: Scalars['ID'];
  /** An array that defines a set of radio buttons. */
  value?: Maybe<Array<Maybe<CustomizableRadioValue>>>;
}
/** CustomizableRadioValue defines the price and sku of a product whose page contains a customized set of radio buttons. */
export interface CustomizableRadioValue {
  /** The ID assigned to the value. */
  option_type_id?: Maybe<Scalars['Int']>;
  /** The price assigned to this option. */
  price?: Maybe<Scalars['Float']>;
  /** FIXED, PERCENT, or DYNAMIC. */
  price_type?: Maybe<PriceTypeEnum>;
  /** The Stock Keeping Unit for this option. */
  sku?: Maybe<Scalars['String']>;
  /** The order in which the radio button is displayed. */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name for this option. */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `CustomizableRadioValue` object. */
  uid: Scalars['ID'];
}
export interface DeleteCompareListOutput {
  /** Indicates whether the compare list was successfully deleted */
  result: Scalars['Boolean'];
}
export interface DeletePaymentTokenOutput {
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  result: Scalars['Boolean'];
}
/** Defines an individual discount. A discount can be applied to the cart as a whole or to an item. */
export interface Discount {
  /** The amount of the discount */
  amount: Money;
  /** A description of the discount */
  label: Scalars['String'];
}
/** Downloadable Cart Item */
export interface DownloadableCartItem extends CartItemInterface {
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  /** An array containing information about the links for the added to cart downloadable product */
  links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** DownloadableProductSamples defines characteristics of a downloadable product */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
}
export interface DownloadableCreditMemoItem extends CreditMemoItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are refunded from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `CreditMemoItemInterface` object */
  id: Scalars['ID'];
  /** The order item the credit memo is applied to */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product, including selected options */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
}
export declare enum DownloadableFileTypeEnum {
  File = 'FILE',
  Url = 'URL',
}
export interface DownloadableInvoiceItem extends InvoiceItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are invoiced from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
}
/** DownloadableProductLinks defines characteristics of a downloadable product */
export interface DownloadableItemsLinks {
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableItemsLinks` object. */
  uid: Scalars['ID'];
}
export interface DownloadableOrderItem extends OrderItemInterface {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** A list of downloadable links that are ordered from the downloadable product */
  downloadable_links?: Maybe<Array<Maybe<DownloadableItemsLinks>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
}
/** DownloadableProduct defines a product that the shopper downloads */
export interface DownloadableProduct extends CustomizableProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  /** An array containing information about the links for this downloadable product */
  downloadable_product_links?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** An array containing information about samples of this downloadable product. */
  downloadable_product_samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** A value of 1 indicates that each link in the array must be purchased separately */
  links_purchased_separately?: Maybe<Scalars['Int']>;
  /** The heading above the list of downloadable products */
  links_title?: Maybe<Scalars['String']>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
}
/** DownloadableProduct defines a product that the shopper downloads */
export interface DownloadableProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
export interface DownloadableProductCartItemInput {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  data: CartItemInput;
  downloadable_product_links?: InputMaybe<Array<InputMaybe<DownloadableProductLinksInput>>>;
}
/** DownloadableProductLinks defines characteristics of a downloadable product */
export interface DownloadableProductLinks {
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated This information should not be exposed on frontend */
  is_shareable?: Maybe<Scalars['Boolean']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  link_type?: Maybe<DownloadableFileTypeEnum>;
  /** @deprecated This information should not be exposed on frontend */
  number_of_downloads?: Maybe<Scalars['Int']>;
  /** The price of the downloadable product */
  price?: Maybe<Scalars['Float']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the link */
  title?: Maybe<Scalars['String']>;
  /** The unique ID for a `DownloadableProductLinks` object. */
  uid: Scalars['ID'];
}
export interface DownloadableProductLinksInput {
  link_id: Scalars['Int'];
}
/** DownloadableProductSamples defines characteristics of a downloadable product */
export interface DownloadableProductSamples {
  /** @deprecated This information should not be exposed on frontend */
  id?: Maybe<Scalars['Int']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_file?: Maybe<Scalars['String']>;
  /** @deprecated `sample_url` serves to get the downloadable sample */
  sample_type?: Maybe<DownloadableFileTypeEnum>;
  /** URL to the downloadable sample */
  sample_url?: Maybe<Scalars['String']>;
  /** A number indicating the sort order */
  sort_order?: Maybe<Scalars['Int']>;
  /** The display name of the sample */
  title?: Maybe<Scalars['String']>;
}
/** A downloadable product wish list item */
export interface DownloadableWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** An array containing information about the selected links */
  links_v2?: Maybe<Array<Maybe<DownloadableProductLinks>>>;
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
  /** An array containing information about the selected samples */
  samples?: Maybe<Array<Maybe<DownloadableProductSamples>>>;
}
/** Defines a customer-entered option */
export interface EnteredOptionInput {
  /** The unique ID for a `CustomizableFieldOption`, `CustomizableFileOption`, `CustomizableAreaOption`, etc. of `CustomizableOptionInterface` objects */
  uid: Scalars['ID'];
  /** Text the customer entered */
  value: Scalars['String'];
}
/**
 *
 * is an output object containing the `id`, `relative_url`, and `type` attributes */
export interface EntityUrl {
  /** @deprecated The canonical_url field is deprecated, use relative_url instead. */
  canonical_url?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface`, `CategoryInterface`, `CmsPage`, etc. object associated with the specified url. This could be a product UID, category UID, or cms page UID. */
  entity_uid?: Maybe<Scalars['ID']>;
  /**
   * The ID assigned to the object associated with the specified url. This could be a product ID, category ID, or page ID.
   * @deprecated Use `entity_uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** 301 or 302 HTTP code for url permanent or temporary redirect or 0 for the 200 no redirect */
  redirectCode?: Maybe<Scalars['Int']>;
  /** The internal relative URL. If the specified  url is a redirect, the query returns the redirected URL, not the original. */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
}
export interface ExchangeRate {
  currency_to?: Maybe<Scalars['String']>;
  rate?: Maybe<Scalars['Float']>;
}
/** Defines a filter that matches the input exactly. */
export interface FilterEqualTypeInput {
  /** A string to filter on */
  eq?: InputMaybe<Scalars['String']>;
  /** An array of values to filter on */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}
/** Defines a filter that performs a fuzzy search. */
export interface FilterMatchTypeInput {
  /** One or more words to filter on */
  match?: InputMaybe<Scalars['String']>;
}
/** Defines a filter that matches a range of values, such as prices or dates. */
export interface FilterRangeTypeInput {
  /** The beginning of the range */
  from?: InputMaybe<Scalars['String']>;
  /** The end of the range */
  to?: InputMaybe<Scalars['String']>;
}
/** Defines a filter for an input string. */
export interface FilterStringTypeInput {
  /** Filters items that are exactly the same as the specified string. */
  eq?: InputMaybe<Scalars['String']>;
  /** Filters items that are exactly the same as entries specified in an array of strings. */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Defines a filter that performs a fuzzy search using the specified string. */
  match?: InputMaybe<Scalars['String']>;
}
/** FilterTypeInput specifies which action will be performed in a query  */
export interface FilterTypeInput {
  /** Equals */
  eq?: InputMaybe<Scalars['String']>;
  finset?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** From. Must be used with 'to' */
  from?: InputMaybe<Scalars['String']>;
  /** Greater than */
  gt?: InputMaybe<Scalars['String']>;
  /** Greater than or equal to */
  gteq?: InputMaybe<Scalars['String']>;
  /** In. The value can contain a set of comma-separated values */
  in?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Like. The specified value can contain % (percent signs) to allow matching of 0 or more characters */
  like?: InputMaybe<Scalars['String']>;
  /** Less than */
  lt?: InputMaybe<Scalars['String']>;
  /** Less than or equal to */
  lteq?: InputMaybe<Scalars['String']>;
  /** More than or equal to */
  moreq?: InputMaybe<Scalars['String']>;
  /** Not equal to */
  neq?: InputMaybe<Scalars['String']>;
  /** Not in. The value can contain a set of comma-separated values */
  nin?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  /** Not null */
  notnull?: InputMaybe<Scalars['String']>;
  /** Is null */
  null?: InputMaybe<Scalars['String']>;
  /** To. Must be used with 'from' */
  to?: InputMaybe<Scalars['String']>;
}
/** A single FPT that can be applied to a product price. */
export interface FixedProductTax {
  /** Amount of the FPT as a money object. */
  amount?: Maybe<Money>;
  /** The label assigned to the FPT to be displayed on the frontend. */
  label?: Maybe<Scalars['String']>;
}
/** This enumeration display settings for the fixed product tax */
export declare enum FixedProductTaxDisplaySettings {
  /** The displayed price does not include the FPT amount. The values of ProductPrice.fixed_product_taxes and the price including the FPT are displayed separately. This value corresponds to 'Excluding FPT, Including FPT description and final price' */
  ExcludeFptAndIncludeWithDetails = 'EXCLUDE_FPT_AND_INCLUDE_WITH_DETAILS',
  /** The displayed price does not include the FPT amount. The values from ProductPrice.fixed_product_taxes are not displayed. This value corresponds to 'Excluding FPT' */
  ExcludeFptWithoutDetails = 'EXCLUDE_FPT_WITHOUT_DETAILS',
  /** The FPT feature is not enabled. You can omit  ProductPrice.fixed_product_taxes from your query */
  FptDisabled = 'FPT_DISABLED',
  /** The displayed price includes the FPT amount without displaying the ProductPrice.fixed_product_taxes values. This value corresponds to 'Including FPT only' */
  IncludeFptWithoutDetails = 'INCLUDE_FPT_WITHOUT_DETAILS',
  /** The displayed price includes the FPT amount while displaying the values of ProductPrice.fixed_product_taxes separately. This value corresponds to 'Including FPT and FPT description' */
  IncludeFptWithDetails = 'INCLUDE_FPT_WITH_DETAILS',
}
export interface GenerateCustomerTokenAsAdminInput {
  /** The email address of the customer requesting remote shopping assistance */
  customer_email: Scalars['String'];
}
export interface GenerateCustomerTokenAsAdminOutput {
  /** The generated customer token */
  customer_token: Scalars['String'];
}
/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessage {
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
}
/** Contains the text of a gift message, its sender, and recipient */
export interface GiftMessageInput {
  /** Sender name */
  from: Scalars['String'];
  /** Gift message text */
  message: Scalars['String'];
  /** Recipient name */
  to: Scalars['String'];
}
/** A grouped product consists of simple standalone products that are presented as a group */
export interface GroupedProduct extends PhysicalProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** An array containing grouped product items */
  items?: Maybe<Array<Maybe<GroupedProductItem>>>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
}
/** A grouped product consists of simple standalone products that are presented as a group */
export interface GroupedProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
/** GroupedProductItem contains information about an individual grouped product item */
export interface GroupedProductItem {
  /** The relative position of this item compared to the other group items */
  position?: Maybe<Scalars['Int']>;
  /** The ProductInterface object, which contains details about this product option */
  product?: Maybe<ProductInterface>;
  /** The quantity of this grouped product item */
  qty?: Maybe<Scalars['Float']>;
}
/** A grouped product wish list item */
export interface GroupedProductWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payments Pro Hosted Solution payment method. */
export interface HostedProInput {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
}
/** Contains secure URL used for Payments Pro Hosted Solution payment method. */
export interface HostedProUrl {
  /** Secure Url generated by PayPal */
  secure_form_url?: Maybe<Scalars['String']>;
}
/** The required input to request the secure URL for Payments Pro Hosted Solution payment. */
export interface HostedProUrlInput {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
}
/** The object details of target path parameters */
export interface HttpQueryParameter {
  /** Parameter name */
  name?: Maybe<Scalars['String']>;
  /** Parameter value */
  value?: Maybe<Scalars['String']>;
}
export interface ImageSwatchData extends SwatchDataInterface {
  /** Thumbnail swatch image URL */
  thumbnail?: Maybe<Scalars['String']>;
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
}
/** Invoice details */
export interface Invoice {
  /** Comments on the invoice */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `Invoice` object */
  id: Scalars['ID'];
  /** Invoiced product details */
  items?: Maybe<Array<Maybe<InvoiceItemInterface>>>;
  /** Sequential invoice number */
  number: Scalars['String'];
  /** Invoice total amount details */
  total?: Maybe<InvoiceTotal>;
}
export interface InvoiceItem extends InvoiceItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
}
/** Invoice item details */
export interface InvoiceItemInterface {
  /** Contains information about the final discount amount for the base product, including discounts on options */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The unique ID for a `InvoiceItemInterface` object */
  id: Scalars['ID'];
  /** Contains details about an individual order item */
  order_item?: Maybe<OrderItemInterface>;
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price for the base product including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
}
/** Contains price details from an invoice */
export interface InvoiceTotal {
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the invoice */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the invoice */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the invoice, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The invoice tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the invoice */
  total_shipping: Money;
  /** The amount of tax applied to the invoice */
  total_tax: Money;
}
export interface IsConfigSettingEnabledOutput {
  /** Is config setting enabled */
  is_enabled?: Maybe<Scalars['Boolean']>;
}
export interface IsEmailAvailableOutput {
  /** Is email availabel value */
  is_email_available?: Maybe<Scalars['Boolean']>;
}
/** A list of options of the selected bundle product */
export interface ItemSelectedBundleOption {
  /**
   * The unique ID for a `ItemSelectedBundleOption` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The label of the option */
  label: Scalars['String'];
  /** The unique ID for a `ItemSelectedBundleOption` object */
  uid: Scalars['ID'];
  /** A list of products that represent the values of the parent option */
  values?: Maybe<Array<Maybe<ItemSelectedBundleOptionValue>>>;
}
/** A list of values for the selected bundle product */
export interface ItemSelectedBundleOptionValue {
  /**
   * The unique ID for a `ItemSelectedBundleOptionValue` object
   * @deprecated Use `uid` instead
   */
  id: Scalars['ID'];
  /** The price of the child bundle product */
  price: Money;
  /** The name of the child bundle product */
  product_name: Scalars['String'];
  /** The SKU of the child bundle product */
  product_sku: Scalars['String'];
  /** Indicates how many of this bundle product were ordered */
  quantity: Scalars['Float'];
  /** The unique ID for a `ItemSelectedBundleOptionValue` object */
  uid: Scalars['ID'];
}
/** The key-value type */
export interface KeyValue {
  /** The name part of the name/value pair */
  name?: Maybe<Scalars['String']>;
  /** The value part of the name/value pair */
  value?: Maybe<Scalars['String']>;
}
export interface KlarnaInput {
  /** The authorization token must be provided to set any Klarna Payments method */
  authorization_token: Scalars['String'];
}
export interface LayerFilter {
  /**
   * Array of filter items.
   * @deprecated Use Aggregation.options instead.
   */
  filter_items?: Maybe<Array<Maybe<LayerFilterItemInterface>>>;
  /**
   * Count of filter items in filter group.
   * @deprecated Use Aggregation.count instead.
   */
  filter_items_count?: Maybe<Scalars['Int']>;
  /**
   * Layered navigation filter name.
   * @deprecated Use Aggregation.label instead.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * Request variable name for filter query.
   * @deprecated Use Aggregation.attribute_code instead.
   */
  request_var?: Maybe<Scalars['String']>;
}
export interface LayerFilterItem extends LayerFilterItemInterface {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
}
export interface LayerFilterItemInterface {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
}
/** MediaGalleryEntry defines characteristics about images and videos associated with a specific product. */
export interface MediaGalleryEntry {
  /** Contains a ProductMediaGalleryEntriesContent object. */
  content?: Maybe<ProductMediaGalleryEntriesContent>;
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The path of the image on the server. */
  file?: Maybe<Scalars['String']>;
  /**
   * The identifier assigned to the object.
   * @deprecated Use `uid` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The alt text displayed on the UI when the user points to the image. */
  label?: Maybe<Scalars['String']>;
  /** image or video. */
  media_type?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** Array of image types. It can have the following values: image, small_image, thumbnail. */
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** The unique ID for a `MediaGalleryEntry` object. */
  uid: Scalars['ID'];
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
}
/** Contains basic information about a product image or video. */
export interface MediaGalleryInterface {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
}
/** A Money object defines a monetary value, including a numeric value and a currency code. */
export interface Money {
  /** A three-letter currency code, such as USD or EUR */
  currency?: Maybe<CurrencyEnum>;
  /** A number expressing a monetary value */
  value?: Maybe<Scalars['Float']>;
}
export interface Mutation {
  addBundleProductsToCart?: Maybe<AddBundleProductsToCartOutput>;
  addConfigurableProductsToCart?: Maybe<AddConfigurableProductsToCartOutput>;
  addDownloadableProductsToCart?: Maybe<AddDownloadableProductsToCartOutput>;
  /** Add any type of product to the cart */
  addProductsToCart?: Maybe<AddProductsToCartOutput>;
  /** Add products to the specified compare list */
  addProductsToCompareList?: Maybe<CompareList>;
  /** Adds one or more products to the specified wish list. This mutation supports all product types */
  addProductsToWishlist?: Maybe<AddProductsToWishlistOutput>;
  addSimpleProductsToCart?: Maybe<AddSimpleProductsToCartOutput>;
  addVirtualProductsToCart?: Maybe<AddVirtualProductsToCartOutput>;
  /** Add items in the specified wishlist to the customer's cart */
  addWishlistItemsToCart?: Maybe<AddWishlistItemsToCartOutput>;
  applyCouponToCart?: Maybe<ApplyCouponToCartOutput>;
  /** Assign the specified compare list to the logged in customer */
  assignCompareListToCustomer?: Maybe<AssignCompareListToCustomerOutput>;
  /** Changes the password for the logged-in customer */
  changeCustomerPassword?: Maybe<Customer>;
  /** Creates Client Token for Braintree Javascript SDK initialization. */
  createBraintreeClientToken: Scalars['String'];
  /** Creates a new compare list. The compare list is saved for logged in customers */
  createCompareList?: Maybe<CompareList>;
  /** Create customer account */
  createCustomer?: Maybe<CustomerOutput>;
  /** Create customer address */
  createCustomerAddress?: Maybe<CustomerAddress>;
  /** Create customer account */
  createCustomerV2?: Maybe<CustomerOutput>;
  /** Creates an empty shopping cart for a guest or logged in user */
  createEmptyCart?: Maybe<Scalars['String']>;
  /** Creates a Klarna Payments Session. */
  createKlarnaPaymentsSession?: Maybe<CreateKlarnaPaymentsSessionOutput>;
  /** Initiates a transaction and receives a token. Use this mutation for Payflow Pro and Payments Pro payment methods */
  createPayflowProToken?: Maybe<CreatePayflowProTokenOutput>;
  /** Initiates an Express Checkout transaction and receives a token. Use this mutation for Express Checkout and Payments Standard payment methods. */
  createPaypalExpressToken?: Maybe<PaypalExpressTokenOutput>;
  /** Creates a product review for the specified SKU */
  createProductReview: CreateProductReviewOutput;
  /** Delete the specified compare list */
  deleteCompareList?: Maybe<DeleteCompareListOutput>;
  /** Delete customer address */
  deleteCustomerAddress?: Maybe<Scalars['Boolean']>;
  /** Delete a customer payment token */
  deletePaymentToken?: Maybe<DeletePaymentTokenOutput>;
  /** Retrieve the customer token */
  generateCustomerToken?: Maybe<CustomerToken>;
  /** Request a customer token so that an administrator can perform remote shopping assistance */
  generateCustomerTokenAsAdmin?: Maybe<GenerateCustomerTokenAsAdminOutput>;
  /** Handles payment response and saves payment in Quote. Use this mutations for Payflow Pro and Payments Pro payment methods. */
  handlePayflowProResponse?: Maybe<PayflowProResponseOutput>;
  /** Merges the source cart into the destination cart */
  mergeCarts: Cart;
  placeOrder?: Maybe<PlaceOrderOutput>;
  removeCouponFromCart?: Maybe<RemoveCouponFromCartOutput>;
  removeItemFromCart?: Maybe<RemoveItemFromCartOutput>;
  /** Remove products from the specified compare list */
  removeProductsFromCompareList?: Maybe<CompareList>;
  /** Removes one or more products from the specified wish list */
  removeProductsFromWishlist?: Maybe<RemoveProductsFromWishlistOutput>;
  /** Adds all products from a customer's previous order to the cart. */
  reorderItems?: Maybe<ReorderItemsOutput>;
  /** Request an email with a reset password token for the registered customer identified by the specified email. */
  requestPasswordResetEmail?: Maybe<Scalars['Boolean']>;
  /** Reset a customer's password using the reset password token that the customer received in an email after requesting it using requestPasswordResetEmail. */
  resetPassword?: Maybe<Scalars['Boolean']>;
  /** Revoke the customer token */
  revokeCustomerToken?: Maybe<RevokeCustomerTokenOutput>;
  /** Recommends Product by Sending Single/Multiple Email */
  sendEmailToFriend?: Maybe<SendEmailToFriendOutput>;
  setBillingAddressOnCart?: Maybe<SetBillingAddressOnCartOutput>;
  setGuestEmailOnCart?: Maybe<SetGuestEmailOnCartOutput>;
  /** @deprecated Should use setPaymentMethodOnCart and placeOrder mutations in single request. */
  setPaymentMethodAndPlaceOrder?: Maybe<PlaceOrderOutput>;
  setPaymentMethodOnCart?: Maybe<SetPaymentMethodOnCartOutput>;
  setShippingAddressesOnCart?: Maybe<SetShippingAddressesOnCartOutput>;
  setShippingMethodsOnCart?: Maybe<SetShippingMethodsOnCartOutput>;
  /** Subscribes the specified email to a newsletter */
  subscribeEmailToNewsletter?: Maybe<SubscribeEmailToNewsletterOutput>;
  updateCartItems?: Maybe<UpdateCartItemsOutput>;
  /** Sends chat profile data to Engagement Cloud. */
  updateChatProfile?: Maybe<Scalars['Boolean']>;
  /** Deprecated. Use UpdateCustomerV2 instead. */
  updateCustomer?: Maybe<CustomerOutput>;
  /** Update customer address */
  updateCustomerAddress?: Maybe<CustomerAddress>;
  updateCustomerEmail?: Maybe<CustomerOutput>;
  /** Update the customer's personal information */
  updateCustomerV2?: Maybe<CustomerOutput>;
  /** Updates one or more products in the specified wish list */
  updateProductsInWishlist?: Maybe<UpdateProductsInWishlistOutput>;
  /** Updates the email address of a quote. */
  updateQuoteEmail?: Maybe<Scalars['Boolean']>;
}
export interface MutationAddBundleProductsToCartArgs {
  input?: InputMaybe<AddBundleProductsToCartInput>;
}
export interface MutationAddConfigurableProductsToCartArgs {
  input?: InputMaybe<AddConfigurableProductsToCartInput>;
}
export interface MutationAddDownloadableProductsToCartArgs {
  input?: InputMaybe<AddDownloadableProductsToCartInput>;
}
export interface MutationAddProductsToCartArgs {
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput>;
}
export interface MutationAddProductsToCompareListArgs {
  input?: InputMaybe<AddProductsToCompareListInput>;
}
export interface MutationAddProductsToWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemInput>;
}
export interface MutationAddSimpleProductsToCartArgs {
  input?: InputMaybe<AddSimpleProductsToCartInput>;
}
export interface MutationAddVirtualProductsToCartArgs {
  input?: InputMaybe<AddVirtualProductsToCartInput>;
}
export interface MutationAddWishlistItemsToCartArgs {
  wishlistId: Scalars['ID'];
  wishlistItemIds?: InputMaybe<Array<Scalars['ID']>>;
}
export interface MutationApplyCouponToCartArgs {
  input?: InputMaybe<ApplyCouponToCartInput>;
}
export interface MutationAssignCompareListToCustomerArgs {
  uid: Scalars['ID'];
}
export interface MutationChangeCustomerPasswordArgs {
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}
export interface MutationCreateCompareListArgs {
  input?: InputMaybe<CreateCompareListInput>;
}
export interface MutationCreateCustomerArgs {
  input: CustomerInput;
}
export interface MutationCreateCustomerAddressArgs {
  input: CustomerAddressInput;
}
export interface MutationCreateCustomerV2Args {
  input: CustomerCreateInput;
}
export interface MutationCreateEmptyCartArgs {
  input?: InputMaybe<CreateEmptyCartInput>;
}
export interface MutationCreateKlarnaPaymentsSessionArgs {
  input?: InputMaybe<CreateKlarnaPaymentsSessionInput>;
}
export interface MutationCreatePayflowProTokenArgs {
  input: PayflowProTokenInput;
}
export interface MutationCreatePaypalExpressTokenArgs {
  input: PaypalExpressTokenInput;
}
export interface MutationCreateProductReviewArgs {
  input: CreateProductReviewInput;
}
export interface MutationDeleteCompareListArgs {
  uid: Scalars['ID'];
}
export interface MutationDeleteCustomerAddressArgs {
  id: Scalars['Int'];
}
export interface MutationDeletePaymentTokenArgs {
  public_hash: Scalars['String'];
}
export interface MutationGenerateCustomerTokenArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}
export interface MutationGenerateCustomerTokenAsAdminArgs {
  input: GenerateCustomerTokenAsAdminInput;
}
export interface MutationHandlePayflowProResponseArgs {
  input: PayflowProResponseInput;
}
export interface MutationMergeCartsArgs {
  destination_cart_id?: InputMaybe<Scalars['String']>;
  source_cart_id: Scalars['String'];
}
export interface MutationPlaceOrderArgs {
  input?: InputMaybe<PlaceOrderInput>;
}
export interface MutationRemoveCouponFromCartArgs {
  input?: InputMaybe<RemoveCouponFromCartInput>;
}
export interface MutationRemoveItemFromCartArgs {
  input?: InputMaybe<RemoveItemFromCartInput>;
}
export interface MutationRemoveProductsFromCompareListArgs {
  input?: InputMaybe<RemoveProductsFromCompareListInput>;
}
export interface MutationRemoveProductsFromWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItemsIds: Array<Scalars['ID']>;
}
export interface MutationReorderItemsArgs {
  orderNumber: Scalars['String'];
}
export interface MutationRequestPasswordResetEmailArgs {
  email: Scalars['String'];
}
export interface MutationResetPasswordArgs {
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
}
export interface MutationSendEmailToFriendArgs {
  input?: InputMaybe<SendEmailToFriendInput>;
}
export interface MutationSetBillingAddressOnCartArgs {
  input?: InputMaybe<SetBillingAddressOnCartInput>;
}
export interface MutationSetGuestEmailOnCartArgs {
  input?: InputMaybe<SetGuestEmailOnCartInput>;
}
export interface MutationSetPaymentMethodAndPlaceOrderArgs {
  input?: InputMaybe<SetPaymentMethodAndPlaceOrderInput>;
}
export interface MutationSetPaymentMethodOnCartArgs {
  input?: InputMaybe<SetPaymentMethodOnCartInput>;
}
export interface MutationSetShippingAddressesOnCartArgs {
  input?: InputMaybe<SetShippingAddressesOnCartInput>;
}
export interface MutationSetShippingMethodsOnCartArgs {
  input?: InputMaybe<SetShippingMethodsOnCartInput>;
}
export interface MutationSubscribeEmailToNewsletterArgs {
  email: Scalars['String'];
}
export interface MutationUpdateCartItemsArgs {
  input?: InputMaybe<UpdateCartItemsInput>;
}
export interface MutationUpdateChatProfileArgs {
  email?: InputMaybe<Scalars['String']>;
  firstname?: InputMaybe<Scalars['String']>;
  lastname?: InputMaybe<Scalars['String']>;
  profileId: Scalars['String'];
}
export interface MutationUpdateCustomerArgs {
  input: CustomerInput;
}
export interface MutationUpdateCustomerAddressArgs {
  id: Scalars['Int'];
  input?: InputMaybe<CustomerAddressInput>;
}
export interface MutationUpdateCustomerEmailArgs {
  email: Scalars['String'];
  password: Scalars['String'];
}
export interface MutationUpdateCustomerV2Args {
  input: CustomerUpdateInput;
}
export interface MutationUpdateProductsInWishlistArgs {
  wishlistId: Scalars['ID'];
  wishlistItems: Array<WishlistItemUpdateInput>;
}
export interface MutationUpdateQuoteEmailArgs {
  cartId: Scalars['String'];
  email: Scalars['String'];
}
/** Contains details about the requested order */
export interface Order {
  /** An array containing the items purchased in this order */
  items?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** @deprecated The order_id field is deprecated, use order_number instead. */
  order_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `Order` object. */
  order_number: Scalars['String'];
  /** Contains the calculated total for this order */
  total?: Maybe<Scalars['String']>;
}
/** OrderAddress contains detailed information about an order's billing and shipping addresses */
export interface OrderAddress {
  /** The city or town */
  city: Scalars['String'];
  /** The customer's company */
  company?: Maybe<Scalars['String']>;
  /** The customer's country */
  country_code?: Maybe<CountryCodeEnum>;
  /** The fax number */
  fax?: Maybe<Scalars['String']>;
  /** The first name of the person associated with the shipping/billing address */
  firstname: Scalars['String'];
  /** The family name of the person associated with the shipping/billing address */
  lastname: Scalars['String'];
  /** The middle name of the person associated with the shipping/billing address */
  middlename?: Maybe<Scalars['String']>;
  /** The customer's order ZIP or postal code */
  postcode?: Maybe<Scalars['String']>;
  /** An honorific, such as Dr., Mr., or Mrs. */
  prefix?: Maybe<Scalars['String']>;
  /** The state or province name */
  region?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object of a pre-defined region */
  region_id?: Maybe<Scalars['ID']>;
  /** An array of strings that define the street number and name */
  street: Array<Maybe<Scalars['String']>>;
  /** A value such as Sr., Jr., or III */
  suffix?: Maybe<Scalars['String']>;
  /** The telephone number */
  telephone: Scalars['String'];
  /** The customer's Value-added tax (VAT) number (for corporate customers) */
  vat_id?: Maybe<Scalars['String']>;
}
export interface OrderItem extends OrderItemInterface {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
}
/** Order item details */
export interface OrderItemInterface {
  /** The final discount information for the product */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The entered option for the base product, such as a logo or image */
  entered_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The unique ID for a `OrderItemInterface` object */
  id: Scalars['ID'];
  /** The name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** The sale price of the base product, including selected options */
  product_sale_price: Money;
  product_sale_price_incl_tax: Money;
  /** The SKU of the base product */
  product_sku: Scalars['String'];
  /** The type of product, such as simple, configurable, etc. */
  product_type?: Maybe<Scalars['String']>;
  /** URL key of the base product */
  product_url_key?: Maybe<Scalars['String']>;
  /** The number of canceled items */
  quantity_canceled?: Maybe<Scalars['Float']>;
  /** The number of invoiced items */
  quantity_invoiced?: Maybe<Scalars['Float']>;
  /** The number of units ordered for this item */
  quantity_ordered?: Maybe<Scalars['Float']>;
  /** The number of refunded items */
  quantity_refunded?: Maybe<Scalars['Float']>;
  /** The number of returned items */
  quantity_returned?: Maybe<Scalars['Float']>;
  /** The number of shipped items */
  quantity_shipped?: Maybe<Scalars['Float']>;
  /** The selected options for the base product, such as color or size */
  selected_options?: Maybe<Array<Maybe<OrderItemOption>>>;
  /** The status of the order item */
  status?: Maybe<Scalars['String']>;
}
/** Represents order item options like selected or entered */
export interface OrderItemOption {
  /** The name of the option */
  label: Scalars['String'];
  /** The value of the option */
  value: Scalars['String'];
}
/** Contains details about the payment method used to pay for the order */
export interface OrderPaymentMethod {
  /** Additional data per payment method type */
  additional_data?: Maybe<Array<Maybe<KeyValue>>>;
  /** The label that describes the payment method */
  name: Scalars['String'];
  /** The payment method code that indicates how the order was paid for */
  type: Scalars['String'];
}
/** Order shipment details */
export interface OrderShipment {
  /** Comments added to the shipment */
  comments?: Maybe<Array<Maybe<SalesCommentItem>>>;
  /** The unique ID for a `OrderShipment` object */
  id: Scalars['ID'];
  /** Contains items included in the shipment */
  items?: Maybe<Array<Maybe<ShipmentItemInterface>>>;
  /** The sequential credit shipment number */
  number: Scalars['String'];
  /** Contains shipment tracking details */
  tracking?: Maybe<Array<Maybe<ShipmentTracking>>>;
}
/** Order JP Delivery Date Info */
export interface VwDeliveryDate {
  /** The Jp order delivery date */
  delivery_date?: Maybe<Scalars['String']>;
  /** The Jp order delivery time */
  delivery_time?: Maybe<Scalars['String']>;
}
/** Contains details about the sales total amounts used to calculate the final price */
export interface OrderTotal {
  /** The final base grand total amount in the base currency */
  base_grand_total: Money;
  /** The applied discounts to the order */
  discounts?: Maybe<Array<Maybe<Discount>>>;
  /** The final total amount, including shipping, discounts, and taxes */
  grand_total: Money;
  /** Contains details about the shipping and handling costs for the order */
  shipping_handling?: Maybe<ShippingHandling>;
  /** The subtotal of the order, excluding shipping, discounts, and taxes */
  subtotal: Money;
  /** The order tax details */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The shipping amount for the order */
  total_shipping: Money;
  /** The amount of tax applied to the order */
  total_tax: Money;
}
/** Required input for Payflow Express Checkout payments */
export interface PayflowExpressInput {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String'];
}
/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkInput {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
}
/** Mode for payment: TEST or LIVE. Applies to Payflow Link and Payments Advanced payment methods. */
export declare enum PayflowLinkMode {
  Live = 'LIVE',
  Test = 'TEST',
}
/** Contains information used to generate PayPal iframe for transaction. Applies to Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkToken {
  /** Mode for Payflow transaction */
  mode?: Maybe<PayflowLinkMode>;
  /** PayPal URL used for requesting Payflow form */
  paypal_url?: Maybe<Scalars['String']>;
  /** Secure token generated by PayPal */
  secure_token?: Maybe<Scalars['String']>;
  /** Secure token ID generated by PayPal */
  secure_token_id?: Maybe<Scalars['String']>;
}
/** Input required to fetch payment token information for Payflow Link and Payments Advanced payment methods. */
export interface PayflowLinkTokenInput {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
}
/** Required input for Payflow Pro and Payments Pro payment methods. */
export interface PayflowProInput {
  /** Required input for credit card related information */
  cc_details: CreditCardDetailsInput;
  /** States whether details about the customer's credit/debit card should be tokenized for later usage. Required only if Vault is enabled for PayPal Payflow Pro payment integration. */
  is_active_payment_token_enabler?: InputMaybe<Scalars['Boolean']>;
}
/** Input required to complete payment. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProResponseInput {
  cart_id: Scalars['String'];
  paypal_payload: Scalars['String'];
}
export interface PayflowProResponseOutput {
  cart: Cart;
}
/** Contains the secure information used to authorize transaction. Applies to Payflow Pro and Payments Pro payment methods. */
export interface PayflowProToken {
  response_message: Scalars['String'];
  result: Scalars['Int'];
  result_code: Scalars['Int'];
  secure_token: Scalars['String'];
  secure_token_id: Scalars['String'];
}
/** Input required to fetch payment token information for Payflow Pro and Payments Pro payment methods. */
export interface PayflowProTokenInput {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** A set of relative URLs that PayPal uses for callback. */
  urls: PayflowProUrlInput;
}
/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Payflow Pro and Payment Pro payment methods. */
export interface PayflowProUrlInput {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the transaction error page that PayPal will redirect to upon payment error. If the full URL to this page is https://www.example.com/paypal/action/error.html, the relative URL is paypal/action/error.html. */
  error_url: Scalars['String'];
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
}
export interface PaymentMethodInput {
  braintree?: InputMaybe<BraintreeInput>;
  braintree_cc_vault?: InputMaybe<BraintreeCcVaultInput>;
  /** Payment method code */
  code: Scalars['String'];
  /** Required input for PayPal Hosted pro payments */
  hosted_pro?: InputMaybe<HostedProInput>;
  klarna?: InputMaybe<KlarnaInput>;
  /** Required input for Payflow Express Checkout payments */
  payflow_express?: InputMaybe<PayflowExpressInput>;
  /** Required input for PayPal Payflow Link and Payments Advanced payments */
  payflow_link?: InputMaybe<PayflowLinkInput>;
  /** Required input type for PayPal Payflow Pro and Payment Pro payments */
  payflowpro?: InputMaybe<PayflowProInput>;
  /** Required input type for PayPal Payflow Pro vault payments */
  payflowpro_cc_vault?: InputMaybe<VaultTokenInput>;
  /** Required input for Express Checkout and Payments Standard payments */
  paypal_express?: InputMaybe<PaypalExpressInput>;
  /** Purchase order number */
  purchase_order_number?: InputMaybe<Scalars['String']>;
  np_gmo_payment_credit_card?: InputMaybe<GmoPaymentCcInput>;
  np_gmo_payment_credit_card_vault?: InputMaybe<GmoPaymentCcVaultInput>;
}
/** The stored payment method available to the customer */
export interface PaymentToken {
  /** Stored account details */
  details?: Maybe<Scalars['String']>;
  /** The payment method code associated with the token */
  payment_method_code: Scalars['String'];
  /** The public hash of the token */
  public_hash: Scalars['String'];
  type: PaymentTokenTypeEnum;
}
/** The list of available payment token types */
export declare enum PaymentTokenTypeEnum {
  Account = 'account',
  Card = 'card',
}
/** Required input for Express Checkout and Payments Standard payments */
export interface PaypalExpressInput {
  /** The unique ID of the PayPal user */
  payer_id: Scalars['String'];
  /** The token returned by the createPaypalExpressToken mutation */
  token: Scalars['String'];
}
/** Deprecated: use type `PaypalExpressTokenOutput` instead */
export interface PaypalExpressToken {
  /**
   * A set of URLs that allow the buyer to authorize payment and adjust checkout details
   * @deprecated Use field `paypal_urls` of type `PaypalExpressTokenOutput` instead
   */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /**
   * The token returned by PayPal
   * @deprecated Use field `token` of type `PaypalExpressTokenOutput` instead
   */
  token?: Maybe<Scalars['String']>;
}
/** Defines the attributes required to receive a payment token for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenInput {
  /** The unique ID that identifies the customer's cart */
  cart_id: Scalars['String'];
  /** Payment method code */
  code: Scalars['String'];
  /** Indicates whether the buyer selected the quick checkout button. The default value is false */
  express_button?: InputMaybe<Scalars['Boolean']>;
  /** A set of relative URLs that PayPal uses in response to various actions during the authorization process */
  urls: PaypalExpressUrlsInput;
  /** Indicates whether the buyer clicked the PayPal credit button. The default value is false */
  use_paypal_credit?: InputMaybe<Scalars['Boolean']>;
}
/** Contains the token returned by PayPal and a set of URLs that allow the buyer to authorize payment and adjust checkout details. Applies to Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressTokenOutput {
  /** A set of URLs that allow the buyer to authorize payment and adjust checkout details */
  paypal_urls?: Maybe<PaypalExpressUrlList>;
  /** The token returned by PayPal */
  token?: Maybe<Scalars['String']>;
}
/** A set of URLs that allow the buyer to authorize payment and adjust checkout details for Express Checkout and Payments Standard transactions. */
export interface PaypalExpressUrlList {
  /** The PayPal URL that allows the buyer to edit their checkout details */
  edit?: Maybe<Scalars['String']>;
  /** The URL to the PayPal login page */
  start?: Maybe<Scalars['String']>;
}
/** A set of relative URLs that PayPal will use in response to various actions during the authorization process. Magento prepends the base URL to this value to create a full URL. For example, if the full URL is https://www.example.com/path/to/page.html, the relative URL is path/to/page.html. Use this input for Express Checkout and Payments Standard payment methods. */
export interface PaypalExpressUrlsInput {
  /** The relative URL of the page that PayPal will redirect to when the buyer cancels the transaction in order to choose a different payment method. If the full URL to this page is https://www.example.com/paypal/action/cancel.html, the relative URL is paypal/action/cancel.html. */
  cancel_url: Scalars['String'];
  /** The relative URL of the page that PayPal will redirect to when the payment has been put on hold for additional review. This condition mostly applies to ACH transactions, and is not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success_pending.html, the relative URL is paypal/action/success_pending.html.  */
  pending_url?: InputMaybe<Scalars['String']>;
  /** The relative URL of the final confirmation page that PayPal will redirect to upon payment success. If the full URL to this page is https://www.example.com/paypal/action/return.html, the relative URL is paypal/action/return.html. */
  return_url: Scalars['String'];
  /** The relative URL of the order confirmation page that PayPal will redirect to when the payment is successful and additional confirmation is not needed. Not applicable to most PayPal solutions. If the full URL to this page is https://www.example.com/paypal/action/success.html, the relative URL is paypal/action/success.html. */
  success_url?: InputMaybe<Scalars['String']>;
}
/** PhysicalProductInterface contains attributes specific to tangible products. */
export interface PhysicalProductInterface {
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
}
/** Defines Pickup Location information. */
export interface PickupLocation {
  city?: Maybe<Scalars['String']>;
  contact_name?: Maybe<Scalars['String']>;
  country_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  fax?: Maybe<Scalars['String']>;
  latitude?: Maybe<Scalars['Float']>;
  longitude?: Maybe<Scalars['Float']>;
  name?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_id?: Maybe<Scalars['Int']>;
  street?: Maybe<Scalars['String']>;
}
/** PickupLocationFilterInput defines the list of attributes and filters for the search. */
export interface PickupLocationFilterInput {
  /** Filter by city. */
  city?: InputMaybe<FilterTypeInput>;
  /** Filter by country. */
  country_id?: InputMaybe<FilterTypeInput>;
  /** Filter by pickup location name. */
  name?: InputMaybe<FilterTypeInput>;
  /** Filter by pickup location code. */
  pickup_location_code?: InputMaybe<FilterTypeInput>;
  /** Filter by postcode. */
  postcode?: InputMaybe<FilterTypeInput>;
  /** Filter by region. */
  region?: InputMaybe<FilterTypeInput>;
  /** Filter by region id. */
  region_id?: InputMaybe<FilterTypeInput>;
  /** Filter by street. */
  street?: InputMaybe<FilterTypeInput>;
}
/** PickupLocationSortInput specifies attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface PickupLocationSortInput {
  /** City where pickup location is placed. */
  city?: InputMaybe<SortEnum>;
  /** Name of the contact person. */
  contact_name?: InputMaybe<SortEnum>;
  /** Id of the country in two letters. */
  country_id?: InputMaybe<SortEnum>;
  /** Description of the pickup location. */
  description?: InputMaybe<SortEnum>;
  /** Distance to the address, requested by distance filter. Applicable only with distance filter. If distance sort order is present, all other sort orders will be ignored. */
  distance?: InputMaybe<SortEnum>;
  /** Contact email of the pickup location. */
  email?: InputMaybe<SortEnum>;
  /** Contact fax of the pickup location. */
  fax?: InputMaybe<SortEnum>;
  /** Geographic latitude where pickup location is placed. */
  latitude?: InputMaybe<SortEnum>;
  /** Geographic longitude where pickup location is placed. */
  longitude?: InputMaybe<SortEnum>;
  /** The pickup location name. Customer use this to identify the pickup location. */
  name?: InputMaybe<SortEnum>;
  /** Contact phone number of the pickup location. */
  phone?: InputMaybe<SortEnum>;
  /** A code assigned to pickup location to identify the source. */
  pickup_location_code?: InputMaybe<SortEnum>;
  /** Postcode where pickup location is placed. */
  postcode?: InputMaybe<SortEnum>;
  /** Name of the region. */
  region?: InputMaybe<SortEnum>;
  /** Id of the region. */
  region_id?: InputMaybe<SortEnum>;
  /** Street where pickup location is placed. */
  street?: InputMaybe<SortEnum>;
}
/** Top level object returned in a pickup locations search. */
export interface PickupLocations {
  /** An array of pickup locations that match the specific search request. */
  items?: Maybe<Array<Maybe<PickupLocation>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** The number of products returned. */
  total_count?: Maybe<Scalars['Int']>;
}
export interface PlaceOrderInput {
  cart_id: Scalars['String'];
}
export interface PlaceOrderOutput {
  order: Order;
}
/** Price is deprecated, replaced by ProductPrice. The Price object defines the price of a product as well as any tax-related adjustments. */
export interface Price {
  /**
   * An array that provides information about tax, weee, or weee_tax adjustments.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  adjustments?: Maybe<Array<Maybe<PriceAdjustment>>>;
  /**
   * The price of a product plus a three-letter currency code.
   * @deprecated Price is deprecated, use ProductPrice.
   */
  amount?: Maybe<Money>;
}
/** PriceAdjustment is deprecated. Taxes will be included or excluded in the price. The PricedAdjustment object defines the amount of money to apply as an adjustment, the type of adjustment to apply, and whether the item is included or excluded from the adjustment. */
export interface PriceAdjustment {
  /** The amount of the price adjustment and its currency code. */
  amount?: Maybe<Money>;
  /**
   * Indicates whether the adjustment involves tax, weee, or weee_tax.
   * @deprecated PriceAdjustment is deprecated.
   */
  code?: Maybe<PriceAdjustmentCodesEnum>;
  /**
   * Indicates whether the entity described by the code attribute is included or excluded from the adjustment.
   * @deprecated PriceAdjustment is deprecated.
   */
  description?: Maybe<PriceAdjustmentDescriptionEnum>;
}
/** PriceAdjustment.code is deprecated. This enumeration contains values defined in modules other than the Catalog module. */
export declare enum PriceAdjustmentCodesEnum {
  Tax = 'TAX',
  Weee = 'WEEE',
  WeeeTax = 'WEEE_TAX',
}
/** PriceAdjustmentDescriptionEnum is deprecated. This enumeration states whether a price adjustment is included or excluded. */
export declare enum PriceAdjustmentDescriptionEnum {
  Excluded = 'EXCLUDED',
  Included = 'INCLUDED',
}
/** Price range for a product. If the product has a single price, the minimum and maximum price will be the same. */
export interface PriceRange {
  /** The highest possible price for the product. */
  maximum_price?: Maybe<ProductPrice>;
  /** The lowest possible price for the product. */
  minimum_price: ProductPrice;
}
/** This enumeration the price type. */
export declare enum PriceTypeEnum {
  Dynamic = 'DYNAMIC',
  Fixed = 'FIXED',
  Percent = 'PERCENT',
}
/** This enumeration defines whether a bundle product's price is displayed as the lowest possible value or as a range. */
export declare enum PriceViewEnum {
  AsLowAs = 'AS_LOW_AS',
  PriceRange = 'PRICE_RANGE',
}
export interface ProductAttribute {
  /** The unique identifier for a product attribute code. */
  code: Scalars['String'];
  /** The display value of the attribute */
  value: Scalars['String'];
}
/** ProductAttributeFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductAttributeFilterInput {
  /** Attribute label: Activity */
  activity?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Category Gear */
  category_gear?: InputMaybe<FilterEqualTypeInput>;
  /** Deprecated: use `category_uid` to filter product by category id. */
  category_id?: InputMaybe<FilterEqualTypeInput>;
  /** Filter product by the unique ID for a `CategoryInterface` object. */
  category_uid?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Climate */
  climate?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Collar */
  collar?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Color */
  color?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Description */
  description?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Eco Collection */
  eco_collection?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Erin Recommends */
  erin_recommends?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Features */
  features_bags?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Format */
  format?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Gender */
  gender?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Material */
  material?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Product Name */
  name?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: New */
  new?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Pattern */
  pattern?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Performance Fabric */
  performance_fabric?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Price */
  price?: InputMaybe<FilterRangeTypeInput>;
  /** Attribute label: Sale */
  sale?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Short Description */
  short_description?: InputMaybe<FilterMatchTypeInput>;
  /** Attribute label: Size */
  size?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: SKU */
  sku?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Sleeve */
  sleeve?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Strap/Handle */
  strap_bags?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Style Bags */
  style_bags?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Style Bottom */
  style_bottom?: InputMaybe<FilterEqualTypeInput>;
  /** Attribute label: Style General */
  style_general?: InputMaybe<FilterEqualTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterEqualTypeInput>;
  /** The url page that identifies the product */
  url_path?: InputMaybe<FilterEqualTypeInput>;
}
/** ProductAttributeSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. It's possible to sort products using searchable attributes with enabled 'Use in Filter Options' option */
export interface ProductAttributeSortInput {
  /** Attribute label: Product Name */
  name?: InputMaybe<SortEnum>;
  /** Sort by the position assigned to each product. */
  position?: InputMaybe<SortEnum>;
  /** Attribute label: Price */
  price?: InputMaybe<SortEnum>;
  /** Sort by the search relevance score (default). */
  relevance?: InputMaybe<SortEnum>;
  nosto_personalized?: InputMaybe<SortEnum>;
}
/** A discount applied to a product price. */
export interface ProductDiscount {
  /** The actual value of the discount. */
  amount_off?: Maybe<Scalars['Float']>;
  /** The discount expressed a percentage. */
  percent_off?: Maybe<Scalars['Float']>;
}
/** ProductFilterInput is deprecated, use @ProductAttributeFilterInput instead. ProductFilterInput defines the filters to be used in the search. A filter contains at least one attribute, a comparison operator, and the value that is being searched for. */
export interface ProductFilterInput {
  /** Category ID the product belongs to. */
  category_id?: InputMaybe<FilterTypeInput>;
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<FilterTypeInput>;
  /** Timestamp indicating when the product was created. */
  created_at?: InputMaybe<FilterTypeInput>;
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<FilterTypeInput>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<FilterTypeInput>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<FilterTypeInput>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<FilterTypeInput>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<FilterTypeInput>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product image. */
  image_label?: InputMaybe<FilterTypeInput>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<FilterTypeInput>;
  /** The numeric maximal price of the product. Do not include the currency code. */
  max_price?: InputMaybe<FilterTypeInput>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<FilterTypeInput>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<FilterTypeInput>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<FilterTypeInput>;
  /** The numeric minimal price of the product. Do not include the currency code. */
  min_price?: InputMaybe<FilterTypeInput>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<FilterTypeInput>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<FilterTypeInput>;
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<FilterTypeInput>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<FilterTypeInput>;
  /** The keyword required to perform a logical OR comparison. */
  or?: InputMaybe<ProductFilterInput>;
  /** The price of an item. */
  price?: InputMaybe<FilterTypeInput>;
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<FilterTypeInput>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<FilterTypeInput>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<FilterTypeInput>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<FilterTypeInput>;
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<FilterTypeInput>;
  /** The discounted price of the product. Do not include the currency code. */
  special_price?: InputMaybe<FilterTypeInput>;
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<FilterTypeInput>;
  /** The file name of a swatch image */
  swatch_image?: InputMaybe<FilterTypeInput>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<FilterTypeInput>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<FilterTypeInput>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<FilterTypeInput>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<FilterTypeInput>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<FilterTypeInput>;
  url_path?: InputMaybe<FilterTypeInput>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<FilterTypeInput>;
}
/** Product image information. Contains the image URL and label. */
export interface ProductImage extends MediaGalleryInterface {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
}
/** Product Information used for Pickup Locations search. */
export interface ProductInfoInput {
  /** Product SKU. */
  sku: Scalars['String'];
}
/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export interface ProductInterface {
  activity?: Maybe<Scalars['String']>;
  __typename?: Maybe<Scalars['String']>;
  /** Whether the Product is enabled */
  enabled?: Maybe<Scalars['Boolean']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  position?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Reinsurance Product Attributes */
  reinsurance?: Maybe<Array<Maybe<{label: string, value: string}>>>
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A plp description of the product. Its use depends on the theme. */
  plp_description?: Maybe<Scalars['String']>;
  /** A short description of the product. Its use depends on the theme. */
  pdp_short_description?: Maybe<ComplexTextValue>;
  /** A short description of the product. Its use depends on the theme. */
  pdp_long_description?: Maybe<ComplexTextValue>;
  size?: Maybe<Scalars['Int']>;
  replenishable?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /** A variant name of the product. */
  variant_name?: Maybe<Array<Maybe<{label: string, value: string}>>>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  number_of_sets?: Maybe<Scalars['String']>;
  price_type?: Maybe<Scalars['String']>;
  custom_set_minimal_price?: InputMaybe<Scalars['Int']>;
  custom_set_minimal_price_excluded_tax?: InputMaybe<Scalars['Int']>;
  engraving?: Maybe<Array<Maybe<Engraving>>>;
}
/** The ProductInterface contains attributes that are common to all types of products. Note that descriptions may not be available for custom and EAV attributes. */
export interface ProductInterfaceReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
/** ProductLinks is an implementation of ProductLinksInterface. */
export interface ProductLinks extends ProductLinksInterface {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
}
/** ProductLinks contains information about linked products, including the link type and product type of each item. */
export interface ProductLinksInterface {
  /** One of related, associated, upsell, or crosssell. */
  link_type?: Maybe<Scalars['String']>;
  /** The SKU of the linked product. */
  linked_product_sku?: Maybe<Scalars['String']>;
  /** The type of linked product (simple, virtual, bundle, downloadable, grouped, configurable). */
  linked_product_type?: Maybe<Scalars['String']>;
  /** The position within the list of product links. */
  position?: Maybe<Scalars['Int']>;
  /** The identifier of the linked product. */
  sku?: Maybe<Scalars['String']>;
}
/** ProductMediaGalleryEntriesContent contains an image in base64 format and basic information about the image. */
export interface ProductMediaGalleryEntriesContent {
  /** The image in base64 format. */
  base64_encoded_data?: Maybe<Scalars['String']>;
  /** The file name of the image. */
  name?: Maybe<Scalars['String']>;
  /** The MIME type of the file, such as image/png. */
  type?: Maybe<Scalars['String']>;
}
/** ProductMediaGalleryEntriesVideoContent contains a link to a video file and basic information about the video. */
export interface ProductMediaGalleryEntriesVideoContent {
  /** Must be external-video. */
  media_type?: Maybe<Scalars['String']>;
  /** A description of the video. */
  video_description?: Maybe<Scalars['String']>;
  /** Optional data about the video. */
  video_metadata?: Maybe<Scalars['String']>;
  /** Describes the video source. */
  video_provider?: Maybe<Scalars['String']>;
  /** The title of the video. */
  video_title?: Maybe<Scalars['String']>;
  /** The URL to the video. */
  video_url?: Maybe<Scalars['String']>;
}
/** Represents a product price. */
export interface ProductPrice {
  /** The price discount. Represents the difference between the regular and final price. */
  discount?: Maybe<ProductDiscount>;
  /** The final price of the product after discounts applied. */
  final_price: Money;
  /** The multiple FPTs that can be applied to a product price. */
  fixed_product_taxes?: Maybe<Array<Maybe<FixedProductTax>>>;
  /** The regular price of the product. */
  regular_price: Money;
}
/** ProductPrices is deprecated, replaced by PriceRange. The ProductPrices object contains the regular price of an item, as well as its minimum and maximum prices. Only composite products, which include bundle, configurable, and grouped products, can contain a minimum and maximum price. */
export interface ProductPrices {
  /**
   * The highest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the to value.
   * @deprecated Use PriceRange.maximum_price.
   */
  maximalPrice?: Maybe<Price>;
  /**
   * The lowest possible final price for all the options defined within a composite product. If you are specifying a price range, this would be the from value.
   * @deprecated Use PriceRange.minimum_price.
   */
  minimalPrice?: Maybe<Price>;
  /**
   * The base price of a product.
   * @deprecated Use regular_price from PriceRange.minimum_price or PriceRange.maximum_price.
   */
  regularPrice?: Maybe<Price>;
}
/** Details of a product review */
export interface ProductReview {
  /** The average rating for product review. */
  average_rating: Scalars['Float'];
  /** Date indicating when the review was created. */
  created_at: Scalars['String'];
  /** The customer's nickname. Defaults to the customer name, if logged in */
  nickname: Scalars['String'];
  /** Contains details about the reviewed product */
  product: ProductInterface;
  /** An array of ratings by rating category, such as quality, price, and value */
  ratings_breakdown: Array<Maybe<ProductReviewRating>>;
  /** The summary (title) of the review */
  summary: Scalars['String'];
  /** The review text. */
  text: Scalars['String'];
}
export interface ProductReviewRating {
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String'];
  /** The rating value given by customer. By default, possible values range from 1 to 5. */
  value: Scalars['String'];
}
export interface ProductReviewRatingInput {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** An encoded rating value id. */
  value_id: Scalars['String'];
}
export interface ProductReviewRatingMetadata {
  /** An encoded rating ID. */
  id: Scalars['String'];
  /** The label assigned to an aspect of a product that is being rated, such as quality or price */
  name: Scalars['String'];
  /** List of product review ratings sorted by position. */
  values: Array<Maybe<ProductReviewRatingValueMetadata>>;
}
export interface ProductReviewRatingValueMetadata {
  /** A ratings scale, such as the number of stars awarded */
  value: Scalars['String'];
  /** An encoded rating value id. */
  value_id: Scalars['String'];
}
export interface ProductReviewRatingsMetadata {
  /** List of product reviews sorted by position */
  items: Array<Maybe<ProductReviewRatingMetadata>>;
}
export interface ProductReviews {
  /** An array of product reviews. */
  items: Array<Maybe<ProductReview>>;
  /** Metadata for pagination rendering. */
  page_info: SearchResultPageInfo;
}
/** ProductSortInput is deprecated, use @ProductAttributeSortInput instead. ProductSortInput specifies the attribute to use for sorting search results and indicates whether the results are sorted in ascending or descending order. */
export interface ProductSortInput {
  /** The product's country of origin. */
  country_of_manufacture?: InputMaybe<SortEnum>;
  /** Timestamp indicating when the product was created. */
  created_at?: InputMaybe<SortEnum>;
  /** The name of a custom layout. */
  custom_layout?: InputMaybe<SortEnum>;
  /** XML code that is applied as a layout update to the product page. */
  custom_layout_update?: InputMaybe<SortEnum>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: InputMaybe<SortEnum>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: InputMaybe<SortEnum>;
  /** Indicates whether additional attributes have been created for the product. */
  has_options?: InputMaybe<SortEnum>;
  /** The relative path to the main image on the product page. */
  image?: InputMaybe<SortEnum>;
  /** The label assigned to a product image. */
  image_label?: InputMaybe<SortEnum>;
  /** A number representing the product's manufacturer. */
  manufacturer?: InputMaybe<SortEnum>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: InputMaybe<SortEnum>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: InputMaybe<SortEnum>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: InputMaybe<SortEnum>;
  /** The product name. Customers use this name to identify the product. */
  name?: InputMaybe<SortEnum>;
  /** The beginning date for new product listings, and determines if the product is featured as a new product. */
  news_from_date?: InputMaybe<SortEnum>;
  /** The end date for new product listings. */
  news_to_date?: InputMaybe<SortEnum>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: InputMaybe<SortEnum>;
  /** The price of the item. */
  price?: InputMaybe<SortEnum>;
  /** Indicates whether the product has required options. */
  required_options?: InputMaybe<SortEnum>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: InputMaybe<SortEnum>;
  /** A plp description of the product. Its use depends on the theme. */
  plp_description?: InputMaybe<SortEnum>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: InputMaybe<SortEnum>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: InputMaybe<SortEnum>;
  /** The label assigned to a product's small image. */
  small_image_label?: InputMaybe<SortEnum>;
  /** The beginning date that a product has a special price. */
  special_from_date?: InputMaybe<SortEnum>;
  /** The discounted price of the product. */
  special_price?: InputMaybe<SortEnum>;
  /** The end date that a product has a special price. */
  special_to_date?: InputMaybe<SortEnum>;
  /** The file name of a swatch image */
  swatch_image?: InputMaybe<SortEnum>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: InputMaybe<SortEnum>;
  /** The label assigned to a product's thumbnail image. */
  thumbnail_label?: InputMaybe<SortEnum>;
  /** The price when tier pricing is in effect and the items purchased threshold has been reached. */
  tier_price?: InputMaybe<SortEnum>;
  /** Timestamp indicating when the product was updated. */
  updated_at?: InputMaybe<SortEnum>;
  /** The part of the URL that identifies the product */
  url_key?: InputMaybe<SortEnum>;
  url_path?: InputMaybe<SortEnum>;
  /** The weight of the item, in units defined by the store. */
  weight?: InputMaybe<SortEnum>;
}
/** This enumeration states whether a product stock status is in stock or out of stock */
export enum ProductStockStatus {
  InStock = 'IN_STOCK',
  OutOfStock = 'OUT_OF_STOCK',
}
/** ProductTierPrices is deprecated and has been replaced by TierPrice. The ProductTierPrices object defines a tier price, which is a quantity discount offered to a specific customer group. */
export interface ProductTierPrices {
  /**
   * The ID of the customer group.
   * @deprecated customer_group_id is not relevant for storefront.
   */
  customer_group_id?: Maybe<Scalars['String']>;
  /**
   * The percentage discount of the item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.discount.
   */
  percentage_value?: Maybe<Scalars['Float']>;
  /**
   * The number of items that must be purchased to qualify for tier pricing.
   * @deprecated ProductTierPrices is deprecated, use TierPrice.quantity.
   */
  qty?: Maybe<Scalars['Float']>;
  /**
   * The price of the fixed price item.
   * @deprecated ProductTierPrices is deprecated. Use TierPrice.final_price
   */
  value?: Maybe<Scalars['Float']>;
  /**
   * The ID assigned to the website.
   * @deprecated website_id is not relevant for storefront.
   */
  website_id?: Maybe<Scalars['Float']>;
}
/** Contains information about a product video. */
export interface ProductVideo extends MediaGalleryInterface {
  /** Whether the image is hidden from view. */
  disabled?: Maybe<Scalars['Boolean']>;
  /** The label of the product image or video. */
  label?: Maybe<Scalars['String']>;
  /** The media item's position after it has been sorted. */
  position?: Maybe<Scalars['Int']>;
  /** The URL of the product image or video. */
  url?: Maybe<Scalars['String']>;
  /** Contains a ProductMediaGalleryEntriesVideoContent object. */
  video_content?: Maybe<ProductMediaGalleryEntriesVideoContent>;
}
/** The Products object is the top-level object returned in a product search. */
export interface Products {
  /** Layered navigation aggregations. */
  aggregations?: Maybe<Array<Maybe<Aggregation>>>;
  /**
   * Layered navigation filters array.
   * @deprecated Use aggregations instead
   */
  filters?: Maybe<Array<Maybe<LayerFilter>>>;
  /** An array of products that match the specified search criteria. */
  items?: Maybe<Array<Maybe<ProductInterface>>>;
  /** An object that includes the page_info and currentPage values specified in the query. */
  page_info?: Maybe<SearchResultPageInfo>;
  /** An object that includes the default sort field and all available sort fields. */
  sort_fields?: Maybe<SortFields>;
  /** The number of products that are marked as visible. By default, in complex products, parent products are visible, but their child products are not. */
  total_count?: Maybe<Scalars['Int']>;
}
export interface Query {
  /** Get a list of available store views and their config information. */
  availableStores?: Maybe<Array<Maybe<StoreConfig>>>;
  /** Returns information about shopping cart */
  cart?: Maybe<Cart>;
  categories?: Maybe<CategoryResult>;
  /**
   * The category query searches for categories that match the criteria specified in the search and filter attributes.
   * @deprecated Use 'categoryList' query instead of 'category' query
   */
  category?: Maybe<CategoryTree>;
  /** Returns an array of categories based on the specified filters. */
  categoryList?: Maybe<Array<Maybe<CategoryTree>>>;
  /** Retrieves an array of configuration data for the chat widget. */
  chatData?: Maybe<ChatData>;
  /** The Checkout Agreements information */
  checkoutAgreements?: Maybe<Array<Maybe<CheckoutAgreement>>>;
  /** The CMS block query returns information about CMS blocks */
  cmsBlocks?: Maybe<CmsBlocks>;
  /** The CMS page query returns information about a CMS page */
  cmsPage?: Maybe<CmsPage>;
  /** Return products that have been added to the specified compare list */
  compareList?: Maybe<CompareList>;
  /** The countries query provides information for all countries. */
  countries?: Maybe<Array<Maybe<Country>>>;
  /** The countries query provides information for a single country. */
  country?: Maybe<Country>;
  /** The currency query returns information about store currency. */
  currency?: Maybe<Currency>;
  /** The customAttributeMetadata query returns the attribute type, given an attribute code and entity type */
  customAttributeMetadata?: Maybe<CustomAttributeMetadata>;
  /** The customer query returns information about a customer account */
  customer?: Maybe<Customer>;
  /** Returns information about the customer shopping cart */
  customerCart: Cart;
  /** The query returns the contents of a customer's downloadable products */
  customerDownloadableProducts?: Maybe<CustomerDownloadableProducts>;
  /** @deprecated Use orders from customer instead */
  customerOrders?: Maybe<CustomerOrders>;
  /** Return a list of customer payment tokens */
  customerPaymentTokens?: Maybe<CustomerPaymentTokens>;
  /** Returns status of Easy Email Capture for Checkout. */
  emailCaptureCheckout?: Maybe<IsConfigSettingEnabledOutput>;
  /** Returns status of Easy Email Capture for Newsletter. */
  emailCaptureNewsletter?: Maybe<IsConfigSettingEnabledOutput>;
  /** Retrieve secure PayPal url for Payments Pro Hosted Solution transaction. */
  getHostedProUrl?: Maybe<HostedProUrl>;
  /** Retrieve payment credentials for transaction. Use this query for Payflow Link and Payments Advanced payment methods. */
  getPayflowLinkToken?: Maybe<PayflowLinkToken>;
  isEmailAvailable?: Maybe<IsEmailAvailableOutput>;
  /** Retrieves information about an order by order id. */
  orderData?: Maybe<Order>;
  /** The pickup locations query searches for locations that match the search request requirements. */
  pickupLocations?: Maybe<PickupLocations>;
  /** Retrieves metadata required by clients to render the Reviews section. */
  productReviewRatingsMetadata: ProductReviewRatingsMetadata;
  /** The products query searches for products that match the criteria specified in the search and filter attributes. */
  products?: Maybe<Products>;
  /** Return the full details for a specified product, category, or CMS page given the specified url_key, appended by the url_suffix, if one exists */
  route?: Maybe<RoutableInterface>;
  /** The store config query */
  storeConfig?: Maybe<StoreConfig>;
  /** Retrieves an array of configuration data for different types of tracking. */
  trackingData?: Maybe<TrackingData>;
  /**
   * The urlResolver query returns the relative URL for a specified product, category or CMS page, using as input a url_key appended by the url_suffix, if one exists
   * @deprecated Use the 'route' query instead
   */
  urlResolver?: Maybe<EntityUrl>;
  /**
   * The wishlist query returns the contents of a customer's wish list
   * @deprecated Moved under `Customer` `wishlist`
   */
  wishlist?: Maybe<WishlistOutput>;
}
export interface QueryAvailableStoresArgs {
  useCurrentGroup?: InputMaybe<Scalars['Boolean']>;
}
export interface QueryCartArgs {
  cart_id: Scalars['String'];
}
export interface QueryCategoriesArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<CategoryFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
export interface QueryCategoryArgs {
  id?: InputMaybe<Scalars['Int']>;
}
export interface QueryCategoryListArgs {
  filters?: InputMaybe<CategoryFilterInput>;
}
export interface QueryCmsBlocksArgs {
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
}
export interface QueryCmsPageArgs {
  id?: InputMaybe<Scalars['Int']>;
  identifier?: InputMaybe<Scalars['String']>;
}
export interface QueryCompareListArgs {
  uid: Scalars['ID'];
}
export interface QueryCountryArgs {
  id?: InputMaybe<Scalars['String']>;
}
export interface QueryCustomAttributeMetadataArgs {
  attributes: Array<AttributeInput>;
}
export interface QueryGetHostedProUrlArgs {
  input: HostedProUrlInput;
}
export interface QueryGetPayflowLinkTokenArgs {
  input: PayflowLinkTokenInput;
}
export interface QueryIsEmailAvailableArgs {
  email: Scalars['String'];
}
export interface QueryOrderDataArgs {
  orderId: Scalars['String'];
}
export interface QueryPickupLocationsArgs {
  area?: InputMaybe<AreaInput>;
  currentPage?: InputMaybe<Scalars['Int']>;
  filters?: InputMaybe<PickupLocationFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  productsInfo?: InputMaybe<Array<InputMaybe<ProductInfoInput>>>;
  sort?: InputMaybe<PickupLocationSortInput>;
}
export interface QueryProductsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  search?: InputMaybe<Scalars['String']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}
export interface QueryRouteArgs {
  url: Scalars['String'];
}
export interface QueryUrlResolverArgs {
  url: Scalars['String'];
}
export interface Region {
  code?: Maybe<Scalars['String']>;
  /** The unique ID for a `Region` object. */
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}
export interface RemoveCouponFromCartInput {
  cart_id: Scalars['String'];
}
export interface RemoveCouponFromCartOutput {
  cart?: Maybe<Cart>;
}
export interface RemoveItemFromCartInput {
  cart_id: Scalars['String'];
  /** Deprecated. Use `cart_item_uid` instead. */
  cart_item_id?: InputMaybe<Scalars['Int']>;
  /** Required field. The unique ID for a `CartItemInterface` object */
  cart_item_uid?: InputMaybe<Scalars['ID']>;
}
export interface RemoveItemFromCartOutput {
  cart: Cart;
}
export interface RemoveProductsFromCompareListInput {
  /** An array of product IDs to remove from the compare list */
  products: Array<InputMaybe<Scalars['ID']>>;
  /** The unique identifier of the compare list to modify */
  uid: Scalars['ID'];
}
/** Contains the customer's wish list and any errors encountered */
export interface RemoveProductsFromWishlistOutput {
  /** An array of errors encountered while deleting products from a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with after items were successfully deleted */
  wishlist: Wishlist;
}
export interface ReorderItemsOutput {
  /** Contains detailed information about the customer's cart. */
  cart: Cart;
  /** An array of reordering errors. */
  userInputErrors: Array<Maybe<CheckoutUserInputError>>;
}
export interface RevokeCustomerTokenOutput {
  result: Scalars['Boolean'];
}
/** Routable entities serve as the model for a rendered page */
export interface RoutableInterface {
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
}
/** Comment item details */
export interface SalesCommentItem {
  /** The text of the message */
  message: Scalars['String'];
  /** The timestamp of the comment */
  timestamp: Scalars['String'];
}
export interface SalesItemInterface {
  /** The entered gift message for the order item */
  gift_message?: Maybe<GiftMessage>;
}
/** SearchResultPageInfo provides navigation for the query response */
export interface SearchResultPageInfo {
  /** Specifies which page of results to return */
  current_page?: Maybe<Scalars['Int']>;
  /** Specifies the maximum number of items to return */
  page_size?: Maybe<Scalars['Int']>;
  /** Total pages */
  total_pages?: Maybe<Scalars['Int']>;
}
export interface SelectedBundleOption {
  /** @deprecated Use `uid` instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  type: Scalars['String'];
  /** The unique ID for a `SelectedBundleOption` object */
  uid: Scalars['ID'];
  values: Array<Maybe<SelectedBundleOptionValue>>;
}
export interface SelectedBundleOptionValue {
  /** Use `uid` instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  price: Scalars['Float'];
  quantity: Scalars['Float'];
  /** The unique ID for a `SelectedBundleOptionValue` object */
  uid: Scalars['ID'];
}
export interface SelectedConfigurableOption {
  /** The unique ID for a `ConfigurableProductOptions` object */
  configurable_product_option_uid: Scalars['ID'];
  /** The unique ID for a `ConfigurableProductOptionsValues` object */
  configurable_product_option_value_uid: Scalars['ID'];
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_uid instead */
  id: Scalars['Int'];
  option_label: Scalars['String'];
  /** @deprecated Use SelectedConfigurableOption.configurable_product_option_value_uid instead */
  value_id: Scalars['Int'];
  value_label: Scalars['String'];
}
export interface SelectedCustomizableOption {
  /** The unique ID for a `CustomizableRadioOption`, `CustomizableDropDownOption`, `CustomizableMultipleOption`, etc. of `CustomizableOptionInterface` objects */
  customizable_option_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomizableOption.customizable_option_uid instead */
  id: Scalars['Int'];
  is_required: Scalars['Boolean'];
  label: Scalars['String'];
  sort_order: Scalars['Int'];
  type: Scalars['String'];
  values: Array<Maybe<SelectedCustomizableOptionValue>>;
}
export interface SelectedCustomizableOptionValue {
  /** The unique ID for a `CustomizableMultipleValue`, `CustomizableRadioValue`, `CustomizableCheckboxValue`, `CustomizableDropDownValue`, etc. objects */
  customizable_option_value_uid: Scalars['ID'];
  /** @deprecated Use SelectedCustomizableOptionValue.customizable_option_value_uid instead */
  id: Scalars['Int'];
  label: Scalars['String'];
  price: CartItemSelectedOptionValuePrice;
  value: Scalars['String'];
}
export interface SelectedPaymentMethod {
  /** The payment method code */
  code: Scalars['String'];
  /** The purchase order number. */
  purchase_order_number?: Maybe<Scalars['String']>;
  /** The payment method title. */
  title: Scalars['String'];
}
export interface SelectedShippingMethod {
  amount: Money;
  /** @deprecated The field should not be used on the storefront */
  base_amount?: Maybe<Money>;
  carrier_code: Scalars['String'];
  carrier_title: Scalars['String'];
  method_code: Scalars['String'];
  method_title: Scalars['String'];
}
export interface SendEmailToFriendInput {
  product_id: Scalars['Int'];
  recipients: Array<InputMaybe<SendEmailToFriendRecipientInput>>;
  sender: SendEmailToFriendSenderInput;
}
export interface SendEmailToFriendOutput {
  recipients?: Maybe<Array<Maybe<SendEmailToFriendRecipient>>>;
  sender?: Maybe<SendEmailToFriendSender>;
}
export interface SendEmailToFriendRecipient {
  email: Scalars['String'];
  name: Scalars['String'];
}
export interface SendEmailToFriendRecipientInput {
  email: Scalars['String'];
  name: Scalars['String'];
}
export interface SendEmailToFriendSender {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
}
export interface SendEmailToFriendSenderInput {
  email: Scalars['String'];
  message: Scalars['String'];
  name: Scalars['String'];
}
export interface SendFriendConfiguration {
  /** Indicates whether the Email to a Friend feature is enabled. */
  enabled_for_customers: Scalars['Boolean'];
  /** Indicates whether the Email to a Friend feature is enabled for guests. */
  enabled_for_guests: Scalars['Boolean'];
}
export interface SetBillingAddressOnCartInput {
  billing_address: BillingAddressInput;
  cart_id: Scalars['String'];
}
export interface SetBillingAddressOnCartOutput {
  cart: Cart;
}
export interface SetGuestEmailOnCartInput {
  cart_id: Scalars['String'];
  email: Scalars['String'];
}
export interface SetGuestEmailOnCartOutput {
  cart: Cart;
}
export interface SetPaymentMethodAndPlaceOrderInput {
  cart_id: Scalars['String'];
  payment_method: PaymentMethodInput;
}
export interface SetPaymentMethodOnCartInput {
  cart_id: Scalars['String'];
  payment_method: PaymentMethodInput;
}
export interface SetPaymentMethodOnCartOutput {
  cart: Cart;
}
export interface SetShippingAddressesOnCartInput {
  cart_id: Scalars['String'];
  shipping_addresses: Array<InputMaybe<ShippingAddressInput>>;
}
export interface SetShippingAddressesOnCartOutput {
  cart: Cart;
}
export interface SetShippingMethodsOnCartInput {
  cart_id: Scalars['String'];
  shipping_methods: Array<InputMaybe<ShippingMethodInput>>;
}
export interface SetShippingMethodsOnCartOutput {
  cart: Cart;
}
export interface DeliveryDateInput {
  cart_id: Scalars['String'];
  delivery_date: Scalars['String'];
  delivery_time: Scalars['String'];
}

export interface DeliveryDateOutput {
  success: Scalars['Boolean'];
  message: Scalars['String'];
  fromDate: Scalars['String'];
  toDate: Scalars['String'];
}

/** This enumeration defines whether bundle items must be shipped together. */
export declare enum ShipBundleItemsEnum {
  Separately = 'SEPARATELY',
  Together = 'TOGETHER',
}
export interface ShipmentItem extends ShipmentItemInterface {
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
}
/** Order shipment item details */
export interface ShipmentItemInterface {
  /** The unique ID for a `ShipmentItemInterface` object */
  id: Scalars['ID'];
  /** Associated order item */
  order_item?: Maybe<OrderItemInterface>;
  /** Name of the base product */
  product_name?: Maybe<Scalars['String']>;
  /** Sale price for the base product */
  product_sale_price: Money;
  /** SKU of the base product */
  product_sku: Scalars['String'];
  /** Number of shipped items */
  quantity_shipped: Scalars['Float'];
}
/** Order shipment tracking details */
export interface ShipmentTracking {
  /** The shipping carrier for the order delivery */
  carrier: Scalars['String'];
  /** The tracking number of the order shipment */
  number?: Maybe<Scalars['String']>;
  /** The shipment tracking title */
  title: Scalars['String'];
}
export interface ShippingAddressInput {
  address?: InputMaybe<CartAddressInput>;
  customer_address_id?: InputMaybe<Scalars['Int']>;
  customer_notes?: InputMaybe<Scalars['String']>;
  /** The code of Pickup Location which will be used for In-Store Pickup. */
  pickup_location_code?: InputMaybe<Scalars['String']>;
}
export interface ShippingCartAddress extends CartAddressInterface {
  available_shipping_methods?: Maybe<Array<Maybe<AvailableShippingMethod>>>;
  /** @deprecated `cart_items_v2` should be used instead */
  cart_items?: Maybe<Array<Maybe<CartItemQuantity>>>;
  cart_items_v2?: Maybe<Array<Maybe<CartItemInterface>>>;
  city: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country: CartAddressCountry;
  customer_notes?: Maybe<Scalars['String']>;
  firstname: Scalars['String'];
  /** @deprecated This information shoud not be exposed on frontend */
  items_weight?: Maybe<Scalars['Float']>;
  lastname: Scalars['String'];
  pickup_location_code?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
  region?: Maybe<CartAddressRegion>;
  selected_shipping_method?: Maybe<SelectedShippingMethod>;
  street: Array<Maybe<Scalars['String']>>;
  telephone: Scalars['String'];
  firstnamekana?: Maybe<Scalars['String']>;
  lastnamekana?: Maybe<Scalars['String']>;
}
/** Defines an individual shipping discount. This discount can be applied to shipping. */
export interface ShippingDiscount {
  /** The amount of the discount */
  amount: Money;
}
/** The Shipping handling details */
export interface ShippingHandling {
  /** The shipping amount, excluding tax */
  amount_excluding_tax?: Maybe<Money>;
  /** The shipping amount, including tax */
  amount_including_tax?: Maybe<Money>;
  /** The applied discounts to the shipping */
  discounts?: Maybe<Array<Maybe<ShippingDiscount>>>;
  /** Contains details about taxes applied for shipping */
  taxes?: Maybe<Array<Maybe<TaxItem>>>;
  /** The total amount for shipping */
  total_amount: Money;
}
export interface ShippingMethodInput {
  carrier_code: Scalars['String'];
  method_code: Scalars['String'];
}
/** Simple Cart Item */
export interface SimpleCartItem extends CartItemInterface {
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The entered gift message for the cart item */
  gift_message?: Maybe<GiftMessage>;
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];

  engraving?:  Maybe<Array<Maybe<Engraving>>>/*zxcvbn*/
}
/** A simple product is tangible and is usually sold in single units or in fixed quantities */
export interface SimpleProduct extends CustomizableProductInterface, PhysicalProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A plp description of the product. Its use depends on the theme. */
  plp_description?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
  /** The weight of the item, in units defined by the store. */
  weight?: Maybe<Scalars['Float']>;
}
/** A simple product is tangible and is usually sold in single units or in fixed quantities */
export interface SimpleProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
export interface SimpleProductCartItemInput {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  data: CartItemInput;
}
/** A simple product wish list Item */
export interface SimpleWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
/** This enumeration indicates whether to return results in ascending or descending order */
export enum SortEnum {
  Asc = 'ASC',
  Desc = 'DESC',
}
export interface SortField {
  /** Label of sort field. */
  label?: Maybe<Scalars['String']>;
  /** Attribute code of sort field. */
  value?: Maybe<Scalars['String']>;
}
/** SortFields contains a default value for sort fields and all available sort fields. */
export interface SortFields {
  /** Default value of sort fields. */
  default?: Maybe<Scalars['String']>;
  /** Available sort fields. */
  options?: Maybe<Array<Maybe<SortField>>>;
}
/** The type contains information about a store config */
export interface StoreConfig {
  /** Google Suggestions */
  google_suggestions_active?: Maybe<Scalars['Boolean']>;
  google_api_key?: any;
  klevu_search_jsv2_api_key?: any;
  klevu_search_jsv2_api_url?: any;
  sales_gift_options_gw_section_title?: any;
  /** Footer Miscellaneous HTML */
  absolute_footer?: Maybe<Scalars['String']>;
  /** Indicates whether guest users can write product reviews. Possible values: 1 (Yes) and 0 (No) */
  allow_guests_to_write_product_reviews?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages for Order Items option */
  allow_items?: Maybe<Scalars['String']>;
  /** The value of the Allow Gift Messages on Order Level option */
  allow_order?: Maybe<Scalars['String']>;
  /** Enable autocomplete on login and forgot password forms */
  autocomplete_on_storefront?: Maybe<Scalars['Boolean']>;
  /** Base currency code */
  base_currency_code?: Maybe<Scalars['String']>;
  /** Base link URL for the store */
  base_link_url?: Maybe<Scalars['String']>;
  /** Base media URL for the store */
  base_media_url?: Maybe<Scalars['String']>;
  /** Base static URL for the store */
  base_static_url?: Maybe<Scalars['String']>;
  /** Base URL for the store */
  base_url?: Maybe<Scalars['String']>;
  /** Braintree cc vault status. */
  braintree_cc_vault_active?: Maybe<Scalars['String']>;
  /** Default Sort By. */
  catalog_default_sort_by?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices In Product Lists' field. It indicates how FPT information is displayed on category pages */
  category_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Category URL Suffix. */
  category_url_suffix?: Maybe<Scalars['String']>;
  /** CMS Home Page */
  cms_home_page?: Maybe<Scalars['String']>;
  /** CMS No Cookies Page */
  cms_no_cookies?: Maybe<Scalars['String']>;
  /** CMS No Route Page */
  cms_no_route?: Maybe<Scalars['String']>;
  /**
   * A code assigned to the store to identify it
   * @deprecated Use `store_code` instead.
   */
  code?: Maybe<Scalars['String']>;
  /** The configuration setting determines which thumbnail should be used in the cart for configurable products. */
  configurable_thumbnail_source?: Maybe<Scalars['String']>;
  /** Copyright */
  copyright?: Maybe<Scalars['String']>;
  /** Default Meta Description */
  default_description?: Maybe<Scalars['String']>;
  /** Default display currency code */
  default_display_currency_code?: Maybe<Scalars['String']>;
  /** Default Meta Keywords */
  default_keywords?: Maybe<Scalars['String']>;
  /** Default Page Title */
  default_title?: Maybe<Scalars['String']>;
  /** Display Demo Store Notice */
  demonotice?: Maybe<Scalars['Int']>;
  /** Default Web URL */
  front?: Maybe<Scalars['String']>;
  /** Products per Page on Grid Default Value. */
  grid_per_page?: Maybe<Scalars['Int']>;
  /** Products per Page on Grid Allowed Values. */
  grid_per_page_values?: Maybe<Scalars['String']>;
  /** Scripts and Style Sheets */
  head_includes?: Maybe<Scalars['String']>;
  /** Favicon Icon */
  head_shortcut_icon?: Maybe<Scalars['String']>;
  /** Logo Image */
  header_logo_src?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the store
   * @deprecated Use `store_code` instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** Indicates whether the store view has been designated as the default within the store group */
  is_default_store?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the store group has been designated as the default within the website */
  is_default_store_group?: Maybe<Scalars['Boolean']>;
  /** List Mode. */
  list_mode?: Maybe<Scalars['String']>;
  /** Products per Page on List Default Value. */
  list_per_page?: Maybe<Scalars['Int']>;
  /** Products per Page on List Allowed Values. */
  list_per_page_values?: Maybe<Scalars['String']>;
  /** Store locale */
  locale?: Maybe<Scalars['String']>;
  /** Logo Image Alt */
  logo_alt?: Maybe<Scalars['String']>;
  /** Logo Attribute Height */
  logo_height?: Maybe<Scalars['Int']>;
  /** Logo Attribute Width */
  logo_width?: Maybe<Scalars['Int']>;
  /** Indicates whether wishlists are enabled (1) or disabled (0) */
  magento_wishlist_general_is_enabled?: Maybe<Scalars['String']>;
  /** The minimum number of characters required for a valid password. */
  minimum_password_length?: Maybe<Scalars['String']>;
  /** Indicates whether subscription modal is enabled (true) or disabled (false) */
  newsletter_subscribe_widget_enabled?: Maybe<Scalars['Boolean']>;
  /** Default No-route URL */
  no_route?: Maybe<Scalars['String']>;
  /** Payflow Pro vault status. */
  payment_payflowpro_cc_vault_active?: Maybe<Scalars['String']>;
  /** Corresponds to the 'Display Prices On Product View Page' field. It indicates how FPT information is displayed on product pages */
  product_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Indicates whether product reviews are enabled. Possible values: 1 (Yes) and 0 (No) */
  product_reviews_enabled?: Maybe<Scalars['String']>;
  /** Product URL Suffix. */
  product_url_suffix?: Maybe<Scalars['String']>;
  /** The number of different character classes required in a password (lowercase, uppercase, digits, special characters). */
  required_character_classes_number?: Maybe<Scalars['String']>;
  /**
   * The ID of the root category
   * @deprecated Use `root_category_uid` instead
   */
  root_category_id?: Maybe<Scalars['Int']>;
  /** The unique ID for a `CategoryInterface` object. */
  root_category_uid?: Maybe<Scalars['ID']>;
  /** Corresponds to the 'Display Prices In Sales Modules' field. It indicates how FPT information is displayed on cart, checkout, and order pages */
  sales_fixed_product_tax_display_setting?: Maybe<FixedProductTaxDisplaySettings>;
  /** Secure base link URL for the store */
  secure_base_link_url?: Maybe<Scalars['String']>;
  /** Secure base media URL for the store */
  secure_base_media_url?: Maybe<Scalars['String']>;
  /** Secure base static URL for the store */
  secure_base_static_url?: Maybe<Scalars['String']>;
  /** Secure base URL for the store */
  secure_base_url?: Maybe<Scalars['String']>;
  /** Email to a Friend configuration. */
  send_friend?: Maybe<SendFriendConfiguration>;
  /** Show Breadcrumbs for CMS Pages */
  show_cms_breadcrumbs?: Maybe<Scalars['Int']>;
  /** The unique ID of the store view. In the Admin, this is called the Store View Code. When making a GraphQL call, assign this value to the `Store` header to provide the scope */
  store_code?: Maybe<Scalars['ID']>;
  /** The unique ID assigned to the store group. In the Admin, this is called the Store Name */
  store_group_code?: Maybe<Scalars['ID']>;
  /** The label assigned to the store group */
  store_group_name?: Maybe<Scalars['String']>;
  /** The label assigned to the store view */
  store_name?: Maybe<Scalars['String']>;
  /** The store view sort order */
  store_sort_order?: Maybe<Scalars['Int']>;
  /** Timezone of the store */
  timezone?: Maybe<Scalars['String']>;
  /** Page Title Prefix */
  title_prefix?: Maybe<Scalars['String']>;
  /** Page Title Separator. */
  title_separator?: Maybe<Scalars['String']>;
  /** Page Title Suffix */
  title_suffix?: Maybe<Scalars['String']>;
  /** The configuration determines if the store code should be used in the URL */
  use_store_in_url?: Maybe<Scalars['Boolean']>;
  /** The unique ID for the website */
  website_code?: Maybe<Scalars['ID']>;
  /**
   * The ID number assigned to the website store
   * @deprecated The field should not be used on the storefront
   */
  website_id?: Maybe<Scalars['Int']>;
  /** The label assigned to the website */
  website_name?: Maybe<Scalars['String']>;
  /** The unit of weight */
  weight_unit?: Maybe<Scalars['String']>;
  /** Welcome Text */
  welcome?: Maybe<Scalars['String']>;
  klarna_pay_instalments_locale?: Scalars['String'];
  klarna_pay_instalments_is_enabled?: Scalars['Boolean'];
  klarna_pay_instalments_min_price?: Scalars['Int'];
  general_country_default?: Scalars['String'];
  vaimo_socloz_reservation_enable?: Scalars['String'];
  tax_display_is_tax_details_display?: Scalars['String'];
  tax_cart_display_price?: Scalars['String'];
  tax_cart_display_subtotal?: Scalars['String'];
  is_subscribed?: Scalars['Boolean'];
  sociallogin_general_enabled?: Scalars['Boolean'];
  giftmessage_giftmessagelimit_giftmessagecharlimit?: Maybe<Scalars['Int']>;
  giftmessage_giftmessagelimit_giftmessage_separate?: Maybe<Scalars['Boolean']>;
  samples_samples_cart_amount?: Maybe<Scalars['Int']>;
  xmas_enable?: Scalars['Boolean'];
  xmas_checkbox_text?: Scalars['String'];
  xmas_skus?: Array<Scalars['String']>;
  vaimo_geoip_general_enable?: Scalars['Boolean'];
  zip2address_app_id?: Maybe<Scalars['String']>;
  web_cookie_cookie_lifetime?: Maybe<Scalars['Int']>;
  phone_mask_with_flag_enabled?: Maybe<Scalars['Boolean']>;
  use_kana?: Maybe<Scalars['Boolean']>;
  require_kana?: Maybe<Scalars['Boolean']>;
  kana_type?: Maybe<Scalars['Int']>;
  contactus_contact_information?: Maybe<Scalars['String']>;
  contactus_contact_phone?: Maybe<Scalars['String']>;
  contactus_object_options?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_enabled?: Maybe<Scalars['Boolean']>;
  booxi_appointment_scheduling_script_url?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_api_url?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_merchant_api_key?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_language?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_fallback_language?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_on_abort_redirect_to?: Maybe<Scalars['String']>;
  booxi_appointment_scheduling_auto_close_enabled?: Maybe<Scalars['Boolean']>;
  booxi_appointment_scheduling_auto_close_time?: Maybe<Scalars['Int']>;
  forter_settings_enabled?: Maybe<Scalars['Boolean']>;
  forter_account_settings_site_id?: Maybe<Scalars['String']>;
  forter_settings_sandbox_mode_enabled?: Maybe<Scalars['Boolean']>;
  checkout_sidebar_free_shipping_icon?: Maybe<Scalars['String']>;
  checkout_sidebar_free_shipping_left_text?: Maybe<Scalars['String']>;
  checkout_sidebar_free_shipping_text?: Maybe<Scalars['String']>;
  checkout_sidebar_display_free_shipping_section?: Maybe<Scalars['Boolean']>;
}
export interface StorefrontProperties {
  /** The relative position of the attribute in the layered navigation block */
  position?: Maybe<Scalars['Int']>;
  /** Indicates whether the attribute is filterable with results, without results, or not at all */
  use_in_layered_navigation?: Maybe<UseInLayeredNavigationOptions>;
  /** Indicates whether the attribute is displayed in product listings */
  use_in_product_listing?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute can be used in layered navigation on search results pages */
  use_in_search_results_layered_navigation?: Maybe<Scalars['Boolean']>;
  /** Indicates whether the attribute is displayed on product pages */
  visible_on_catalog_pages?: Maybe<Scalars['Boolean']>;
}
export interface SubscribeEmailToNewsletterOutput {
  /** Returns the status of the subscription request */
  status?: Maybe<SubscriptionStatusesEnum>;
}
export enum SubscriptionStatusesEnum {
  NotActive = 'NOT_ACTIVE',
  Subscribed = 'SUBSCRIBED',
  Unconfirmed = 'UNCONFIRMED',
  Unsubscribed = 'UNSUBSCRIBED',
}
export interface SwatchData {
  /** Type of swatch filter item: 1 - text, 2 - image */
  type?: Maybe<Scalars['String']>;
  /** Value for swatch item (text or image link) */
  value?: Maybe<Scalars['String']>;
}
export interface SwatchDataInterface {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
}
export interface SwatchLayerFilterItem extends LayerFilterItemInterface, SwatchLayerFilterItemInterface {
  /**
   * Count of items by filter.
   * @deprecated Use AggregationOption.count instead.
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * Filter label.
   * @deprecated Use AggregationOption.label instead.
   */
  label?: Maybe<Scalars['String']>;
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>;
  /**
   * Value for filter request variable to be used in query.
   * @deprecated Use AggregationOption.value instead.
   */
  value_string?: Maybe<Scalars['String']>;
}
export interface SwatchLayerFilterItemInterface {
  /** Data required to render swatch filter item */
  swatch_data?: Maybe<SwatchData>;
}
/** The tax item details */
export interface TaxItem {
  /** The amount of tax applied to the item */
  amount: Money;
  /** The rate used to calculate the tax */
  rate: Scalars['Float'];
  /** A title that describes the tax */
  title: Scalars['String'];
}
export interface TextSwatchData extends SwatchDataInterface {
  /** Value of swatch item (HEX color code, image link or textual value) */
  value?: Maybe<Scalars['String']>;
}
/** A price based on the quantity purchased. */
export interface TierPrice {
  /** The price discount that this tier represents. */
  discount?: Maybe<ProductDiscount>;
  final_price?: Maybe<Money>;
  /** The minimum number of items that must be purchased to qualify for this price tier. */
  quantity?: Maybe<Scalars['Float']>;
}
export interface TrackingData {
  /** Is Page Tracking enabled */
  page_tracking_enabled?: Maybe<Scalars['Boolean']>;
  /** dotdigital region prefix */
  region_prefix?: Maybe<Scalars['String']>;
  /** Is ROI Tracking enabled */
  roi_tracking_enabled?: Maybe<Scalars['Boolean']>;
  /** Web Behaviour Tracking profile ID */
  wbt_profile_id?: Maybe<Scalars['String']>;
}
export interface UpdateCartItemsInput {
  cart_id: Scalars['String'];
  cart_items: Array<InputMaybe<CartItemUpdateInput>>;
}
export interface UpdateCartItemsOutput {
  cart: Cart;
}
/** Contains the customer's wish list and any errors encountered */
export interface UpdateProductsInWishlistOutput {
  /** An array of errors encountered while updating products in a wish list */
  user_errors: Array<Maybe<WishListUserInputError>>;
  /** Contains the wish list with all items that were successfully updated */
  wishlist: Wishlist;
}
/** The object contains URL rewrite details */
export interface UrlRewrite {
  /** Request parameters */
  parameters?: Maybe<Array<Maybe<HttpQueryParameter>>>;
  /** Request URL */
  url?: Maybe<Scalars['String']>;
}
/** This enumeration defines the entity type. */
export declare enum UrlRewriteEntityTypeEnum {
  Category = 'CATEGORY',
  CmsPage = 'CMS_PAGE',
  Product = 'PRODUCT',
}
export declare enum UseInLayeredNavigationOptions {
  FilterableNoResult = 'FILTERABLE_NO_RESULT',
  FilterableWithResults = 'FILTERABLE_WITH_RESULTS',
  No = 'NO',
}
/** Required input for payment methods with Vault support. */
export interface VaultTokenInput {
  /** The public hash of the payment token */
  public_hash: Scalars['String'];
}
/** Virtual Cart Item */
export interface VirtualCartItem extends CartItemInterface {
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** @deprecated Use `uid` instead */
  id: Scalars['String'];
  prices?: Maybe<CartItemPrices>;
  product: ProductInterface;
  quantity: Scalars['Float'];
  /** The unique ID for a `CartItemInterface` object */
  uid: Scalars['ID'];
}
/** A virtual product is a non-tangible product that does not require shipping and is not kept in inventory */
export interface VirtualProduct extends CustomizableProductInterface, ProductInterface, RoutableInterface {
  activity?: Maybe<Scalars['String']>;
  /**
   * The attribute set assigned to the product.
   * @deprecated The field should not be used on the storefront.
   */
  attribute_set_id?: Maybe<Scalars['Int']>;
  /** Relative canonical URL. This value is returned only if the system setting 'Use Canonical Link Meta Tag For Products' is enabled */
  canonical_url?: Maybe<Scalars['String']>;
  /** The categories assigned to a product. */
  categories?: Maybe<Array<Maybe<CategoryInterface>>>;
  category_gear?: Maybe<Scalars['String']>;
  climate?: Maybe<Scalars['String']>;
  collar?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['Int']>;
  /** The product's country of origin. */
  country_of_manufacture?: Maybe<Scalars['String']>;
  /**
   * Timestamp indicating when the product was created.
   * @deprecated The field should not be used on the storefront.
   */
  created_at?: Maybe<Scalars['String']>;
  /** Crosssell Products */
  crosssell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Detailed information about the product. The value can include simple HTML tags. */
  description?: Maybe<ComplexTextValue>;
  eco_collection?: Maybe<Scalars['Int']>;
  erin_recommends?: Maybe<Scalars['Int']>;
  features_bags?: Maybe<Scalars['String']>;
  format?: Maybe<Scalars['Int']>;
  gender?: Maybe<Scalars['String']>;
  /** Indicates whether a gift message is available. */
  gift_message_available?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the product.
   * @deprecated Use the `uid` field instead.
   */
  id?: Maybe<Scalars['Int']>;
  /** The relative path to the main image on the product page. */
  image?: Maybe<ProductImage>;
  /** A number representing the product's manufacturer. */
  manufacturer?: Maybe<Scalars['Int']>;
  material?: Maybe<Scalars['String']>;
  /** An array of Media Gallery objects. */
  media_gallery?: Maybe<Array<Maybe<MediaGalleryInterface>>>;
  /**
   * An array of MediaGalleryEntry objects.
   * @deprecated Use product's `media_gallery` instead
   */
  media_gallery_entries?: Maybe<Array<Maybe<MediaGalleryEntry>>>;
  /** A brief overview of the product for search results listings, maximum 255 characters. */
  meta_description?: Maybe<Scalars['String']>;
  /** A comma-separated list of keywords that are visible only to search engines. */
  meta_keyword?: Maybe<Scalars['String']>;
  /** A string that is displayed in the title bar and tab of the browser and in search results lists. */
  meta_title?: Maybe<Scalars['String']>;
  /** The product name. Customers use this name to identify the product. */
  name?: Maybe<Scalars['String']>;
  new?: Maybe<Scalars['Int']>;
  /**
   * The beginning date for new product listings, and determines if the product is featured as a new product.
   * @deprecated The field should not be used on the storefront.
   */
  new_from_date?: Maybe<Scalars['String']>;
  /**
   * The end date for new product listings.
   * @deprecated The field should not be used on the storefront.
   */
  new_to_date?: Maybe<Scalars['String']>;
  /** Product stock only x left count */
  only_x_left_in_stock?: Maybe<Scalars['Float']>;
  /** An array of options for a customizable product. */
  options?: Maybe<Array<Maybe<CustomizableOptionInterface>>>;
  /** If the product has multiple options, determines where they appear on the product page. */
  options_container?: Maybe<Scalars['String']>;
  pattern?: Maybe<Scalars['String']>;
  performance_fabric?: Maybe<Scalars['Int']>;
  /**
   * A ProductPrices object, indicating the price of an item.
   * @deprecated Use price_range for product price information.
   */
  price?: Maybe<ProductPrices>;
  /** A PriceRange object, indicating the range of prices for the product */
  price_range: PriceRange;
  /** An array of TierPrice objects. */
  price_tiers?: Maybe<Array<Maybe<TierPrice>>>;
  price_excluded_tax?: Maybe<Scalars['String']>;
  /** An array of ProductLinks objects. */
  product_links?: Maybe<Array<Maybe<ProductLinksInterface>>>;
  /** The average of all the ratings given to the product. */
  rating_summary: Scalars['Float'];
  /** Contains 0 when there is no redirect error. A value of 301 indicates the URL of the requested resource has been changed permanently, while a value of 302 indicates a temporary redirect */
  redirect_code: Scalars['Int'];
  /** Related Products */
  related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** Related Products */
  revamp_related_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The internal relative URL. If the specified URL is a redirect, the query returns the redirected URL, not the original */
  relative_url?: Maybe<Scalars['String']>;
  /** The total count of all the reviews given to the product. */
  review_count: Scalars['Int'];
  /** The list of products reviews. */
  reviews: ProductReviews;
  sale?: Maybe<Scalars['Int']>;
  /** A short description of the product. Its use depends on the theme. */
  short_description?: Maybe<ComplexTextValue>;
  /** A plp description of the product. Its use depends on the theme. */
  plp_description?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  /** A number or code assigned to a product to identify the product, options, price, and manufacturer. */
  sku?: Maybe<Scalars['String']>;
  sleeve?: Maybe<Scalars['String']>;
  /** The relative path to the small image, which is used on catalog pages. */
  small_image?: Maybe<ProductImage>;
  /**
   * The beginning date that a product has a special price.
   * @deprecated The field should not be used on the storefront.
   */
  special_from_date?: Maybe<Scalars['String']>;
  /** The discounted price of the product. */
  special_price?: Maybe<Scalars['Float']>;
  /** The end date that a product has a special price. */
  special_to_date?: Maybe<Scalars['String']>;
  /** Stock status of the product */
  stock_status?: Maybe<ProductStockStatus>;
  is_salable?: Maybe<Scalars['Boolean']>;
  strap_bags?: Maybe<Scalars['String']>;
  style_bags?: Maybe<Scalars['String']>;
  style_bottom?: Maybe<Scalars['String']>;
  style_general?: Maybe<Scalars['String']>;
  /** The file name of a swatch image */
  swatch_image?: Maybe<Scalars['String']>;
  /** The relative path to the product's thumbnail image. */
  thumbnail?: Maybe<ProductImage>;
  /**
   * The price when tier pricing is in effect and the items purchased threshold has been reached.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_price?: Maybe<Scalars['Float']>;
  /**
   * An array of ProductTierPrices objects.
   * @deprecated Use price_tiers for product tier price information.
   */
  tier_prices?: Maybe<Array<Maybe<ProductTierPrices>>>;
  /** One of PRODUCT, CATEGORY, or CMS_PAGE. */
  type?: Maybe<UrlRewriteEntityTypeEnum>;
  /**
   * One of simple, virtual, bundle, downloadable, grouped, or configurable.
   * @deprecated Use __typename instead.
   */
  type_id?: Maybe<Scalars['String']>;
  /** The unique ID for a `ProductInterface` object. */
  uid: Scalars['ID'];
  /**
   * Timestamp indicating when the product was updated.
   * @deprecated The field should not be used on the storefront.
   */
  updated_at?: Maybe<Scalars['String']>;
  /** Upsell Products */
  upsell_products?: Maybe<Array<Maybe<ProductInterface>>>;
  /** The part of the URL that identifies the product */
  url_key?: Maybe<Scalars['String']>;
  /** @deprecated Use product's `canonical_url` or url rewrites instead */
  url_path?: Maybe<Scalars['String']>;
  /** URL rewrites list */
  url_rewrites?: Maybe<Array<Maybe<UrlRewrite>>>;
  /** The part of the product URL that is appended after the url key */
  url_suffix?: Maybe<Scalars['String']>;
  /**
   * An array of websites in which the product is available.
   * @deprecated The field should not be used on the storefront.
   */
  websites?: Maybe<Array<Maybe<Website>>>;
}
/** A virtual product is a non-tangible product that does not require shipping and is not kept in inventory */
export interface VirtualProductReviewsArgs {
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}
export interface VirtualProductCartItemInput {
  customizable_options?: InputMaybe<Array<InputMaybe<CustomizableOptionInput>>>;
  data: CartItemInput;
}
/** A virtual product wish list item */
export interface VirtualWishlistItem extends WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
/** Website is deprecated because it is should not be used on storefront. The type contains information about a website */
export interface Website {
  /**
   * A code assigned to the website to identify it
   * @deprecated The field should not be used on the storefront.
   */
  code?: Maybe<Scalars['String']>;
  /**
   * The default group ID that the website has
   * @deprecated The field should not be used on the storefront.
   */
  default_group_id?: Maybe<Scalars['String']>;
  /**
   * The ID number assigned to the website
   * @deprecated The field should not be used on the storefront.
   */
  id?: Maybe<Scalars['Int']>;
  /**
   * Specifies if this is the default website
   * @deprecated The field should not be used on the storefront.
   */
  is_default?: Maybe<Scalars['Boolean']>;
  /**
   * The website name. Websites use this name to identify it easier.
   * @deprecated The field should not be used on the storefront.
   */
  name?: Maybe<Scalars['String']>;
  /**
   * The attribute to use for sorting websites
   * @deprecated The field should not be used on the storefront.
   */
  sort_order?: Maybe<Scalars['Int']>;
}
/** An error encountered while performing operations with WishList. */
export interface WishListUserInputError {
  /** Wishlist-specific error code */
  code: WishListUserInputErrorType;
  /** A localized error message */
  message: Scalars['String'];
}
export declare enum WishListUserInputErrorType {
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}
export interface Wishlist {
  /** The unique ID for a `Wishlist` object */
  id?: Maybe<Scalars['ID']>;
  /** @deprecated Use field `items_v2` from type `Wishlist` instead */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /** The number of items in the wish list */
  items_count?: Maybe<Scalars['Int']>;
  /** An array of items in the customer's wish list */
  items_v2?: Maybe<WishlistItems>;
  /** An encrypted code that Magento uses to link to the wish list */
  sharing_code?: Maybe<Scalars['String']>;
  /** The time of the last modification to the wish list */
  updated_at?: Maybe<Scalars['String']>;
}

export interface WishlistCartUserInputError {
  /** An error code that describes the error encountered */
  code: WishlistCartUserInputErrorType;
  /** A localized error message */
  message: Scalars['String'];
  /** The unique ID of the `Wishlist` object containing an error */
  wishlistId: Scalars['ID'];
  /** The unique ID of the wish list item containing an error */
  wishlistItemId: Scalars['ID'];
}
export enum WishlistCartUserInputErrorType {
  InsufficientStock = 'INSUFFICIENT_STOCK',
  NotSalable = 'NOT_SALABLE',
  ProductNotFound = 'PRODUCT_NOT_FOUND',
  Undefined = 'UNDEFINED',
}
export interface WishlistItem {
  /** The time when the customer added the item to the wish list */
  added_at?: Maybe<Scalars['String']>;
  /** The customer's comment about this item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItem` object */
  id?: Maybe<Scalars['Int']>;
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  qty?: Maybe<Scalars['Float']>;
}
/** Defines the items to add to a wish list */
export interface WishlistItemInput {
  /** An array of options that the customer entered */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** For complex product types, the SKU of the parent product */
  parent_sku?: InputMaybe<Scalars['String']>;
  /** The amount or number of items to add */
  quantity: Scalars['Float'];
  /** An array of strings corresponding to options the customer selected */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The SKU of the product to add. For complex product types, specify the child product SKU */
  sku: Scalars['String'];
}
export interface WishlistItemInterface {
  /** The date and time the item was added to the wish list */
  added_at: Scalars['String'];
  /** Custom options selected for the wish list item */
  customizable_options: Array<Maybe<SelectedCustomizableOption>>;
  /** The description of the item */
  description?: Maybe<Scalars['String']>;
  /** The unique ID for a `WishlistItemInterface` object */
  id: Scalars['ID'];
  /** Product details of the wish list item */
  product?: Maybe<ProductInterface>;
  /** The quantity of this wish list item */
  quantity: Scalars['Float'];
}
/** Defines updates to items in a wish list */
export interface WishlistItemUpdateInput {
  /** Customer-entered comments about the item */
  description?: InputMaybe<Scalars['String']>;
  /** An array of options that the customer entered */
  entered_options?: InputMaybe<Array<InputMaybe<EnteredOptionInput>>>;
  /** The new amount or number of this item */
  quantity?: InputMaybe<Scalars['Float']>;
  /** An array of strings corresponding to options the customer selected */
  selected_options?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  /** The unique ID for a `WishlistItemInterface` object */
  wishlist_item_id: Scalars['ID'];
}
export interface WishlistItems {
  /** A list of items in the wish list */
  items: Array<Maybe<WishlistItemInterface>>;
  /** Contains pagination metadata */
  page_info?: Maybe<SearchResultPageInfo>;
}
/** Deprecated: `Wishlist` type should be used instead */
export interface WishlistOutput {
  /**
   * An array of items in the customer's wish list
   * @deprecated Use field `items` from type `Wishlist` instead
   */
  items?: Maybe<Array<Maybe<WishlistItem>>>;
  /**
   * The number of items in the wish list
   * @deprecated Use field `items_count` from type `Wishlist` instead
   */
  items_count?: Maybe<Scalars['Int']>;
  /**
   * When multiple wish lists are enabled, the name the customer assigns to the wishlist
   * @deprecated This field is related to Commerce functionality and is always `null` in Open Source edition
   */
  name?: Maybe<Scalars['String']>;
  /**
   * An encrypted code that Magento uses to link to the wish list
   * @deprecated Use field `sharing_code` from type `Wishlist` instead
   */
  sharing_code?: Maybe<Scalars['String']>;
  /**
   * The time of the last modification to the wish list
   * @deprecated Use field `updated_at` from type `Wishlist` instead
   */
  updated_at?: Maybe<Scalars['String']>;
}
export interface CreateEmptyCartInput {
  cart_id?: InputMaybe<Scalars['String']>;
}
export interface CreateKlarnaPaymentsSessionInput {
  cart_id: Scalars['String'];
}
export interface CreateKlarnaPaymentsSessionOutput {
  /** The payment method client token */
  client_token?: Maybe<Scalars['String']>;
  /** The payment method categories */
  payment_method_categories?: Maybe<Array<Maybe<Categories>>>;
}
export declare type AddBundleProductsToCartMutationVariables = Exact<{
  input?: InputMaybe<AddBundleProductsToCartInput>;
}>;
export declare type AddBundleProductsToCartMutation = {
  addBundleProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AddConfigurableProductsToCartMutationVariables = Exact<{
  input?: InputMaybe<AddConfigurableProductsToCartInput>;
}>;
export declare type AddConfigurableProductsToCartMutation = {
  addConfigurableProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AddDownloadableProductsToCartMutationVariables = Exact<{
  input?: InputMaybe<AddDownloadableProductsToCartInput>;
}>;
export declare type AddDownloadableProductsToCartMutation = {
  addDownloadableProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AddProductsToWishlistMutationVariables = Exact<{
  id: Scalars['ID'];
  items: Array<WishlistItemInput> | WishlistItemInput;
}>;
export declare type AddProductsToWishlistMutation = {
  addProductsToWishlist?: {
    wishlist: {
      id?: string | null | undefined;
      items_count?: number | null | undefined;
      sharing_code?: string | null | undefined;
      items_v2?: {
        items: Array<{
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | null | undefined>;
        page_info?: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AddProductsToCartMutationVariables = Exact<{
  cartId: Scalars['String'];
  cartItems: Array<CartItemInput> | CartItemInput;
}>;

export interface Engraving {
  color: Maybe<Scalars['String']>;
  engraving_text: Maybe<Scalars['String']>;
  font: Maybe<Scalars['String']>;
  // template: Maybe<EngravingTemplate>;
}

export interface EngravingTemplate {
  entity_id: Maybe<Scalars['Int']>;
  image_url: Maybe<Scalars['String']>;
  key: Maybe<Scalars['String']>;
  line1_maxlength: Maybe<Scalars['Int']>;
  line2_maxlength: Maybe<Scalars['Int']>;
  number_of_lines: Maybe<Scalars['Int']>;
  is_text_vertical: Maybe<Scalars['Int']>;
  pos_x: Maybe<Scalars['Float']>;
  pos_y: Maybe<Scalars['Float']>;
  product_type: Maybe<Scalars['String']>;
}

export interface EngravingCartItemInput {
  template_id: Maybe<Scalars['Int']>;
  sku: Maybe<Scalars['String']>;
  font: Maybe<Scalars['String']>;
  engraving_text: Maybe<Scalars['String']>;
  color: Maybe<Scalars['String']>;
};

export declare type AddProductsToCartMutation = {
  addProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;

    };
  } | null | undefined;
};
export declare type AddSimpleProductsToCartMutationVariables = Exact<{
  input?: InputMaybe<AddSimpleProductsToCartInput>;
}>;
export declare type AddSimpleProductsToCartMutation = {
  addSimpleProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
        engraving?: Array<{
          color: string | null | undefined;
          engraving_text: string | null | undefined;
          font: string | null | undefined;
        } | null | undefined>;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AddVirtualProductsToCartMutationVariables = Exact<{
  input?: InputMaybe<AddVirtualProductsToCartInput>;
}>;
export declare type AddVirtualProductsToCartMutation = {
  addVirtualProductsToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type ApplyCouponToCartMutationVariables = Exact<{
  input?: InputMaybe<ApplyCouponToCartInput>;
}>;
export declare type ApplyCouponToCartMutation = {
  applyCouponToCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type AvailableStoresQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type AvailableStoresQuery = {
  availableStores?: Array<{
    absolute_footer?: string | null | undefined;
    allow_guests_to_write_product_reviews?: string | null | undefined;
    allow_items?: string | null | undefined;
    allow_order?: string | null | undefined;
    autocomplete_on_storefront?: boolean | null | undefined;
    base_currency_code?: string | null | undefined;
    base_link_url?: string | null | undefined;
    base_media_url?: string | null | undefined;
    base_static_url?: string | null | undefined;
    base_url?: string | null | undefined;
    catalog_default_sort_by?: string | null | undefined;
    category_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    category_url_suffix?: string | null | undefined;
    cms_home_page?: string | null | undefined;
    cms_no_cookies?: string | null | undefined;
    cms_no_route?: string | null | undefined;
    code?: string | null | undefined;
    configurable_thumbnail_source?: string | null | undefined;
    copyright?: string | null | undefined;
    default_description?: string | null | undefined;
    default_display_currency_code?: string | null | undefined;
    default_keywords?: string | null | undefined;
    default_title?: string | null | undefined;
    front?: string | null | undefined;
    grid_per_page?: number | null | undefined;
    grid_per_page_values?: string | null | undefined;
    head_includes?: string | null | undefined;
    head_shortcut_icon?: string | null | undefined;
    header_logo_src?: string | null | undefined;
    id?: number | null | undefined;
    is_default_store?: boolean | null | undefined;
    is_default_store_group?: boolean | null | undefined;
    list_mode?: string | null | undefined;
    list_per_page?: number | null | undefined;
    list_per_page_values?: string | null | undefined;
    locale?: string | null | undefined;
    logo_alt?: string | null | undefined;
    logo_height?: number | null | undefined;
    logo_width?: number | null | undefined;
    magento_wishlist_general_is_enabled?: string | null | undefined;
    minimum_password_length?: string | null | undefined;
    newsletter_subscribe_widget_enabled?: boolean | null | undefined;
    no_route?: string | null | undefined;
    payment_payflowpro_cc_vault_active?: string | null | undefined;
    product_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    product_reviews_enabled?: string | null | undefined;
    product_url_suffix?: string | null | undefined;
    required_character_classes_number?: string | null | undefined;
    root_category_id?: number | null | undefined;
    root_category_uid?: string | null | undefined;
    sales_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    secure_base_link_url?: string | null | undefined;
    secure_base_media_url?: string | null | undefined;
    secure_base_static_url?: string | null | undefined;
    secure_base_url?: string | null | undefined;
    show_cms_breadcrumbs?: number | null | undefined;
    store_code?: string | null | undefined;
    store_group_code?: string | null | undefined;
    store_group_name?: string | null | undefined;
    store_name?: string | null | undefined;
    store_sort_order?: number | null | undefined;
    timezone?: string | null | undefined;
    title_prefix?: string | null | undefined;
    title_separator?: string | null | undefined;
    title_suffix?: string | null | undefined;
    use_store_in_url?: boolean | null | undefined;
    website_code?: string | null | undefined;
    website_id?: number | null | undefined;
    website_name?: string | null | undefined;
    weight_unit?: string | null | undefined;
    welcome?: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export declare type CartQueryVariables = Exact<{
  cartId: Scalars['String'];
}>;
export declare type CartQuery = {
  cart?: {
    id: string;
    email?: string | null | undefined;
    is_virtual: boolean;
    total_quantity: number;
    applied_coupons?: Array<{
      code: string;
      free_shipping_coupon: boolean;
    } | null | undefined> | null | undefined;
    prices?: {
      subtotal_excluding_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      subtotal_including_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      applied_taxes?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      discounts?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      grand_total?: {
        value?: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
    items?: Array<{
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
      uid: string;
      quantity: number;
      bundle_options: Array<{
        uid: string;
        label: string;
        type: string;
        values: Array<{
          id: number;
          label: string;
          price: number;
          quantity: number;
        } | null | undefined>;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      configurable_options: Array<{
        configurable_product_option_uid: string;
        option_label: string;
        configurable_product_option_value_uid: string;
        value_label: string;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
    } | null | undefined> | null | undefined;
    shipping_addresses: Array<{
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
      selected_shipping_method?: {
        carrier_code: string;
        carrier_title: string;
        method_code: string;
        method_title: string;
        amount: {
          value?: number | null | undefined;
          currency?: CurrencyEnum | null | undefined;
        };
      } | null | undefined;
    } | null | undefined>;
    billing_address?: {
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
    } | null | undefined;
  } | null | undefined;
};
export declare type CategoryListQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CategoryListQuery = {
  categories?: {
    items?: Array<{
      product_count?: number | null | undefined;
      name?: string | null | undefined;
      uid: string;
      meta_title?: string | null | undefined;
      meta_description?: string | null | undefined;
      children?: Array<{
        include_in_menu?: number | null | undefined;
        is_anchor?: number | null | undefined;
        level?: number | null | undefined;
        name?: string | null | undefined;
        position?: number | null | undefined;
        product_count?: number | null | undefined;
        uid: string;
        url_path?: string | null | undefined;
        url_suffix?: string | null | undefined;
        meta_title?: string | null | undefined;
        meta_description?: string | null | undefined;
        children?: Array<{
          include_in_menu?: number | null | undefined;
          is_anchor?: number | null | undefined;
          level?: number | null | undefined;
          name?: string | null | undefined;
          position?: number | null | undefined;
          product_count?: number | null | undefined;
          uid: string;
          url_path?: string | null | undefined;
          url_suffix?: string | null | undefined;
          meta_title?: string | null | undefined;
          meta_description?: string | null | undefined;
          children?: Array<{
            include_in_menu?: number | null | undefined;
            is_anchor?: number | null | undefined;
            level?: number | null | undefined;
            name?: string | null | undefined;
            position?: number | null | undefined;
            product_count?: number | null | undefined;
            uid: string;
            url_path?: string | null | undefined;
            url_suffix?: string | null | undefined;
            meta_title?: string | null | undefined;
            meta_description?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CategorySearchQueryVariables = Exact<{
  filters?: InputMaybe<CategoryFilterInput>;
}>;
export declare type CategorySearchQuery = {
  categoryList?: Array<{
    include_in_menu?: number | null | undefined;
    is_anchor?: number | null | undefined;
    level?: number | null | undefined;
    name?: string | null | undefined;
    position?: number | null | undefined;
    product_count?: number | null | undefined;
    uid: string;
    url_path?: string | null | undefined;
    url_suffix?: string | null | undefined;
    meta_title?: string | null | undefined;
    meta_description?: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export declare type ChangeCustomerPasswordMutationVariables = Exact<{
  currentPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;
export declare type ChangeCustomerPasswordMutation = {
  changeCustomerPassword?: {
    date_of_birth?: string | null | undefined;
    default_billing?: string | null | undefined;
    default_shipping?: string | null | undefined;
    email?: string | null | undefined;
    firstname?: string | null | undefined;
    is_subscribed?: boolean | null | undefined;
    lastname?: string | null | undefined;
    middlename?: string | null | undefined;
    firstnamekana?: string | null | undefined;
    lastnamekana?: string | null | undefined;
    prefix?: string | null | undefined;
    suffix?: string | null | undefined;
    taxvat?: string | null | undefined;
    addresses?: Array<{
      city?: string | null | undefined;
      country_code?: CountryCodeEnum | null | undefined;
      default_billing?: boolean | null | undefined;
      default_shipping?: boolean | null | undefined;
      firstname?: string | null | undefined;
      id?: number | null | undefined;
      lastname?: string | null | undefined;
      postcode?: string | null | undefined;
      prefix?: string | null | undefined;
      street?: Array<string | null | undefined> | null | undefined;
      suffix?: string | null | undefined;
      telephone?: string | null | undefined;
      vat_id?: string | null | undefined;
      extension_attributes?: Array<{
        attribute_code?: string | null | undefined;
        value?: string | null | undefined;
      } | null | undefined> | null | undefined;
      region?: {
        region_code?: string | null | undefined;
        region_id?: number | null | undefined;
        region?: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CmsBlockQueryVariables = Exact<{
  identifiers?: InputMaybe<Array<InputMaybe<Scalars['String']>> | InputMaybe<Scalars['String']>>;
}>;
export declare type CmsBlockQuery = {
  cmsBlocks?: {
    items?: Array<{
      content?: string | null | undefined;
      identifier?: string | null | undefined;
      title?: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CmsPageQueryVariables = Exact<{
  identifier?: InputMaybe<Scalars['String']>;
}>;
export declare type CmsPageQuery = {
  cmsPage?: {
    identifier?: string | null | undefined;
    content?: string | null | undefined;
    title?: string | null | undefined;
    meta_title?: string | null | undefined;
    meta_description?: string | null | undefined;
    content_heading?: string | null | undefined;
  } | null | undefined;
};
export declare type CountriesListQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CountriesListQuery = {
  countries?: Array<{
    id?: string | null | undefined;
    two_letter_abbreviation?: string | null | undefined;
    full_name_locale?: string | null | undefined;
    full_name_english?: string | null | undefined;
  } | null | undefined> | null | undefined;
};
export declare type CountryInformationQueryVariables = Exact<{
  id?: InputMaybe<Scalars['String']>;
}>;
export declare type CountryInformationQuery = {
  country?: {
    id?: string | null | undefined;
    two_letter_abbreviation?: string | null | undefined;
    full_name_locale?: string | null | undefined;
    full_name_english?: string | null | undefined;
    available_regions?: Array<{
      id?: number | null | undefined;
      code?: string | null | undefined;
      name?: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CreateCustomerMutationVariables = Exact<{
  input: CustomerCreateInput;
}>;
export declare type CreateCustomerMutation = {
  createCustomerV2?: {
    customer: {
      date_of_birth?: string | null | undefined;
      default_billing?: string | null | undefined;
      default_shipping?: string | null | undefined;
      email?: string | null | undefined;
      firstname?: string | null | undefined;
      is_subscribed?: boolean | null | undefined;
      lastname?: string | null | undefined;
      middlename?: string | null | undefined;
      prefix?: string | null | undefined;
      suffix?: string | null | undefined;
      taxvat?: string | null | undefined;
      addresses?: Array<{
        city?: string | null | undefined;
        country_code?: CountryCodeEnum | null | undefined;
        default_billing?: boolean | null | undefined;
        default_shipping?: boolean | null | undefined;
        firstname?: string | null | undefined;
        id?: number | null | undefined;
        lastname?: string | null | undefined;
        postcode?: string | null | undefined;
        prefix?: string | null | undefined;
        street?: Array<string | null | undefined> | null | undefined;
        suffix?: string | null | undefined;
        telephone?: string | null | undefined;
        vat_id?: string | null | undefined;
        extension_attributes?: Array<{
          attribute_code?: string | null | undefined;
          value?: string | null | undefined;
        } | null | undefined> | null | undefined;
        region?: {
          region_code?: string | null | undefined;
          region_id?: number | null | undefined;
          region?: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export declare type CreateCustomerAddressMutationVariables = Exact<{
  input: CustomerAddressInput;
}>;
export declare type CreateCustomerAddressMutation = {
  createCustomerAddress?: {
    id?: number | null | undefined;
    country_code?: CountryCodeEnum | null | undefined;
    street?: Array<string | null | undefined> | null | undefined;
    telephone?: string | null | undefined;
    postcode?: string | null | undefined;
    city?: string | null | undefined;
    default_shipping?: boolean | null | undefined;
    default_billing?: boolean | null | undefined;
    vat_id?: string | null | undefined;
    region?: {
      region?: string | null | undefined;
      region_id?: number | null | undefined;
      region_code?: string | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export declare type CreateEmptyCartMutationVariables = Exact<{
  [key: string]: never;
}>;
export declare type CreateEmptyCartMutation = {
  createEmptyCart?: string | null | undefined;
};
export declare type CreateProductReviewMutationVariables = Exact<{
  input: CreateProductReviewInput;
}>;
export declare type CreateProductReviewMutation = {
  createProductReview: {
    review: {
      average_rating: number;
      nickname: string;
      summary: string;
      text: string;
      created_at: string;
      ratings_breakdown: Array<{
        name: string;
        value: string;
      } | null | undefined>;
    };
  };
};
export declare type CurrencyQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CurrencyQuery = {
  currency?: {
    available_currency_codes?: Array<string | null | undefined> | null | undefined;
    base_currency_code?: string | null | undefined;
    base_currency_symbol?: string | null | undefined;
    default_display_currecy_code?: string | null | undefined;
    default_display_currecy_symbol?: string | null | undefined;
    default_display_currency_code?: string | null | undefined;
    default_display_currency_symbol?: string | null | undefined;
    exchange_rates?: Array<{
      currency_to?: string | null | undefined;
      rate?: number | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CustomerQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CustomerQuery = {
  customer?: {
    date_of_birth?: string | null | undefined;
    default_billing?: string | null | undefined;
    default_shipping?: string | null | undefined;
    email?: string | null | undefined;
    firstname?: string | null | undefined;
    is_subscribed?: boolean | null | undefined;
    lastname?: string | null | undefined;
    middlename?: string | null | undefined;
    prefix?: string | null | undefined;
    suffix?: string | null | undefined;
    taxvat?: string | null | undefined;
    addresses?: Array<{
      city?: string | null | undefined;
      country_code?: CountryCodeEnum | null | undefined;
      default_billing?: boolean | null | undefined;
      default_shipping?: boolean | null | undefined;
      firstname?: string | null | undefined;
      id?: number | null | undefined;
      lastname?: string | null | undefined;
      postcode?: string | null | undefined;
      prefix?: string | null | undefined;
      street?: Array<string | null | undefined> | null | undefined;
      suffix?: string | null | undefined;
      telephone?: string | null | undefined;
      vat_id?: string | null | undefined;
      extension_attributes?: Array<{
        attribute_code?: string | null | undefined;
        value?: string | null | undefined;
      } | null | undefined> | null | undefined;
      region?: {
        region_code?: string | null | undefined;
        region_id?: number | null | undefined;
        region?: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type CustomerCartQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CustomerCartQuery = {
  customerCart: {
    id: string;
    email?: string | null | undefined;
    is_virtual: boolean;
    total_quantity: number;
    applied_coupons?: Array<{
      code: string;
      free_shipping_coupon: boolean;
    } | null | undefined> | null | undefined;
    prices?: {
      subtotal_excluding_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      subtotal_including_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      applied_taxes?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      discounts?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      grand_total?: {
        value?: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
    items?: Array<{
      engraving?: Array<{
        sku: string | null | undefined;
        template: Array<{
          entity_id: number | null | undefined
          position: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
          } | null | undefined>
        } | null | undefined>
        engraving_text: string | null | undefined;
        font: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
            size: string | null | undefined;
        } | null | undefined>;
        color: Array<{
            label: string | null | undefined;
            value: string | null | undefined;
        } | null | undefined>;
      } | null | undefined>;
      uid: string;
      quantity: number;
      bundle_options: Array<{
        uid: string;
        label: string;
        type: string;
        values: Array<{
          id: number;
          label: string;
          price: number;
          quantity: number;
        } | null | undefined>;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      configurable_options: Array<{
        configurable_product_option_uid: string;
        option_label: string;
        configurable_product_option_value_uid: string;
        value_label: string;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    shipping_addresses: Array<{
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
      selected_shipping_method?: {
        carrier_code: string;
        carrier_title: string;
        method_code: string;
        method_title: string;
        amount: {
          value?: number | null | undefined;
          currency?: CurrencyEnum | null | undefined;
        };
      } | null | undefined;
    } | null | undefined>;
    billing_address?: {
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
    } | null | undefined;
  };
};
export declare type CustomerOrdersQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  filter?: InputMaybe<CustomerOrdersFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;
export declare type CustomerOrdersQuery = {
  customer?: {
    orders?: {
      total_count?: number | null | undefined;
      items: Array<{
        order_number: string;
        id: string;
        created_at?: string | null | undefined;
        grand_total?: number | null | undefined;
        status: string;
        shipping_method?: string | null | undefined;
        total?: {
          discounts?: Array<{
            label: string;
            amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          base_grand_total: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          shipping_handling?: {
            amount_excluding_tax?: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            } | null | undefined;
            amount_including_tax?: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            } | null | undefined;
            discounts?: Array<{
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            taxes?: Array<{
              rate: number;
              title: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            total_amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          subtotal: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          taxes?: Array<{
            rate: number;
            title: string;
            amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          total_shipping: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          total_tax: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        comments?: Array<{
          message: string;
          timestamp: string;
        } | null | undefined> | null | undefined;
        invoices: Array<{
          id: string;
          number: string;
          comments?: Array<{
            message: string;
            timestamp: string;
          } | null | undefined> | null | undefined;
          items?: Array<{
            id: string;
            product_name?: string | null | undefined;
            product_sku: string;
            quantity_invoiced?: number | null | undefined;
            discounts?: Array<{
              label: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            product_sale_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | {
            id: string;
            product_name?: string | null | undefined;
            product_sku: string;
            quantity_invoiced?: number | null | undefined;
            discounts?: Array<{
              label: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            product_sale_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | {
            id: string;
            product_name?: string | null | undefined;
            product_sku: string;
            quantity_invoiced?: number | null | undefined;
            discounts?: Array<{
              label: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            product_sale_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          total?: {
            discounts?: Array<{
              label: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            base_grand_total: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            shipping_handling?: {
              amount_excluding_tax?: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              } | null | undefined;
              amount_including_tax?: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              } | null | undefined;
              discounts?: Array<{
                amount: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined> | null | undefined;
              taxes?: Array<{
                rate: number;
                title: string;
                amount: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined> | null | undefined;
              total_amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            subtotal: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            taxes?: Array<{
              rate: number;
              title: string;
              amount: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined> | null | undefined;
            total_shipping: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            total_tax: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
        } | null | undefined>;
        items?: Array<{
          id: string;
          product_name?: string | null | undefined;
          product_sku: string;
          product_type?: string | null | undefined;
          product_url_key?: string | null | undefined;
          quantity_canceled?: number | null | undefined;
          quantity_invoiced?: number | null | undefined;
          quantity_ordered?: number | null | undefined;
          quantity_refunded?: number | null | undefined;
          quantity_returned?: number | null | undefined;
          quantity_shipped?: number | null | undefined;
          status?: string | null | undefined;
          discounts?: Array<{
            label: string;
            amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          entered_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
          product_sale_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          selected_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
        } | {
          id: string;
          product_name?: string | null | undefined;
          product_sku: string;
          product_type?: string | null | undefined;
          product_url_key?: string | null | undefined;
          quantity_canceled?: number | null | undefined;
          quantity_invoiced?: number | null | undefined;
          quantity_ordered?: number | null | undefined;
          quantity_refunded?: number | null | undefined;
          quantity_returned?: number | null | undefined;
          quantity_shipped?: number | null | undefined;
          status?: string | null | undefined;
          discounts?: Array<{
            label: string;
            amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          entered_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
          product_sale_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          selected_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
        } | {
          id: string;
          product_name?: string | null | undefined;
          product_sku: string;
          product_type?: string | null | undefined;
          product_url_key?: string | null | undefined;
          quantity_canceled?: number | null | undefined;
          quantity_invoiced?: number | null | undefined;
          quantity_ordered?: number | null | undefined;
          quantity_refunded?: number | null | undefined;
          quantity_returned?: number | null | undefined;
          quantity_shipped?: number | null | undefined;
          status?: string | null | undefined;
          discounts?: Array<{
            label: string;
            amount: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
          entered_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
          product_sale_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          selected_options?: Array<{
            label: string;
            value: string;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        payment_methods?: Array<{
          name: string;
          type: string;
          additional_data?: Array<{
            name?: string | null | undefined;
            value?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        shipments?: Array<{
          id: string;
          number: string;
          comments?: Array<{
            message: string;
            timestamp: string;
          } | null | undefined> | null | undefined;
          tracking?: Array<{
            carrier: string;
            number?: string | null | undefined;
            title: string;
          } | null | undefined> | null | undefined;
          items?: Array<{
            id: string;
            product_name?: string | null | undefined;
            product_sku: string;
            quantity_shipped: number;
            product_sale_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | {
            id: string;
            product_name?: string | null | undefined;
            product_sku: string;
            quantity_shipped: number;
            product_sale_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        shipping_address?: {
          city: string;
          country_code?: CountryCodeEnum | null | undefined;
          firstname: string;
          lastname: string;
          postcode?: string | null | undefined;
          prefix?: string | null | undefined;
          region?: string | null | undefined;
          street: Array<string | null | undefined>;
          suffix?: string | null | undefined;
          telephone: string;
        } | null | undefined;
      } | null | undefined>;
      page_info?: {
        current_page?: number | null | undefined;
        total_pages?: number | null | undefined;
        page_size?: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export declare type CustomerProductReviewQueryVariables = Exact<{
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
}>;
export declare type CustomerProductReviewQuery = {
  customer?: {
    reviews: {
      items: Array<{
        average_rating: number;
        nickname: string;
        summary: string;
        text: string;
        created_at: string;
        ratings_breakdown: Array<{
          name: string;
          value: string;
        } | null | undefined>;
        product: {
          name?: string | null | undefined;
          uid: string;
        } | {
          name?: string | null | undefined;
          uid: string;
        } | {
          name?: string | null | undefined;
          uid: string;
        } | {
          name?: string | null | undefined;
          uid: string;
        } | {
          name?: string | null | undefined;
          uid: string;
        } | {
          name?: string | null | undefined;
          uid: string;
        };
      } | null | undefined>;
      page_info: {
        current_page?: number | null | undefined;
        page_size?: number | null | undefined;
        total_pages?: number | null | undefined;
      };
    };
  } | null | undefined;
};
export declare type DeleteCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int'];
}>;
export declare type DeleteCustomerAddressMutation = {
  deleteCustomerAddress?: boolean | null | undefined;
};
export declare type GenerateCustomerTokenMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;
export declare type GenerateCustomerTokenMutation = {
  generateCustomerToken?: {
    token?: string | null | undefined;
  } | null | undefined;
};
export declare type CustomerAvailablePaymentMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CustomerAvailablePaymentMethodsQuery = {
  customerCart: {
    available_payment_methods?: Array<{
      code: string;
      title: string;
    } | null | undefined> | null | undefined;
  };
};
export declare type CustomerAvailableShippingMethodsQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type CustomerAvailableShippingMethodsQuery = {
  customerCart: {
    shipping_addresses: Array<{
      available_shipping_methods?: Array<{
        available: boolean;
        carrier_code: string;
        carrier_title: string;
        error_message?: string | null | undefined;
        method_code?: string | null | undefined;
        method_title?: string | null | undefined;
        amount: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
        price_excl_tax: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
        price_incl_tax: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
    } | null | undefined>;
  };
};
export declare type GuestAvailablePaymentMethodsQueryVariables = Exact<{
  cartId: Scalars['String'];
}>;
export declare type GuestAvailablePaymentMethodsQuery = {
  cart?: {
    available_payment_methods?: Array<{
      code: string;
      title: string;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type GuestAvailableShippingMethodsQueryVariables = Exact<{
  cart_id: Scalars['String'];
}>;
export declare type GuestAvailableShippingMethodsQuery = {
  cart?: {
    shipping_addresses: Array<{
      available_shipping_methods?: Array<{
        available: boolean;
        carrier_code: string;
        carrier_title: string;
        error_message?: string | null | undefined;
        method_code?: string | null | undefined;
        method_title?: string | null | undefined;
        amount: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
        price_excl_tax: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
        price_incl_tax: {
          currency?: CurrencyEnum | null | undefined;
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
export declare type GetCustomerAddressesQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type GetCustomerAddressesQuery = {
  customer?: {
    addresses?: Array<{
      city?: string | null | undefined;
      country_code?: CountryCodeEnum | null | undefined;
      default_billing?: boolean | null | undefined;
      default_shipping?: boolean | null | undefined;
      firstname?: string | null | undefined;
      id?: number | null | undefined;
      lastname?: string | null | undefined;
      postcode?: string | null | undefined;
      prefix?: string | null | undefined;
      street?: Array<string | null | undefined> | null | undefined;
      suffix?: string | null | undefined;
      telephone?: string | null | undefined;
      vat_id?: string | null | undefined;
      extension_attributes?: Array<{
        attribute_code?: string | null | undefined;
        value?: string | null | undefined;
      } | null | undefined> | null | undefined;
      region?: {
        region_code?: string | null | undefined;
        region_id?: number | null | undefined;
        region?: string | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type MergeCartsMutationVariables = Exact<{
  sourceCartId: Scalars['String'];
  destinationCartId: Scalars['String'];
}>;
export declare type MergeCartsMutation = {
  mergeCarts: {
    id: string;
    email?: string | null | undefined;
    is_virtual: boolean;
    total_quantity: number;
    applied_coupons?: Array<{
      code: string;
    } | null | undefined> | null | undefined;
    prices?: {
      subtotal_excluding_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      subtotal_including_tax?: {
        value?: number | null | undefined;
      } | null | undefined;
      applied_taxes?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      discounts?: Array<{
        label: string;
        amount: {
          value?: number | null | undefined;
        };
      } | null | undefined> | null | undefined;
      grand_total?: {
        value?: number | null | undefined;
      } | null | undefined;
    } | null | undefined;
    items?: Array<{
      uid: string;
      quantity: number;
      bundle_options: Array<{
        uid: string;
        label: string;
        type: string;
        values: Array<{
          id: number;
          label: string;
          price: number;
          quantity: number;
        } | null | undefined>;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      configurable_options: Array<{
        configurable_product_option_uid: string;
        option_label: string;
        configurable_product_option_value_uid: string;
        value_label: string;
      } | null | undefined>;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | {
      uid: string;
      quantity: number;
      product: {
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      };
      prices?: {
        row_total: {
          value?: number | null | undefined;
        };
        row_total_including_tax: {
          value?: number | null | undefined;
        };
        total_item_discount?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined> | null | undefined;
    shipping_addresses: Array<{
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
      selected_shipping_method?: {
        carrier_code: string;
        carrier_title: string;
        method_code: string;
        method_title: string;
        amount: {
          value?: number | null | undefined;
          currency?: CurrencyEnum | null | undefined;
        };
      } | null | undefined;
    } | null | undefined>;
    billing_address?: {
      firstname: string;
      lastname: string;
      street: Array<string | null | undefined>;
      city: string;
      company?: string | null | undefined;
      postcode?: string | null | undefined;
      telephone: string;
      region?: {
        code?: string | null | undefined;
        region_id?: number | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      country: {
        code: string;
        label: string;
      };
    } | null | undefined;
  };
};
export declare type PlaceOrderMutationVariables = Exact<{
  input?: InputMaybe<PlaceOrderInput>;
}>;
export declare type PlaceOrderMutation = {
  placeOrder?: {
    order: {
      order_number: string;
    };
  } | null | undefined;
};
export declare type ProductDetailsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
  configurations?: InputMaybe<Array<Scalars['ID']> | Scalars['ID']>;
}>;
export declare type ProductDetailsQuery = {
  products?: {
    items?: Array<{
      id?: number | null,
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      url_suffix?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      options?: Array<{
        uid: string | null | undefined;
        title: string | null | undefined;
      } | null | undefined>;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      items?: Array<{
        position?: number | null | undefined;
        required?: boolean | null | undefined;
        sku?: string | null | undefined;
        title?: string | null | undefined;
        type?: string | null | undefined;
        uid?: string | null | undefined;
        options?: Array<{
          can_change_quantity?: boolean | null | undefined;
          is_default?: boolean | null | undefined;
          position?: number | null | undefined;
          uid: string;
          quantity?: number | null | undefined;
          product?: {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | {
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            price_excluded_tax?: string | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      url_suffix?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      configurable_options?: Array<{
        attribute_code?: string | null | undefined;
        attribute_uid: string;
        label?: string | null | undefined;
        position?: number | null | undefined;
        uid: string;
        use_default?: boolean | null | undefined;
        values?: Array<{
          label?: string | null | undefined;
          uid?: string | null | undefined;
          swatch_data?: {
            value?: string | null | undefined;
          } | {
            value?: string | null | undefined;
          } | {
            value?: string | null | undefined;
          } | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      configurable_product_options_selection?: {
        options_available_for_selection?: Array<{
          attribute_code: string;
          option_value_uids: Array<string | null | undefined>;
        } | null | undefined> | null | undefined;
        media_gallery?: Array<{
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
          position?: number | null | undefined;
          url?: string | null | undefined;
        } | {
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
          position?: number | null | undefined;
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        variant?: {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
        } | null | undefined;
      } | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      url_suffix?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      downloadable_product_samples?: Array<{
        sample_url?: string | null | undefined;
        title?: string | null | undefined;
      } | null | undefined> | null | undefined;
      downloadable_product_links?: Array<{
        id?: number | null | undefined;
        price?: number | null | undefined;
        title?: string | null | undefined;
        uid: string;
      } | null | undefined> | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      items?: Array<{
        position?: number | null | undefined;
        qty?: number | null | undefined;
        product?: {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | {
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      url_suffix?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | {
      gift_message_available?: string | null | undefined;
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      url_key?: string | null | undefined;
      url_suffix?: string | null | undefined;
      rating_summary: number;
      review_count: number;
      meta_description?: string | null | undefined;
      meta_keyword?: string | null | undefined;
      meta_title?: string | null | undefined;
      options_container?: string | null | undefined;
      special_to_date?: string | null | undefined;
      product_links?: Array<{
        link_type?: string | null | undefined;
        linked_product_sku?: string | null | undefined;
        linked_product_type?: string | null | undefined;
        position?: number | null | undefined;
        sku?: string | null | undefined;
      } | null | undefined> | null | undefined;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
      small_image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      image?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      media_gallery?: Array<{
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined> | null | undefined;
      description?: {
        html: string;
      } | null | undefined;
      short_description?: {
        html: string;
      } | null | undefined;
      plp_description?: string | null | undefined;
      price_excluded_tax?: string | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type ProductReviewQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}>;
export declare type ProductReviewQuery = {
  products?: {
    items?: Array<{
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | {
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | {
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | {
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | {
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | {
      review_count: number;
      reviews: {
        items: Array<{
          average_rating: number;
          nickname: string;
          summary: string;
          text: string;
          created_at: string;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
          product: {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          } | {
            name?: string | null | undefined;
            uid: string;
          };
        } | null | undefined>;
        page_info: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        };
      };
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type ProductReviewRatingsMetadataQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type ProductReviewRatingsMetadataQuery = {
  productReviewRatingsMetadata: {
    items: Array<{
      id: string;
      name: string;
      values: Array<{
        value_id: string;
        value: string;
      } | null | undefined>;
    } | null | undefined>;
  };
};
export declare type ProductsListQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}>;
export declare type ProductsListQuery = {
  products?: {
    total_count?: number | null | undefined;
    aggregations?: Array<{
      attribute_code: string;
      label?: string | null | undefined;
      options?: Array<{
        label?: string | null | undefined;
        value: string;
        count?: number | null | undefined;
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
    items?: Array<{
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | {
      uid: string;
      sku?: string | null | undefined;
      name?: string | null | undefined;
      stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
      only_x_left_in_stock?: number | null | undefined;
      rating_summary: number;
      url_key?: string | null | undefined;
      review_count: number;
      thumbnail?: {
        url?: string | null | undefined;
        position?: number | null | undefined;
        disabled?: boolean | null | undefined;
        label?: string | null | undefined;
      } | null | undefined;
      url_rewrites?: Array<{
        url?: string | null | undefined;
      } | null | undefined> | null | undefined;
      price_range: {
        maximum_price?: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined;
        minimum_price: {
          final_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          regular_price: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        };
      };
      price_excluded_tax?: string | null | undefined;
      categories?: Array<{
        uid: string;
        name?: string | null | undefined;
        url_suffix?: string | null | undefined;
        url_path?: string | null | undefined;
        breadcrumbs?: Array<{
          category_name?: string | null | undefined;
          category_url_path?: string | null | undefined;
        } | null | undefined> | null | undefined;
      } | null | undefined> | null | undefined;
      reviews: {
        items: Array<{
          average_rating: number;
          ratings_breakdown: Array<{
            name: string;
            value: string;
          } | null | undefined>;
        } | null | undefined>;
      };
    } | null | undefined> | null | undefined;
    page_info?: {
      current_page?: number | null | undefined;
      page_size?: number | null | undefined;
      total_pages?: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export declare type RelatedProductQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}>;
export declare type RelatedProductQuery = {
  products?: {
    items?: Array<{
      uid: string;
      revamp_related_products?: Array<{
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};

export declare type RemoveCouponFromCartMutationVariables = Exact<{
  input?: InputMaybe<RemoveCouponFromCartInput>;
}>;
export declare type RemoveCouponFromCartMutation = {
  removeCouponFromCart?: {
    cart?: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export declare type RemoveItemFromCartMutationVariables = Exact<{
  input?: InputMaybe<RemoveItemFromCartInput>;
}>;
export declare type RemoveItemFromCartMutation = {
  removeItemFromCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type RemoveProductsFromWishlistMutationVariables = Exact<{
  id: Scalars['ID'];
  items: Array<Scalars['ID']> | Scalars['ID'];
}>;
export declare type RemoveProductsFromWishlistMutation = {
  removeProductsFromWishlist?: {
    wishlist: {
      id?: string | null | undefined;
      items_count?: number | null | undefined;
      sharing_code?: string | null | undefined;
      items_v2?: {
        items: Array<{
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | null | undefined>;
        page_info?: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type RequestPasswordResetEmailMutationVariables = Exact<{
  email: Scalars['String'];
  recaptchaToken?: Scalars['String'];
}>;
export declare type RequestPasswordResetEmailMutation = {
  requestPasswordResetEmail?: boolean | null | undefined;
};
export declare type ResetPasswordMutationVariables = Exact<{
  email: Scalars['String'];
  newPassword: Scalars['String'];
  resetPasswordToken: Scalars['String'];
  recaptchaToken?: Scalars['String'];
}>;
export declare type ResetPasswordMutation = {
  resetPassword?: boolean | null | undefined;
};
export declare type RevokeCustomerTokenMutationVariables = Exact<{
  [key: string]: never;
}>;
export declare type RevokeCustomerTokenMutation = {
  revokeCustomerToken?: {
    result: boolean;
  } | null | undefined;
};
export declare type SetBillingAddressOnCartMutationVariables = Exact<{
  input?: InputMaybe<SetBillingAddressOnCartInput>;
}>;
export declare type SetBillingAddressOnCartMutation = {
  setBillingAddressOnCart?: {
    cart: {
      billing_address?: {
        firstname: string;
        lastname: string;
        company?: string | null | undefined;
        street: Array<string | null | undefined>;
        city: string;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type SetGuestEmailOnCartMutationVariables = Exact<{
  input?: InputMaybe<SetGuestEmailOnCartInput>;
}>;
export declare type SetGuestEmailOnCartMutation = {
  setGuestEmailOnCart?: {
    cart: {
      email?: string | null | undefined;
    };
  } | null | undefined;
};
export declare type SetPaymentMethodOnCartMutationVariables = Exact<{
  input?: InputMaybe<SetPaymentMethodOnCartInput>;
}>;
export declare type SetPaymentMethodOnCartMutation = {
  setPaymentMethodOnCart?: {
    cart: {
      available_payment_methods?: Array<{
        code: string;
        title: string;
      } | null | undefined> | null | undefined;
      selected_payment_method?: {
        code: string;
        title: string;
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type SetShippingAddressesOnCartMutationVariables = Exact<{
  input?: InputMaybe<SetShippingAddressesOnCartInput>;
}>;
export declare type SetShippingAddressesOnCartMutation = {
  setShippingAddressesOnCart?: {
    cart: {
      shipping_addresses: Array<{
        city: string;
        company?: string | null | undefined;
        firstname: string;
        lastname: string;
        firstnamekana: string;
        lastnamekana: string;
        postcode?: string | null | undefined;
        street: Array<string | null | undefined>;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          label?: string | null | undefined;
          region_id?: number | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        available_shipping_methods?: Array<{
          available: boolean;
          carrier_code: string;
          carrier_title: string;
          error_message?: string | null | undefined;
          method_code?: string | null | undefined;
          method_title?: string | null | undefined;
          amount: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          price_excl_tax: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
          price_incl_tax: {
            currency?: CurrencyEnum | null | undefined;
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
      } | null | undefined>;
    };
  } | null | undefined;
};
export declare type SetShippingMethodsOnCartMutationVariables = Exact<{
  input?: InputMaybe<SetShippingMethodsOnCartInput>;
}>;
export declare type SetShippingMethodsOnCartMutation = {
  setShippingMethodsOnCart?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type StoreConfigQueryVariables = Exact<{
  [key: string]: never;
}>;
export declare type StoreConfigQuery = {
  storeConfig?: {
    allow_guests_to_write_product_reviews?: string | null | undefined;
    allow_items?: string | null | undefined;
    allow_order?: string | null | undefined;
    base_currency_code?: string | null | undefined;
    catalog_default_sort_by?: string | null | undefined;
    category_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    cms_home_page?: string | null | undefined;
    cms_no_cookies?: string | null | undefined;
    cms_no_route?: string | null | undefined;
    configurable_thumbnail_source?: string | null | undefined;
    copyright?: string | null | undefined;
    default_description?: string | null | undefined;
    default_display_currency_code?: string | null | undefined;
    default_keywords?: string | null | undefined;
    default_title?: string | null | undefined;
    grid_per_page?: number | null | undefined;
    grid_per_page_values?: string | null | undefined;
    head_shortcut_icon?: string | null | undefined;
    header_logo_src?: string | null | undefined;
    is_default_store?: boolean | null | undefined;
    is_default_store_group?: boolean | null | undefined;
    list_mode?: string | null | undefined;
    list_per_page?: number | null | undefined;
    list_per_page_values?: string | null | undefined;
    locale?: string | null | undefined;
    logo_alt?: string | null | undefined;
    logo_height?: number | null | undefined;
    logo_width?: number | null | undefined;
    magento_wishlist_general_is_enabled?: string | null | undefined;
    minimum_password_length?: string | null | undefined;
    newsletter_subscribe_widget_enabled?: boolean | null | undefined;
    no_route?: string | null | undefined;
    product_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    product_reviews_enabled?: string | null | undefined;
    required_character_classes_number?: string | null | undefined;
    root_category_uid?: string | null | undefined;
    sales_fixed_product_tax_display_setting?: FixedProductTaxDisplaySettings | null | undefined;
    store_code?: string | null | undefined;
    store_group_code?: string | null | undefined;
    store_group_name?: string | null | undefined;
    store_name?: string | null | undefined;
    store_sort_order?: number | null | undefined;
    timezone?: string | null | undefined;
    title_prefix?: string | null | undefined;
    title_separator?: string | null | undefined;
    title_suffix?: string | null | undefined;
    use_store_in_url?: boolean | null | undefined;
    website_code?: string | null | undefined;
    website_name?: string | null | undefined;
    weight_unit?: string | null | undefined;
    welcome?: string | null | undefined;
  } | null | undefined;
};
export declare type SubscribeEmailToNewsletterMutationVariables = Exact<{
  email: Scalars['String'];
}>;
export declare type SubscribeEmailToNewsletterMutation = {
  subscribeEmailToNewsletter?: {
    status?: SubscriptionStatusesEnum | null | undefined;
  } | null | undefined;
};
export declare type UpdateCartItemsMutationVariables = Exact<{
  input?: InputMaybe<UpdateCartItemsInput>;
}>;
export declare type UpdateCartItemsMutation = {
  updateCartItems?: {
    cart: {
      id: string;
      email?: string | null | undefined;
      is_virtual: boolean;
      total_quantity: number;
      applied_coupons?: Array<{
        code: string;
      } | null | undefined> | null | undefined;
      prices?: {
        subtotal_excluding_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        subtotal_including_tax?: {
          value?: number | null | undefined;
        } | null | undefined;
        applied_taxes?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        discounts?: Array<{
          label: string;
          amount: {
            value?: number | null | undefined;
          };
        } | null | undefined> | null | undefined;
        grand_total?: {
          value?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
      items?: Array<{
        uid: string;
        quantity: number;
        bundle_options: Array<{
          uid: string;
          label: string;
          type: string;
          values: Array<{
            id: number;
            label: string;
            price: number;
            quantity: number;
          } | null | undefined>;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        configurable_options: Array<{
          configurable_product_option_uid: string;
          option_label: string;
          configurable_product_option_value_uid: string;
          value_label: string;
        } | null | undefined>;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | {
        uid: string;
        quantity: number;
        product: {
          __typename: 'BundleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'ConfigurableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'DownloadableProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'GroupedProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'SimpleProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        } | {
          __typename: 'VirtualProduct';
          uid: string;
          sku?: string | null | undefined;
          name?: string | null | undefined;
          stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
          only_x_left_in_stock?: number | null | undefined;
          rating_summary: number;
          url_key?: string | null | undefined;
          review_count: number;
          thumbnail?: {
            url?: string | null | undefined;
            position?: number | null | undefined;
            disabled?: boolean | null | undefined;
            label?: string | null | undefined;
          } | null | undefined;
          url_rewrites?: Array<{
            url?: string | null | undefined;
          } | null | undefined> | null | undefined;
          price_range: {
            maximum_price?: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            } | null | undefined;
            minimum_price: {
              final_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
              regular_price: {
                currency?: CurrencyEnum | null | undefined;
                value?: number | null | undefined;
              };
            };
          };
          categories?: Array<{
            uid: string;
            name?: string | null | undefined;
            url_suffix?: string | null | undefined;
            url_path?: string | null | undefined;
            breadcrumbs?: Array<{
              category_name?: string | null | undefined;
              category_url_path?: string | null | undefined;
            } | null | undefined> | null | undefined;
          } | null | undefined> | null | undefined;
          reviews: {
            items: Array<{
              average_rating: number;
              ratings_breakdown: Array<{
                name: string;
                value: string;
              } | null | undefined>;
            } | null | undefined>;
          };
        };
        prices?: {
          row_total: {
            value?: number | null | undefined;
          };
          row_total_including_tax: {
            value?: number | null | undefined;
          };
          total_item_discount?: {
            value?: number | null | undefined;
          } | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
      shipping_addresses: Array<{
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
        selected_shipping_method?: {
          carrier_code: string;
          carrier_title: string;
          method_code: string;
          method_title: string;
          amount: {
            value?: number | null | undefined;
            currency?: CurrencyEnum | null | undefined;
          };
        } | null | undefined;
      } | null | undefined>;
      billing_address?: {
        firstname: string;
        lastname: string;
        street: Array<string | null | undefined>;
        city: string;
        company?: string | null | undefined;
        postcode?: string | null | undefined;
        telephone: string;
        region?: {
          code?: string | null | undefined;
          region_id?: number | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        country: {
          code: string;
          label: string;
        };
      } | null | undefined;
    };
  } | null | undefined;
};
export declare type UpdateCustomerMutationVariables = Exact<{
  input: CustomerUpdateInput;
}>;
export declare type UpdateCustomerMutation = {
  updateCustomerV2?: {
    customer: {
      date_of_birth?: string | null | undefined;
      default_billing?: string | null | undefined;
      default_shipping?: string | null | undefined;
      email?: string | null | undefined;
      firstname?: string | null | undefined;
      is_subscribed?: boolean | null | undefined;
      lastname?: string | null | undefined;
      middlename?: string | null | undefined;
      prefix?: string | null | undefined;
      suffix?: string | null | undefined;
      taxvat?: string | null | undefined;
      addresses?: Array<{
        city?: string | null | undefined;
        country_code?: CountryCodeEnum | null | undefined;
        default_billing?: boolean | null | undefined;
        default_shipping?: boolean | null | undefined;
        firstname?: string | null | undefined;
        id?: number | null | undefined;
        lastname?: string | null | undefined;
        postcode?: string | null | undefined;
        prefix?: string | null | undefined;
        street?: Array<string | null | undefined> | null | undefined;
        suffix?: string | null | undefined;
        telephone?: string | null | undefined;
        vat_id?: string | null | undefined;
        extension_attributes?: Array<{
          attribute_code?: string | null | undefined;
          value?: string | null | undefined;
        } | null | undefined> | null | undefined;
        region?: {
          region_code?: string | null | undefined;
          region_id?: number | null | undefined;
          region?: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export declare type UpdateCustomerAddressMutationVariables = Exact<{
  id: Scalars['Int'];
  input?: InputMaybe<CustomerAddressInput>;
}>;
export declare type UpdateCustomerAddressMutation = {
  updateCustomerAddress?: {
    id?: number | null | undefined;
    city?: string | null | undefined;
    company?: string | null | undefined;
    country_code?: CountryCodeEnum | null | undefined;
    default_billing?: boolean | null | undefined;
    default_shipping?: boolean | null | undefined;
    fax?: string | null | undefined;
    firstname?: string | null | undefined;
    lastname?: string | null | undefined;
    middlename?: string | null | undefined;
    postcode?: string | null | undefined;
    prefix?: string | null | undefined;
    street?: Array<string | null | undefined> | null | undefined;
    suffix?: string | null | undefined;
    telephone?: string | null | undefined;
    vat_id?: string | null | undefined;
    extension_attributes?: Array<{
      attribute_code?: string | null | undefined;
      value?: string | null | undefined;
    } | null | undefined> | null | undefined;
    region?: {
      region?: string | null | undefined;
      region_code?: string | null | undefined;
      region_id?: number | null | undefined;
    } | null | undefined;
  } | null | undefined;
};
export declare type UpdateCustomerEmailMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  recaptchaToken?: Scalars['String'];
}>;
export declare type UpdateCustomerEmailMutation = {
  updateCustomerEmail?: {
    customer: {
      date_of_birth?: string | null | undefined;
      default_billing?: string | null | undefined;
      default_shipping?: string | null | undefined;
      email?: string | null | undefined;
      firstname?: string | null | undefined;
      is_subscribed?: boolean | null | undefined;
      lastname?: string | null | undefined;
      middlename?: string | null | undefined;
      prefix?: string | null | undefined;
      suffix?: string | null | undefined;
      taxvat?: string | null | undefined;
      addresses?: Array<{
        city?: string | null | undefined;
        country_code?: CountryCodeEnum | null | undefined;
        default_billing?: boolean | null | undefined;
        default_shipping?: boolean | null | undefined;
        firstname?: string | null | undefined;
        id?: number | null | undefined;
        lastname?: string | null | undefined;
        postcode?: string | null | undefined;
        prefix?: string | null | undefined;
        street?: Array<string | null | undefined> | null | undefined;
        suffix?: string | null | undefined;
        telephone?: string | null | undefined;
        vat_id?: string | null | undefined;
        extension_attributes?: Array<{
          attribute_code?: string | null | undefined;
          value?: string | null | undefined;
        } | null | undefined> | null | undefined;
        region?: {
          region_code?: string | null | undefined;
          region_id?: number | null | undefined;
          region?: string | null | undefined;
        } | null | undefined;
      } | null | undefined> | null | undefined;
    };
  } | null | undefined;
};
export declare type UpsellProductsQueryVariables = Exact<{
  search?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ProductAttributeFilterInput>;
  pageSize?: InputMaybe<Scalars['Int']>;
  currentPage?: InputMaybe<Scalars['Int']>;
  sort?: InputMaybe<ProductAttributeSortInput>;
}>;
export declare type UpsellProductsQuery = {
  products?: {
    items?: Array<{
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | {
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | {
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | {
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | {
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | {
      uid: string;
      upsell_products?: Array<{
        __typename: 'BundleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'ConfigurableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'DownloadableProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'GroupedProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'SimpleProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | {
        __typename: 'VirtualProduct';
        uid: string;
        sku?: string | null | undefined;
        name?: string | null | undefined;
        stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
        only_x_left_in_stock?: number | null | undefined;
        rating_summary: number;
        url_key?: string | null | undefined;
        review_count: number;
        thumbnail?: {
          url?: string | null | undefined;
          position?: number | null | undefined;
          disabled?: boolean | null | undefined;
          label?: string | null | undefined;
        } | null | undefined;
        url_rewrites?: Array<{
          url?: string | null | undefined;
        } | null | undefined> | null | undefined;
        price_range: {
          maximum_price?: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          } | null | undefined;
          minimum_price: {
            final_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
            regular_price: {
              currency?: CurrencyEnum | null | undefined;
              value?: number | null | undefined;
            };
          };
        };
        categories?: Array<{
          uid: string;
          name?: string | null | undefined;
          url_suffix?: string | null | undefined;
          url_path?: string | null | undefined;
          breadcrumbs?: Array<{
            category_name?: string | null | undefined;
            category_url_path?: string | null | undefined;
          } | null | undefined> | null | undefined;
        } | null | undefined> | null | undefined;
        reviews: {
          items: Array<{
            average_rating: number;
            ratings_breakdown: Array<{
              name: string;
              value: string;
            } | null | undefined>;
          } | null | undefined>;
        };
      } | null | undefined> | null | undefined;
    } | null | undefined> | null | undefined;
  } | null | undefined;
};
export declare type UrlResolverQueryVariables = Exact<{
  url: Scalars['String'];
}>;
export declare type UrlResolverQuery = {
  urlResolver?: {
    id?: number | null | undefined;
    redirectCode?: number | null | undefined;
    relative_url?: string | null | undefined;
    type?: UrlRewriteEntityTypeEnum | null | undefined;
    entity_uid?: string | null | undefined;
  } | null | undefined;
};
export declare type WishlistQueryVariables = Exact<{
  currentPage?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}>;
export declare type WishlistQuery = {
  customer?: {
    wishlists: Array<{
      id?: string | null | undefined;
      items_count?: number | null | undefined;
      sharing_code?: string | null | undefined;
      items_v2?: {
        items: Array<{
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | {
          id: string;
          quantity: number;
          description?: string | null | undefined;
          added_at: string;
          product?: {
            __typename: 'BundleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            items?: Array<{
              sku?: string | null | undefined;
              title?: string | null | undefined;
              options?: Array<{
                uid: string;
                quantity?: number | null | undefined;
                product?: {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | {
                  uid: string;
                  sku?: string | null | undefined;
                  name?: string | null | undefined;
                  price_range: {
                    maximum_price?: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    } | null | undefined;
                    minimum_price: {
                      final_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                      regular_price: {
                        currency?: CurrencyEnum | null | undefined;
                        value?: number | null | undefined;
                      };
                    };
                  };
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'ConfigurableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            configurable_options?: Array<{
              attribute_code?: string | null | undefined;
              attribute_uid: string;
              label?: string | null | undefined;
              position?: number | null | undefined;
              uid: string;
              use_default?: boolean | null | undefined;
              values?: Array<{
                label?: string | null | undefined;
                uid?: string | null | undefined;
                swatch_data?: {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | {
                  value?: string | null | undefined;
                } | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'DownloadableProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'GroupedProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'SimpleProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | {
            __typename: 'VirtualProduct';
            uid: string;
            sku?: string | null | undefined;
            name?: string | null | undefined;
            stock_status?: ProductStockStatus | null | undefined;
          is_salable?: boolean | null | undefined;
            only_x_left_in_stock?: number | null | undefined;
            rating_summary: number;
            url_key?: string | null | undefined;
            review_count: number;
            categories?: Array<{
              uid: string;
              name?: string | null | undefined;
              url_suffix?: string | null | undefined;
              url_path?: string | null | undefined;
              breadcrumbs?: Array<{
                category_name?: string | null | undefined;
                category_url_path?: string | null | undefined;
              } | null | undefined> | null | undefined;
            } | null | undefined> | null | undefined;
            price_range: {
              maximum_price?: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              } | null | undefined;
              minimum_price: {
                final_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
                regular_price: {
                  currency?: CurrencyEnum | null | undefined;
                  value?: number | null | undefined;
                };
              };
            };
            thumbnail?: {
              url?: string | null | undefined;
              position?: number | null | undefined;
              disabled?: boolean | null | undefined;
              label?: string | null | undefined;
            } | null | undefined;
            url_rewrites?: Array<{
              url?: string | null | undefined;
            } | null | undefined> | null | undefined;
            reviews: {
              items: Array<{
                average_rating: number;
                ratings_breakdown: Array<{
                  name: string;
                  value: string;
                } | null | undefined>;
              } | null | undefined>;
            };
          } | null | undefined;
        } | null | undefined>;
        page_info?: {
          current_page?: number | null | undefined;
          page_size?: number | null | undefined;
          total_pages?: number | null | undefined;
        } | null | undefined;
      } | null | undefined;
    } | null | undefined>;
  } | null | undefined;
};
// # sourceMappingURL=GraphQL.d.ts.map
